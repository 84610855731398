import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import ListaHora from "./pages/ListaHora";
import ListaMaquina from "./pages/ListaMaquina";
import Recebiveis from "./pages/Recebiveis";
import ResumoRecebiveis from "./pages/ResumoRecebiveis";
import RecebiveisContestacao from "./pages/RecebiveisContestacao";



import ListaBandeiras from "./pages/ListaBandeiras";
import ListaFormaspagamento from "./pages/ListaFormaspagamento";
import LoginAreaparceiros02 from "./pages/LoginAreaparceiros02";
import Input from "./pages/Input";
import Calender from "./pages/Calender";
import TelaXloja04Areaparceiros from "./pages/TelaXloja04Areaparceiros";
import TelaXlojaDetalhevendaAreapa from "./pages/TelaXlojaDetalhevendaAreapa";
import TelaXloja03Areaparceiros from "./pages/TelaXloja03Areaparceiros";
import TelaXloja02Areaparceiros from "./pages/TelaXloja02Areaparceiros";
import TelaXloja01AreaparceirosVAZ from "./pages/TelaXloja01AreaparceirosVAZ";
import TelaSaque01Areaparceiros from "./pages/TelaSaque01Areaparceiros";
import TelaProdutos01Areaparceiros from "./pages/TelaProdutos01Areaparceiros";
import TelaRelatorios01Areaparceiro from "./pages/TelaRelatorios01Areaparceiro";
import TelaXloja01Areaparceiros from "./pages/TelaXloja01Areaparceiros";
import MinhasVendas from "./pages/Minhasvendas";
import Conciliacao from "./pages/Conciliacao/Conciliacao";
import VendasHoje from "./pages/VendasHoje";
import EsqueciSenha from "./pages/EsqueciSenha";
import Configuracoes from "./pages/Configuracoes";
import ConfiguracoesInfoLogin from "./pages/ConfiguracoesInfoLogin";

import Marketing from "./pages/Marketing";
import CadastrarMembrosJS from "./pages/CadastrarMembros";
import CadastrarSubmembrosJS from "./pages/CadastrarSubmembros";
import DetalhesVendas from "./pages/DetalhesVendas";

import MembrosJS from "./pages/Membros";


import PrimeiroAcesso from "./pages/PrimeiroAcesso/ViewsPrimeiroAcesso";


import TelaHomeAreaparceiros from "./pages/TelaHomeAreaparceiros";
import LoginAreaparceiros from "./pages/LoginAreaparceiros";
import Desktop from "./pages/Desktop";
import { useEffect } from "react";
import { ApiProvider } from './services/config';

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";
    console.log(pathname)
    switch (pathname) {

      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/lista-maquina":
        title = "";
        metaDescription = "";
        break;
      case "/lista-bandeiras":
        title = "";
        metaDescription = "";
        break;
      case "/lista-formaspagamento":
        title = "";
        metaDescription = "";
        break;
      case "/login-areaparceiros-02":
        title = "";
        metaDescription = "";
        break;
      case "/input":
        title = "";
        metaDescription = "";
        break;
      case "/calender":
        title = "";
        metaDescription = "";
        break;
      case "/tela-xloja04-areaparceiros":
        title = "";
        metaDescription = "";
        break;
      case "/tela-xloja-detalhevenda-areaparceiros":
        title = "";
        metaDescription = "";
        break;
      case "/tela-xloja03-areaparceiros":
        title = "";
        metaDescription = "";
        break;
      case "/tela-xloja02-areaparceiros":
        title = "";
        metaDescription = "";
        break;
      case "/tela-xloja01-areaparceiros-vazio":
        title = "";
        metaDescription = "";
        break;
      case "/tela-saque01-areaparceiros":
        title = "";
        metaDescription = "";
        break;
      case "/tela-produtos01-areaparceiros":
        title = "";
        metaDescription = "";
        break;
      case "/tela-relatorios01-areaparceiros":
        title = "";
        metaDescription = "";
        break;
      case "/xloja":
        title = "";
        metaDescription = "";
        break;

      case "cadastrar_membros":
        title = "";
        metaDescription = "";
        break;

      case "membros":
        title = "";
        metaDescription = "";
        break;

      case "PrimeiroAcesso":
        title = "";
        metaDescription = "";
        break;

      case "/marketing":
        title = "";
        metaDescription = "";
        break;

      case "/esquecisenha":
        title = "";
        metaDescription = "";
        break;

      case "/configuracoes":
        title = "";
        metaDescription = "";
        break;

      case "/infologin":
        title = "";
        metaDescription = "";
        break;

      case "/tela-home-areaparceiros":
        title = "";
        metaDescription = "";
        break;
      case "/login-areaparceiros":
        title = "";
        metaDescription = "";
        break;
      case "/desktop":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <ApiProvider>
      <Routes>
        <Route path="/" element={<LoginAreaparceiros />} />
        <Route path="/lista-maquina" element={<ListaMaquina />} />
        <Route path="/recebiveis" element={<Recebiveis />} />
        <Route path="/resumo_recebiveis" element={<ResumoRecebiveis />} />
        <Route path="/recebiveis_contestacao" element={<RecebiveisContestacao />} />
        <Route path="/conciliacao" element={<Conciliacao />} />


        <Route path="/lista-bandeiras" element={<ListaBandeiras />} />
        <Route path="/lista-formaspagamento" element={<ListaFormaspagamento />} />
        <Route
          path="/login-areaparceiros-02"
          element={<LoginAreaparceiros02 />}
        />
        <Route path="/input" element={<Input />} />
        <Route path="/calender" element={<Calender />} />
        <Route
          path="/tela-xloja04-areaparceiros"
          element={<TelaXloja04Areaparceiros />}
        />
        <Route
          path="/tela-xloja-detalhevenda-areaparceiros"
          element={<TelaXlojaDetalhevendaAreapa />}
        />
        <Route
          path="/tela-xloja03-areaparceiros"
          element={<TelaXloja03Areaparceiros />}
        />
        <Route
          path="/tela-xloja02-areaparceiros"
          element={<TelaXloja02Areaparceiros />}
        />
        <Route
          path="/tela-xloja01-areaparceiros-vazio"
          element={<TelaXloja01AreaparceirosVAZ />}
        />
        <Route
          path="/tela-saque01-areaparceiros"
          element={<TelaSaque01Areaparceiros />}
        />
        <Route
          path="/tela-produtos01-areaparceiros"
          element={<TelaProdutos01Areaparceiros />}
        />
        <Route
          path="/tela-relatorios01-areaparceiros"
          element={<TelaRelatorios01Areaparceiro />}
        />
        <Route
          path="/xloja"
          element={<TelaXloja01Areaparceiros />}
        />

        <Route
          path="/minhasvendas"
          element={<MinhasVendas />}
        />

        <Route
          path="/detalhesvendas"
          element={<DetalhesVendas />}
        />

        <Route
          path="/vendashoje"
          element={<VendasHoje />}
        />

        <Route
          path="/esquecisenha"
          element={<EsqueciSenha />}
        />

        <Route
          path="/configuracoes"
          element={<Configuracoes />}
        />

        <Route
          path="/configuracoes"
          element={<ConfiguracoesInfoLogin />}
        />



        <Route
          path="/marketing"
          element={<Marketing />}
        />

        <Route
          path="/cadastrar_membros"
          element={<CadastrarMembrosJS />}
        />

        <Route
          path="/cadastrar_submembros"
          element={<CadastrarSubmembrosJS />}
        />

        <Route
          path="/membros"
          element={<MembrosJS />}
        />

        <Route
          path="/primeiroAcesso"
          element={<PrimeiroAcesso />}
        />



        <Route
          path="/tela-home-areaparceiros"
          element={<TelaHomeAreaparceiros />}
        />
        <Route path="/login-areaparceiros" element={<LoginAreaparceiros />} />
        <Route path="/desktop" element={<Desktop />} />
      </Routes>
    </ApiProvider>
  );
}
export default App;

import React, { useState } from "react";
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Collapse
} from "@mui/material";
import { format } from "date-fns";
import arrowTop from "../../../assets/icons/arrowTop.png";
import arrowBottom from "../../../assets/icons/arrowBottom.png";
import exclamacao142A3099 from '../../../assets/icons/exclamacao142A3099.png';

const TabelaAgrupadoExcluidosIncluidos = ({ tipe, real, rows }) => {
    const [openRows, setOpenRows] = useState([]);

    const toggleRow = (index) => {
        setOpenRows((prev) =>
            prev.includes(index)
                ? prev.filter((i) => i !== index)
                : [...prev, index]
        );
    };

    const [tooltipOpen, setTooltipOpen] = useState({});

    // Abre o tooltip apenas para a linha específica
    const handleOpen = (id) => {
        setTooltipOpen((prev) => ({ ...prev, [id]: true }));
    };

    // Fecha o tooltip apenas para a linha específica
    const handleClose = (id) => {
        setTooltipOpen((prev) => ({ ...prev, [id]: false }));
    };


    return (
        <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>
            {rows?.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, borderSpacing: '0px 2px', borderCollapse: 'separate' }} aria-label="simple table">
                        <TableHead>
                            {rows.map((row, index) => (
                                <React.Fragment key={index} s>
                                    <TableRow onClick={() => toggleRow(index)} sx={{ cursor: 'pointer', paddingBottom: '15px' }}>
                                        <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                            <Grid sx={{
                                                height: '35px',
                                                borderRadius: '50px',
                                                borderLeft: `${rows[0].tipo == 'E' ? '5px solid  #F09292' : '5px solid  #A9D18E'}`
                                            }} />

                                            <TableCell sx={{
                                                fontSize: '14px',
                                                color: '#142A3099',
                                                border: 0,
                                                padding: '8px 8px 8px 16px',
                                                // borderLeft: `${rows[0].tipo == 'E' ? '4px solid  #F09292' : '4px solid  #A9D18E'}`
                                            }} align="left">
                                                {row.data ? format(`${row.data}T00:00:00`, 'dd/MM/yyyy') : 'N/A'}
                                            </TableCell>
                                        </Grid>
                                        <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, padding: '8px 8px 8px 16px' }} align="center">{row.qtde} exclusões</TableCell>
                                        <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, padding: '8px 8px 8px 16px' }} align="center">{real(row.total)}</TableCell>
                                        <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, padding: '8px 0px 8px 16px' }} align="center">
                                            {openRows.includes(index) ?
                                                <img src={arrowTop} style={{ width: "14px", height: "14px" }} alt="Setas para cima" />
                                                :
                                                <img src={arrowBottom} style={{ width: "14px", height: "14px" }} alt="Setas para baixo" />
                                            }
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={4}
                                            sx={{
                                                border: 0,
                                                padding: 0,

                                                borderLeft: `${rows[0].tipo == 'E' ? '4px solid  #F09292' : '4px solid  #A9D18E'}`
                                            }}>
                                            <Collapse in={openRows.includes(index)} timeout="auto" unmountOnExit>
                                                <Table size="small" sx={{ marginBottom: 1, }}>
                                                    <TableHead >
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, padding: '8px 8px 15px 16px', borderTop: '1px solid #142A3066' }} align="left">ID</TableCell>
                                                            <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, padding: '8px 8px 15px 16px', borderTop: '1px solid #142A3066' }} align="center">Autorização</TableCell>
                                                            <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, padding: '8px 8px 15px 16px', borderTop: '1px solid #142A3066' }} align="center">Colaborador</TableCell>
                                                            <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, padding: '8px 8px 15px 16px', borderTop: '1px solid #142A3066' }} align="center">Tipo Venda</TableCell>
                                                            <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, padding: '8px 8px 15px 16px', borderTop: '1px solid #142A3066' }} align="center">bandeira</TableCell>
                                                            <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, padding: '8px 8px 15px 16px', borderTop: '1px solid #142A3066' }} align="center">Total</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {row.detalhe?.map((detail, idx) => (
                                                            <TableRow key={idx}>
                                                                <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, paddingBottom: '15px' }} align="left">{detail.id}</TableCell>
                                                                <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, paddingBottom: '15px' }} align="center">{detail.codigoautorizacao}</TableCell>
                                                                <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, padding: '5px' }} align="center">
                                                                    <Tooltip
                                                                        arrow
                                                                        PopperProps={{
                                                                            modifiers: [
                                                                                {
                                                                                    name: 'preventOverflow',
                                                                                    options: {
                                                                                        boundary: 'window',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    name: 'offset',
                                                                                    options: {
                                                                                        offset: [0, -14], // [Horizontal, Vertical]
                                                                                    },
                                                                                },
                                                                            ],
                                                                        }}
                                                                        componentsProps={{
                                                                            tooltip: {
                                                                                sx: {
                                                                                    backgroundColor: '#181818', // Cor de fundo
                                                                                    color: 'white', // Cor do texto
                                                                                    fontSize: '14px', // Tamanho da fonte
                                                                                    padding: '10px', // Espaçamento interno
                                                                                    borderRadius: '8px', // Bordas arredondadas
                                                                                    marginTop: '0px'
                                                                                },
                                                                            },
                                                                            arrow: {
                                                                                sx: {
                                                                                    width: '35px',
                                                                                    height: '22px',
                                                                                    color: '#181818',
                                                                                }
                                                                            }
                                                                        }}
                                                                        open={tooltipOpen[detail?.id] || false}
                                                                        onClose={() => handleClose(detail?.id)}
                                                                        onOpen={detail?.funcionarionome ? () => handleOpen(detail?.id) : null}
                                                                        title={
                                                                            <Grid sx={{
                                                                                gap: '10px',
                                                                                display: 'flex',
                                                                                flexDirection: 'column'
                                                                            }}>
                                                                                <Typography variant="body2" sx={{ fontSize: '12px', bgcolor: '#181818', color: 'white', textAlign: 'start' }}>
                                                                                    {detail?.funcionarionome &&
                                                                                        (detail.funcionarionome === detail.funcionarionome.toUpperCase()
                                                                                            ? detail.funcionarionome.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
                                                                                            : detail.funcionarionome)}
                                                                                </Typography>
                                                                            </Grid>
                                                                        }>
                                                                        <Grid>
                                                                            <Typography sx={{ fontSize: '14px', color: '#142A3099', border: 0, padding: '5px' }}>
                                                                                {detail?.funcionarionome ?
                                                                                    detail.funcionarionome
                                                                                        .split(" ")[0]
                                                                                        .toLowerCase()
                                                                                        .replace(/\b\w/g, (char) => char.toUpperCase()) : 'Caixa'}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Tooltip>
                                                                </TableCell>
                                                                <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, paddingBottom: '15px' }} align="center">
                                                                    {
                                                                        detail.tipovenda == 'Xuliz' ? 'Xulis'
                                                                            : detail.tipovenda == 'XULIZ' ? 'Xulis'
                                                                                : detail.tipovenda == 'PIX' ? 'Pix'
                                                                                    : detail.tipovenda == 'CARTAO' ? 'Cartão'
                                                                                        : detail.tipovenda == 'VENDA MAQUININHA' ? 'Venda Maquininha'
                                                                                            : detail.tipovenda == 'ABASTECIMENTO APP' ? 'Abastecimento App'
                                                                                                : detail.tipovenda
                                                                    }
                                                                </TableCell>
                                                                <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, paddingBottom: '15px' }} align="center">
                                                                    {
                                                                        detail.bandeira == 'Xuliz' ? 'Xulis'
                                                                            : detail.bandeira == 'XULIZ' ? 'Xulis'
                                                                                : detail.bandeira
                                                                    }
                                                                </TableCell>
                                                                <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, paddingBottom: '15px' }} align="center">{real(detail.total)}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                                <Typography sx={{ fontSize: '15px', paddingTop: '14px', paddingBottom: '5px', paddingLeft: '16px', color: '#142A3099', fontWeight: 700, borderTop: '1px solid #142A3066' }}>
                                                    Total {rows[0].tipo == 'E' ? 'excluído' : 'incluídas'}: {real(row.total)}
                                                </Typography>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableHead>
                    </Table>
                </TableContainer>
            ) : (
                <Grid sx={{
                    gap: '25px',
                    display: 'flex',
                    borderRadius: '20px',
                    flexDirection: 'row',
                    bgcolor: '#F0EFEB59',
                    padding: '15px 25px',
                    alignItems: 'center'
                }}>
                    <img src={exclamacao142A3099} style={{ width: "35px", height: "35px" }} alt="Exclamação" />
                    <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>
                        Não foram encontrados lançamentos ou dados disponíveis. Como resultado, até o momento nenhuma transação foi {tipe == 'E' ? 'removida' : 'inserida'} do sistema.
                    </Typography>
                </Grid>
            )}
        </Paper>
    );
};

export default TabelaAgrupadoExcluidosIncluidos;

import React, { useState } from "react";
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import exclamacao142A3099 from '../../../assets/icons/exclamacao142A3099.png';

const TabelaExcluidos = ({ tipe, real, rows }) => {

    const [tooltipOpen, setTooltipOpen] = useState({});

    // Abre o tooltip apenas para a linha específica
    const handleOpen = (id) => {
        setTooltipOpen((prev) => ({ ...prev, [id]: true }));
    };

    // Fecha o tooltip apenas para a linha específica
    const handleClose = (id) => {
        setTooltipOpen((prev) => ({ ...prev, [id]: false }));
    };

    return (
        <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>
            {rows?.detalhe?.length > 0 ?
                <>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650, borderSpacing: '0 2px', borderCollapse: 'separate' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, paddingLeft: 0, paddingBottom: '15px' }} align="left">ID</TableCell>
                                    <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, paddingBottom: '15px' }} align="center">Autorização</TableCell>
                                    <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, paddingBottom: '15px' }} align="center">Colaborador</TableCell>
                                    <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, paddingBottom: '15px' }} align="center">Tipo Venda</TableCell>
                                    <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, paddingBottom: '15px' }} align="center">Bandeira</TableCell>
                                    <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, paddingBottom: '15px' }} align="center">Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows?.detalhe?.map((row) => (
                                    <TableRow key={row?.bandeira}>
                                        <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, padding: '10px 5px 10px 0px' }} align="left">{row.id}</TableCell>
                                        <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, padding: '5px' }} align="center">{row.codigoautorizacao}</TableCell>
                                        <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, padding: '5px' }} align="center">
                                            <Tooltip
                                                arrow
                                                PopperProps={{
                                                    modifiers: [
                                                        {
                                                            name: 'preventOverflow',
                                                            options: {
                                                                boundary: 'window',
                                                            },
                                                        },
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [0, -14], // [Horizontal, Vertical]
                                                            },
                                                        },
                                                    ],
                                                }}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            backgroundColor: '#181818', // Cor de fundo
                                                            color: 'white', // Cor do texto
                                                            fontSize: '14px', // Tamanho da fonte
                                                            padding: '10px', // Espaçamento interno
                                                            borderRadius: '8px', // Bordas arredondadas
                                                            marginTop: '0px'
                                                        },
                                                    },
                                                    arrow: {
                                                        sx: {
                                                            width: '35px',
                                                            height: '22px',
                                                            color: '#181818',
                                                        }
                                                    }
                                                }}
                                                open={tooltipOpen[row?.id] || false}
                                                onClose={() => handleClose(row?.id)}
                                                onOpen={row?.funcionarionome ? () => handleOpen(row?.id) : null}
                                                title={
                                                    <Grid sx={{
                                                        gap: '10px',
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}>
                                                        <Typography variant="body2" sx={{ fontSize: '12px', bgcolor: '#181818', color: 'white', textAlign: 'start' }}>
                                                            {row?.funcionarionome &&
                                                                (row.funcionarionome === row.funcionarionome.toUpperCase()
                                                                    ? row.funcionarionome.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
                                                                    : row.funcionarionome)}
                                                        </Typography>
                                                    </Grid>
                                                }>
                                                <Grid>
                                                    <Typography sx={{ fontSize: '14px', color: '#142A3099', border: 0, padding: '5px' }}>
                                                        {row?.funcionarionome ?
                                                            row.funcionarionome
                                                                .split(" ")[0]
                                                                .toLowerCase()
                                                                .replace(/\b\w/g, (char) => char.toUpperCase()) : '-'}
                                                    </Typography>
                                                </Grid>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, padding: '5px' }} align="center">
                                            {
                                                row.tipovenda == 'Xuliz' ? 'Xulis'
                                                    : row.tipovenda == 'XULIZ' ? 'Xulis'
                                                        : row.tipovenda == 'PIX' ? 'Pix'
                                                            : row.tipovenda == 'CARTAO' ? 'Cartão'
                                                                : row.tipovenda == 'VENDA MAQUININHA' ? 'Venda Maquininha'
                                                                    : row.tipovenda == 'ABASTECIMENTO APP' ? 'Abastecimento App'
                                                                        : row.tipovenda
                                            }
                                        </TableCell>
                                        <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, padding: '5px' }} align="center">
                                            {
                                                row.bandeira == 'Xuliz' ? 'Xulis'
                                                    : row.bandeira == 'XULIZ' ? 'Xulis'
                                                        : row.bandeira
                                            }
                                        </TableCell>
                                        <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, padding: '5px' }} align="center">{real(row.total)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Typography sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, paddingTop: '20px' }}>
                            Total {rows?.tipo == 'E' ? 'excluído' : 'incluídas'}:  {real(rows.total)}
                        </Typography>
                    </TableContainer>
                </>
                :
                (
                    <Grid sx={{
                        gap: '25px',
                        display: 'flex',
                        borderRadius: '20px',
                        flexDirection: 'row',
                        bgcolor: '#F0EFEB59',
                        padding: '15px 25px',
                        alignItems: 'center'
                    }}>
                        <img src={exclamacao142A3099} style={{ width: "35px", height: "35px" }} alt="Exclamação" />
                        <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>
                            Não foram encontrados lançamentos ou dados disponíveis. Como resultado, até o momento nenhuma transação foi {tipe == 'E' ? 'removida' : 'inserida'} do sistema.
                        </Typography>
                    </Grid>
                )
            }
        </Paper >
    );
};

export default TabelaExcluidos;

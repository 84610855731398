import { useCallback,useContext } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./componentes/menu";
import MenuMobile from "./componentes/menuMobile";

import { useEffect } from "react";
import xloja_12 from './assets/xloja_12.png'; // Tell webpack this JS file uses this image
import waiting from './assets/waiting.jpg';

import xloja from './assets/xloja.png'; // Tell webpack this JS file uses this image


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faShoppingBag, faChartPie, faBox, faCircleDollarToSlot, faBullhorn, faQuestionCircle, faUsers, faGear, faSquareXmark, faMobile, faBars, faTrash, faAngleLeft, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";
import axios from "axios";
import logo_parceiros from './assets/grupo677.png'; // Tell webpack this JS file uses this image
import grupo12 from './assets/grupo12.png';
import retangulo473 from './assets/retangulo473.png';

import more_falt1 from './assets/more_falt1.png';
import more_falt2 from './assets/more_falt2.png';
import Membros from "./Membros/Membros";
import {ApiContext} from "../services/config.js";


var vendaPaga = false;

const MembrosJS = () => {
  const navigate = useNavigate();

  const [emailRef, setEmailRef] = useState('');
  const [value, setValue] = useState('');
  const [urlfotoperfil, setFotoUrl] = useState('');
  const [cashbackConta, setCashback] = useState('3');
  const [cashbackCliente, setCaschbackCliente] = useState('R$ 0,00');
  const [dadosClinte, setDadosCliente] = useState([]);
  const [dadosClinteSubmembros, setSubmembros2] = useState([]);
  const { firebase_endpoint } = useContext(ApiContext);


  const [dadosX, setDadosX] = useState([]);
  const [primeiroNome, setPrimeiroNome] = useState('');
  const [nomeUser, setNome] = useState('');

  const [clickEnviou, setClickEnviou] = useState(false);
  const [tokenEsta, setTokenEstabelecimento] = useState('');
  const [idvenda, setIdvenda] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [urlLogo, setUrlLogo] = useState('');

  const [submembros, setSubmembros] = useState([]);
  const [dadosClinteSubmembrosORIGEM, setSubmembrosORIGEM] = useState([]);

  const [screenWidth, setScreenWidth] = useState(null);
  const [menuOpen, setMenuOpen] = useState(true);
  const [allMembros, setAllMembros] = useState([]);
  const [filterMembros, setFilterMembros] = useState([]);
  const [membroSearch, setMembroSearch] = useState();


  //{item:'item',id:1,nome:'Lucas',celular:'62 98604-236'},{item:'item',id:1,nome:'Lucas',celular:'62 98604-236'}



  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);


  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800);
    setScreenWidth(window.innerWidth);

    if (window.innerWidth <= 1400 && menuOpen) {
      setMenuOpen(false)
    }
    if (window.innerWidth > 1400 && menuOpen) {
      setMenuOpen(true)
    }
  };

  useEffect(() => {
    getDados()
  }, []);

  const Swal = require('sweetalert2')


  const pegarMembros = async (dados) => {

    var token_estabelecimento = dados.tokenestabelecimento;
    var idestabelecimento = dados.idestabelecimento;
    var idparceiro = dados.parceiros;

    if (idparceiro == null) {
      return false;
    }

    if (idparceiro.length == 0) {
      return false;
    }

    if (idparceiro[0] == null) {
      return false;
    }

    idparceiro = idparceiro[0].idparceiro

    const options = {
      method: 'POST',
      url: 'https://us-central1-restapi-4777e.cloudfunctions.net/app/membrosListar.php',
      headers: { 'Content-Type': 'application/json' },
      params: {
        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
        funcao: 'membrosListar',
        token_estabelecimento: token_estabelecimento,
        idestabelecimento: idestabelecimento,
        idparceiro: idparceiro
      },
    };

    await axios.request(options).then(function (response) {
      // console.log('membrosListar--------------------------2', response.data);

      setAllMembros(response.data.retorno)
      var dados = response.data;
      if (dados.erro != "N") {
        return false;
      }

      var membros = dados.retorno;

      if (membros != null && membros.length > 0) {
        setSubmembros(membros)
        setSubmembrosORIGEM(membros)
        calcularSubmembros(membros)

      }




    }).catch(function (error) {
      console.error(error);
      alert("Encontramos um problema tente mais tarde")
    });

  }

  const calcularSubmembros = async (membros) => {
    var dependentesTOTAL = 0;

    for (var x = 0; x < membros.length; x++) {

      if (membros[x].dependentes && membros[x].dependentes.length && membros[x].dependentes.length > 0) {
        var total = membros[x].dependentes.length;
        dependentesTOTAL = dependentesTOTAL + total;
      }
    }
  }

  const buscarMembro = async () => {
    var buscar_id = document.getElementById('buscar_id').value;


    // console.log(buscar_id)



    if (buscar_id == '') {
      setSubmembros(dadosClinteSubmembrosORIGEM)
      return false;
    }

    buscar_id = buscar_id.toLowerCase()
    // console.log(buscar_id)
    // console.log(submembros)

    var filtrar = [];

    for (var x = 0; x < dadosClinteSubmembrosORIGEM.length; x++) {
      var nome = dadosClinteSubmembrosORIGEM[x].nome
      nome = nome.toLowerCase()

      if (nome.includes(buscar_id)) {

        filtrar.push(dadosClinteSubmembrosORIGEM[x])
      } else {
        //vamos ver se tem pelos submembros
        if (dadosClinteSubmembrosORIGEM[x].dependentes && dadosClinteSubmembrosORIGEM[x].dependentes.length > 0) {
          var dep = dadosClinteSubmembrosORIGEM[x].dependentes

          var nesseTemPesquisa = false;
          for (var a = 0; a < dep.length; a++) {
            var nome2 = dep[a].nome;
            nome2 = nome2.toLowerCase()

            if (nome2.includes(buscar_id)) {
              nesseTemPesquisa = true;

            }
          }

          if (nesseTemPesquisa == true) {
            filtrar.push(dadosClinteSubmembrosORIGEM[x])
          }
        }
      }
    }

    // console.log('1',filtrar)
    // console.log('2',submembros)

    setSubmembros(filtrar)



  }

  const getDados = async () => {
    var dados = await localStorage.getItem('login')

    if (dados == null) {
      navigate("/");

      return false;
    }
    dados = JSON.parse(dados)

    setDadosX(dados)
    setUrlLogo(dados.urllogo)
    setEmailRef(dados.email)
    pegarMembros(dados)
  }

  const verificarSubmembros = async (dados) => {
    function getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }


    var sub = getParameterByName('sub');
    var ids = getParameterByName('id');

    ///COLOQUE
    // if(sub == null || sub != 's'){
    //   return false;
    // }

    if (sub == null || sub != 's') {
      return false;
    }


    buscarMembro(dados, ids)
  }

  const adicionarSubmembro = async () => {
    var chave = document.getElementById('numero_celular2').value;
    if (chave.length != 11) {
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Número de celular inválido!',
        showConfirmButton: false,
        timer: 1000
      })

      return false;
    }


    var iddependentetipo = document.getElementById('qual_relação2').value;

    if (iddependentetipo == '-') {
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Selecione uma relação com o membro',
        showConfirmButton: false,
        timer: 1000
      })

      return false;
    }



    ////

    var idestabelecimento = dadosX.idestabelecimento
    var token_estabelecimento = dadosX.tokenestabelecimento

    var idparceiro = dadosX.parceiros;

    if (idparceiro == null) {
      return false;
    }

    if (idparceiro.length == 0) {
      return false;
    }

    if (idparceiro[0] == null) {
      return false;
    }

    idparceiro = idparceiro[0].idparceiro

    var idusuario = dadosClinteSubmembros.idusuario;
    var idusuariotitular = dadosClinte.idusuario;
    var idusuarioregistro = dadosX.idusuario;



    const options = {
      method: 'POST',
      url: 'https://us-central1-restapi-4777e.cloudfunctions.net/app/cadastrarMembro.php',
      headers: { 'Content-Type': 'application/json' },
      params: {
        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
        funcao: 'cadastrarMembro',
        token_estabelecimento: token_estabelecimento,
        idestabelecimento: idestabelecimento,
        idparceiro: idparceiro,
        idusuario: idusuario,
        idusuariotitular: idusuariotitular,
        iddependentetipo: iddependentetipo,
        idusuarioregistro: idusuarioregistro,

      },
    };

    await axios.request(options).then(function (response) {
      // console.log('novoCadastro', response.data);
      var dados = response.data;

      if (dados.erro != "N") {
        alert(dados.mensagem)
        return false;
      }

      Swal.fire(
        'Sucesso!',
        '' + dados.mensagem,
        'success'
      ).then(() => {
        location.reload()
      })




    }).catch(function (error) {
      console.error(error);
      alert("Encontramos um problema tente mais tarde")
    });



  }

  const adicionarMembro = () => {
    location.href = './cadastrar_membros'
  }

  const salvarMembro = async () => {

    var chave = document.getElementById('chave_celular').value;
    if (chave.length != 11) {
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Número de celular inválido!',
        showConfirmButton: false,
        timer: 1000
      })

      return false;
    }


    var iddependentetipo = document.getElementById('qual_relação').value;

    if (iddependentetipo == '-') {
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Selecione uma relação com a empresa',
        showConfirmButton: false,
        timer: 1000
      })

      return false;
    }


    //vamos conversar com a API


    var idestabelecimento = dadosX.idestabelecimento
    var token_estabelecimento = dadosX.tokenestabelecimento

    var idparceiro = dadosX.parceiros;

    if (idparceiro == null) {
      return false;
    }

    if (idparceiro.length == 0) {
      return false;
    }

    if (idparceiro[0] == null) {
      return false;
    }

    idparceiro = idparceiro[0].idparceiro

    var idusuario = dadosClinte.idusuario;
    var idusuariotitular = '';
    var idusuarioregistro = dadosX.idusuario;



    const options = {
      method: 'POST',
      url: 'https://us-central1-restapi-4777e.cloudfunctions.net/app/cadastrarMembro.php',
      headers: { 'Content-Type': 'application/json' },
      params: {
        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
        funcao: 'cadastrarMembro',
        token_estabelecimento: token_estabelecimento,
        idestabelecimento: idestabelecimento,
        idparceiro: idparceiro,
        idusuario: idusuario,
        idusuariotitular: idusuariotitular,
        iddependentetipo: iddependentetipo,
        idusuarioregistro: idusuarioregistro,

      },
    };

    await axios.request(options).then(function (response) {
      // console.log('novoCadastro', response.data);
      var dados = response.data;

      if (dados.erro != "N") {
        alert(dados.mensagem)
        return false;
      }

      Swal.fire(
        'Sucesso!',
        '' + dados.mensagem,
        'success'
      )

      document.getElementById('submembros_id').style.display = 'flex';



    }).catch(function (error) {
      console.error(error);
      alert("Encontramos um problema tente mais tarde")
    });


  }

  const chaveCelular2 = async () => {


    var chave = document.getElementById('numero_celular2').value;
    if (chave.length != 11) {
      document.getElementById("nome_cliente2").value = '';


      return false;
    }


    var qual_chave = "celular";

    const form = new FormData();
    form.append("metodo", "ConsultaUsuarioChave");
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("chavecliente", "" + chave);
    form.append("chavetipo", qual_chave);

    const options = {
      method: 'POST',
    };

    options.body = form;

    fetch(`${firebase_endpoint}/painel/integracao/usuario.php`, options)
      .then(response => response.json())
      .then(response => setarDados2(response))
      .catch(err => console.error(err));

  }

  const chaveCelular = async () => {

    var chave = document.getElementById('chave_celular').value;
    if (chave.length != 11) {
      document.getElementById("nome_cliente").value = '';
      document.getElementById('imagem_user').src = more_falt1;
      document.getElementById('titulo_msg').innerText = 'Xuliz & CDL';
      document.getElementById('titulo_msg2').innerText = 'Digite o número de celular para cadastrar um novo Membro+';
      document.getElementById('card_info').style.display = 'none';
      document.getElementById('card_info2').style.display = 'flex';
      document.getElementById('submembros_id').style.display = 'none';



      return false;
    }

    var qual_chave = "celular";

    const form = new FormData();
    form.append("metodo", "ConsultaUsuarioChave");
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("chavecliente", "" + chave);
    form.append("chavetipo", qual_chave);

    const options = {
      method: 'POST',
    };

    options.body = form;

    fetch(`${firebase_endpoint}/painel/integracao/usuario.php`, options)
      .then(response => response.json())
      .then(response => setarDados(response))
      .catch(err => console.error(err));
  }

  const setarDados2 = async (dados) => {
    // console.log(dados)
    if (dados.erro != "N") {

      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: dados.mensagem,
        showConfirmButton: false,
        timer: 1000
      })

      return false;
    }

    setSubmembros2(dados)

    document.getElementById('nome_cliente2').value = dados.nome;

  }

  const setarDados = async (dados) => {
    // console.log(dados)

    if (dados.erro != "N") {

      document.getElementById('imagem_user').src = more_falt2;
      document.getElementById('titulo_msg').innerText = 'Usuário não existente';
      document.getElementById('titulo_msg2').innerText = 'Mas não se preocupe, enviaremos um SMS para ele criar uma conta Xuliz';

      return false;

    }

    setDadosCliente(dados);

    // console.log('dadosClinte', dadosClinte)


    var urlFoto = dados.urlfotoperfil;

    if (urlFoto == '' || urlFoto == null) {
      urlFoto = 'https://cdn-icons-png.flaticon.com/512/149/149071.png';
    }


    document.getElementById('imagem_user').src = urlFoto;
    document.getElementById('card_info').style.display = 'flex';
    document.getElementById('card_info2').style.display = 'none';

    document.getElementById('nome_cliente').value = dados.nome






  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    allMembrosFilter()
  }, [membroSearch])

  const handleChangeMembroSearch = (event) => {
    setMembroSearch(event.target.value)
  }

  const allMembrosFilter = () => {
      const filtered = allMembros
        .map(membro => {
          // Filtra os dependentes que correspondem ao termo de busca
          const dependentesFiltrados = membro.dependentes?.filter(dep =>
            dep.nome.toLowerCase().includes(membroSearch.toLowerCase())
          );

          // Verifica se o nome do pai ou algum dependente corresponde ao termo
          const paiOuDependenteEncontrado =
            membro.nome.toLowerCase().includes(membroSearch.toLowerCase()) ||
            (dependentesFiltrados && dependentesFiltrados.length > 0);

          // Se encontrar, retorna o pai com os dependentes filtrados
          if (paiOuDependenteEncontrado) {
            return {
              ...membro,
              dependentes: dependentesFiltrados || [],
            };
          }
          return null; // Se não encontrar, retorna null
        })
        .filter(i => i !== null); // Remove os itens nulos

      setFilterMembros(filtered);
  };


  if (isMobile == true) {

    return (
      <>
        <MenuMobile page="marketing" />
        <Membros
          isMobile={isMobile}
          screenWidth={screenWidth}
          allMembros={allMembros}
          dadosX={dadosX}
          membroSearch={membroSearch}
          handleChangeMembroSearch={handleChangeMembroSearch}
        />
        {/* <div style={{ height: '100vh', display: "flex", width: "100%", overflow: 'auto' }}>
          <div style={{ display: "flex", width: "100%", margin: 40, flexDirection: 'column', height: 420 }}>
            <p style={{ fontSize: 15, fontFamily: 'Montserrat', marginLeft: 20, marginTop: 30, cursor: "pointer" }} onClick={() => { location.href = './marketing' }}><FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: 15, color: "#262626", marginRight: 3 }} />Voltar</p>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ display: 'flex', flex: 1 }}>
                <p style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'Montserrat', marginLeft: 20, marginTop: 2 }}>Membro+</p>
              </div>
              <div style={{ display: 'flex', flex: 1 }}>
                <div style={{ width: 120, backgroundColor: '#142A30', height: 30, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => { adicionarMembro() }}>
                  <span style={{ color: '#FFFFFF', fontSize: 10, fontWeight: 'bold' }}>Adicionar membro</span>
                </div>
              </div>
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '900px' }}>
              <div style={{ marginTop: 20 }}>
                <div style={{ width: 900, height: 30, marginBottom: 10, flex: 1, display: 'flex', borderBottomStyle: 'solid', borderWidth: 0.3 }} >
                  <div style={{ display: 'flex', flex: 1.1, alignItems: 'center', justifyContent: 'center' }} >
                    <span style={{ fontSize: 12 }}>Id</span>
                  </div>
                  <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                    <span style={{ fontSize: 12 }}>Nome</span>
                  </div>
                  <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }} >
                    <span style={{ fontSize: 12 }}>Celular</span>
                  </div>
                  <div style={{ display: 'flex', flex: 0.9, justifyContent: 'center', alignItems: 'center' }} >
                    <span style={{ fontSize: 12, marginLeft: 60 }}>Situação</span>
                  </div>
                  <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }} >
                    <span style={{ fontSize: 12 }}>Data</span>
                  </div>
                  <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }} >
                    <span style={{ fontSize: 12, marginLeft: 50 }}>Relação</span>
                  </div>
                  <div style={{ display: 'flex', flex: 0.2, justifyContent: 'center', alignItems: 'center' }}>
                    <span style={{ fontSize: 12 }}>Dependentes</span>
                  </div>
                  <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <span style={{ fontSize: 12 }}>Ações</span>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 1, flexDirection: 'column' }}>
                {submembros.map((item, index) => {
                  function convertDateFormat(inputString) {
                    if (inputString == null || inputString == '') {
                      return ''
                    }
                    var parts = inputString.split(' ');
                    var datePart = parts[0];
                    var timePart = parts[1];

                    var dateParts = datePart.split('-');
                    var year = dateParts[0];
                    var month = dateParts[1];
                    var day = dateParts[2];

                    var formattedDate = `${day}/${month}/${year} ${timePart}`;

                    return formattedDate;
                  }


                  var imagemRef = item.urlfotoperfil;
                  var dataCadastro = item.datestamp;
                  dataCadastro = convertDateFormat(dataCadastro);

                  var Relacao = item.dependentetipo;
                  var openSubmembros = false;
                  var id = item.idempresaparceirousuario;
                  id = id.match(/\d+/g);


                  if (imagemRef == '' || imagemRef == '') {
                    imagemRef = 'https://cdn-icons-png.flaticon.com/512/149/149071.png'
                  }


                  var meusub = [];
                  if (item.dependentes != null && item.dependentes.length > 0) {
                    meusub = item.dependentes
                    for (var x = 0; x < meusub.length; x++) {
                      var idx = '' + meusub[x].idempresaparceirousuario;
                      idx = '' + idx.match(/\d+/g);

                      meusub[x].id = idx;

                      if (meusub[x].urlfotoperfil == null || meusub[x].urlfotoperfil == '') {
                        meusub[x].urlfotoperfil = 'https://cdn-icons-png.flaticon.com/512/149/149071.png'
                      }
                    }
                  }


                  const funcaoClick = () => {
                    if (meusub.length == 0 || meusub == null) {
                      openSubmembros = false;
                      document.getElementById(id + '_div').style.display = 'none';
                      document.getElementById(id + '_div').style.height = '0px';
                      return false;
                    }

                    var altura = 50 * meusub.length;


                    if (openSubmembros == false) {
                      openSubmembros = true;
                      document.getElementById(id + '_div').style.display = 'flex';
                      document.getElementById(id + '_div').style.height = altura + 'px';
                    } else {
                      openSubmembros = false;
                      document.getElementById(id + '_div').style.display = 'none'
                      document.getElementById(id + '_div').style.height = '0px';
                    }
                  }

                  // console.log('meusub', meusub)
                  // console.log('item', item)



                  const clickAdd = (idusuario) => {

                    if (idusuario == null || idusuario == '') {
                      alert('Não é possivel cadastrar dependentes de usuário que não finalizaram o cadastro no Xuliz ')
                      return false;
                    }

                    location.href = './cadastrar_submembros?id=' + idusuario + '&sub=s'
                  }


                  const excluirMembro = async (idempresaparceirousuario, situacao) => {

                    var url = "https://us-central1-restapi-4777e.cloudfunctions.net/app/excluirMembro.php";
                    var fun = 'excluirMembro';
                    if (situacao && situacao == "PENDENTE") {
                      url = "https://us-central1-restapi-4777e.cloudfunctions.net/app/excluirMembroCelular.php";
                      fun = 'excluirMembroCelular';
                    }

                    var get_idempresaparceirousuario = idempresaparceirousuario;
                    get_idempresaparceirousuario = '' + get_idempresaparceirousuario.match(/\d+/g);

                    var idestabelecimento = dadosX.idestabelecimento
                    var token_estabelecimento = dadosX.tokenestabelecimento


                    const options = {
                      method: 'POST',
                      url: url,
                      headers: { 'Content-Type': 'application/json' },
                      params: {
                        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
                        funcao: fun,
                        token_estabelecimento: token_estabelecimento,
                        idestabelecimento: idestabelecimento,
                        idlinha: get_idempresaparceirousuario

                      },
                    };



                    await axios.request(options).then(function (response) {
                      // console.log('novoCadastro', response.data);
                      var dados = response.data;

                      if (dados.erro != "N") {
                        alert(dados.mensagem)
                        return false;
                      }

                      Swal.fire(
                        'Sucesso!',
                        '' + dados.mensagem,
                        'success'
                      ).then(() => {
                        location.reload()
                      })



                    }).catch(function (error) {
                      console.error(error);
                      alert("Encontramos um problema tente mais tarde")
                    });

                  }


                  return (
                    <>
                      <div style={{ width: 900, height: 70, marginBottom: 10, flex: 1, display: 'flex' }} >

                        <div style={{ display: 'flex', flex: 0.1, alignItems: 'center', padding: 10, cursor: "pointer" }} onClick={() => { funcaoClick() }}>
                          <img style={{ height: 40, width: 40, borderRadius: 5 }} src={imagemRef}>

                          </img>

                        </div>


                        <div style={{ display: 'flex', flex: 0.4, alignItems: 'center', cursor: "pointer" }} onClick={() => { funcaoClick() }}>
                          <span style={{ fontSize: 12 }}>{id}</span>
                        </div>

                        <div style={{ display: 'flex', flex: 1, alignItems: 'center', cursor: "pointer" }} onClick={() => { funcaoClick() }}>
                          <span style={{ fontSize: 12 }}>{item.nome}</span>
                        </div>

                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', cursor: "pointer" }} onClick={() => { funcaoClick() }}>
                          <span style={{ fontSize: 12 }}>{item.celular}</span>
                        </div>

                        <div style={{ display: 'flex', flex: 0.6, justifyContent: 'center', alignItems: 'center', cursor: "pointer" }} onClick={() => { funcaoClick() }}>
                          <span style={{ fontSize: 12 }}>{item.situacao}</span>
                        </div>


                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', cursor: "pointer" }} onClick={() => { funcaoClick() }}>
                          <span style={{ fontSize: 12 }}>{dataCadastro}</span>
                        </div>

                        <div style={{ display: 'flex', flex: 0.6, justifyContent: 'center', alignItems: 'center', cursor: "pointer" }} onClick={() => { funcaoClick() }}>
                          <span style={{ fontSize: 12 }}>{Relacao}</span>
                        </div>

                        <div style={{ display: 'flex', flex: 0.2, justifyContent: 'center', alignItems: 'center', cursor: "pointer" }} onClick={() => { funcaoClick() }}>
                          <span style={{ fontSize: 12 }}>{meusub.length}</span>
                        </div>

                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                          <div style={{ width: 45, backgroundColor: '#142A30', height: 25, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => { clickAdd(item.idusuario) }}>
                            <span style={{ color: '#FFFFFF', fontSize: 10, fontWeight: 'bold' }}>Add+</span>
                          </div>

                          <div style={{ width: 25, backgroundColor: '#142A30', height: 25, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 5, cursor: 'pointer' }} onClick={() => { excluirMembro(item.idempresaparceirousuario, item.situacao) }}>
                            <FontAwesomeIcon icon={faTrash} style={{ fontSize: 10, color: "#FFFFFF" }} />
                          </div>
                        </div>

                      </div>

                      <div style={{ width: 860, height: 200, borderBottomStyle: 'solid', padding: 2, borderWidth: 0.4, backgroundColor: '#262626', display: 'none', margin: 10, flexDirection: 'column', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }} id={id + '_div'}>
                        {meusub.map((item2, index) => (
                          <div style={{ width: '100%', height: 20, marginBottom: 10, flex: 1, display: 'flex', marginTop: 10 }}>

                            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                              <div style={{ backgroundColor: '#f2e1c4', width: 20, height: 20, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ textAlignLast: 'center', fontSize: 12 }}>{index + 1}</span>
                              </div>
                            </div>

                            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                              <div style={{ width: 30, height: 30, borderRadius: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', borderWidth: 1, borderColor: '#f2e1c4', borderStyle: 'solid' }} src={item2.urlfoto}>
                                <img src={item2.urlfotoperfil} style={{ width: 30, height: 30 }}>
                                </img>
                              </div>
                            </div>

                            <div style={{ display: 'flex', flex: 0.8, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                              <span style={{ fontSize: 9, color: '#8f8f8f' }}>ID</span>
                              <p style={{ fontSize: 10, marginBottom: 0, color: 'white' }}>{item2.id}</p>
                            </div>

                            <div style={{ display: 'flex', flex: 3, flexDirection: 'column', justifyContent: 'center' }}>
                              <span style={{ fontSize: 9, color: '#8f8f8f' }}>Nome</span>
                              <p style={{ fontSize: 10, marginBottom: 0, color: 'white' }}>{item2.nome}</p>
                            </div>

                            <div style={{ display: 'flex', flex: 2, flexDirection: 'column', justifyContent: 'center' }}>
                              <span style={{ fontSize: 9, color: '#8f8f8f' }}>Nº Celular</span>
                              <p style={{ fontSize: 10, marginBottom: 0, color: 'white' }}>{item2.celular}</p>
                            </div>

                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center' }}>
                              <span style={{ fontSize: 9, color: '#8f8f8f' }}>Relação</span>
                              <p style={{ fontSize: 10, marginBottom: 0, color: 'white' }}>{item2.dependentetipo}</p>
                            </div>

                            <div style={{ display: 'flex', flex: 2, flexDirection: 'column', justifyContent: 'center' }}>
                              <span style={{ fontSize: 9, color: '#8f8f8f' }}>Data cadastro</span>
                              <p style={{ fontSize: 10, marginBottom: 0, color: 'white' }}>{convertDateFormat(item2.datestamp)}</p>
                            </div>

                            <div style={{ display: 'flex', flex: 2, flexDirection: 'column', justifyContent: 'center' }}>
                              <span style={{ fontSize: 9, color: '#8f8f8f' }}>Situação</span>
                              <p style={{ fontSize: 10, marginBottom: 0, color: 'white' }}>{item2.situacao}</p>
                            </div>

                            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => { excluirMembro(item2.idempresaparceirousuario, item2.situacao) }}>
                              <FontAwesomeIcon icon={faTrash} style={{ fontSize: 12, color: '#f1e2c4' }} />
                            </div>
                          </div>
                        ))}
                      </div>


                    </>
                  )
                })}
              </div>




            </div>


          </div>
        </div> */}

      </>
    )

  }

  return (
    <>
      <div style={{ maxWidth: '1920px', height: '100vh', display: "flex" }}>
        <div style={{ backgroundColor: '#262626' }}>
          <Menu page="marketing" menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </div>
        <div style={{ overflow: 'auto', width: '100%' }}>
          <Membros
            isMobile={isMobile}
            screenWidth={screenWidth}
            allMembros={filterMembros.length > 0 ? filterMembros : allMembros}
            dadosX={dadosX}
            membroSearch={membroSearch}
            handleChangeMembroSearch={handleChangeMembroSearch}
          />
        </div>
      </div>

      {/* <div style={{ height: '100vh', display: "flex" }}>
        <div style={{ backgroundColor: '#262626' }}>
          <Menu page="marketing" />
        </div>

        <div style={{ height: '100vh', display: "flex", width: "100%", overflow: 'auto' }}>
          <div style={{ display: "flex", width: "100%", margin: 40, flexDirection: 'column', height: 420 }}>
            <p style={{ fontSize: 15, fontFamily: 'Montserrat', marginLeft: 20, marginTop: 30, cursor: "pointer" }} onClick={() => { location.href = './marketing' }}><FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: 15, color: "#262626", marginRight: 3 }} />Voltar</p>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ display: 'flex', flex: 1 }}>
                <p style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'Montserrat', marginLeft: 20, marginTop: 2 }}>Membro+</p>
              </div>

              <div style={{ display: 'flex', flex: 1, justifyContent: 'right' }}>



                <div class="mb-3" style={{ width: 300, height: 30, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', marginRight: 20 }}>
                  <input type="email" class="form-control" id="buscar_id" aria-describedby="emailHelp" placeholder="Buscar membro" onChange={() => buscarMembro()} />
                </div>

                <div style={{ width: 120, backgroundColor: '#142A30', height: 30, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => { adicionarMembro() }}>
                  <span style={{ color: '#FFFFFF', fontSize: 10, fontWeight: 'bold' }}>Adicionar membro</span>
                </div>

              </div>


            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

              <div style={{ marginTop: 20 }}>

                <div style={{ width: 900, height: 30, marginBottom: 10, flex: 1, display: 'flex', borderBottomStyle: 'solid', borderWidth: 0.3 }} >




                  <div style={{ display: 'flex', flex: 1.1, alignItems: 'center', justifyContent: 'center' }} >
                    <span style={{ fontSize: 12 }}>Id</span>
                  </div>

                  <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                    <span style={{ fontSize: 12 }}>Nome</span>
                  </div>

                  <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }} >
                    <span style={{ fontSize: 12 }}>Celular</span>
                  </div>

                  <div style={{ display: 'flex', flex: 0.9, justifyContent: 'center', alignItems: 'center' }} >
                    <span style={{ fontSize: 12, marginLeft: 60 }}>Situação</span>
                  </div>


                  <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }} >
                    <span style={{ fontSize: 12 }}>Data</span>
                  </div>

                  <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }} >
                    <span style={{ fontSize: 12, marginLeft: 50 }}>Relação</span>
                  </div>

                  <div style={{ display: 'flex', flex: 0.2, justifyContent: 'center', alignItems: 'center' }}>
                    <span style={{ fontSize: 12 }}>Dependentes</span>
                  </div>

                  <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <span style={{ fontSize: 12 }}>Ações</span>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: 1, flexDirection: 'column' }}>
                {submembros.map((item, index) => {

                  function convertDateFormat(inputString) {

                    if (inputString == null || inputString == '') {
                      return ''
                    }

                    var parts = inputString.split(' ');
                    var datePart = parts[0];
                    var timePart = parts[1];

                    var dateParts = datePart.split('-');
                    var year = dateParts[0];
                    var month = dateParts[1];
                    var day = dateParts[2];

                    var formattedDate = `${day}/${month}/${year} ${timePart}`;

                    return formattedDate;
                  }


                  var imagemRef = item.urlfotoperfil;
                  var dataCadastro = item.datestamp;
                  dataCadastro = convertDateFormat(dataCadastro);

                  var Relacao = item.dependentetipo;
                  var openSubmembros = false;
                  var id = item.idempresaparceirousuario;
                  id = id.match(/\d+/g);


                  if (imagemRef == '' || imagemRef == '') {
                    imagemRef = 'https://cdn-icons-png.flaticon.com/512/149/149071.png'
                  }


                  var meusub = [];
                  if (item.dependentes != null && item.dependentes.length > 0) {
                    meusub = item.dependentes
                    for (var x = 0; x < meusub.length; x++) {
                      var idx = '' + meusub[x].idempresaparceirousuario;
                      idx = '' + idx.match(/\d+/g);

                      meusub[x].id = idx;

                      if (meusub[x].urlfotoperfil == null || meusub[x].urlfotoperfil == '') {
                        meusub[x].urlfotoperfil = 'https://cdn-icons-png.flaticon.com/512/149/149071.png'
                      }
                    }
                  }


                  const funcaoClick = () => {
                    if (meusub.length == 0 || meusub == null) {
                      openSubmembros = false;
                      document.getElementById(id + '_div').style.display = 'none';
                      document.getElementById(id + '_div').style.height = '0px';
                      return false;
                    }

                    var altura = 50 * meusub.length;


                    if (openSubmembros == false) {
                      openSubmembros = true;
                      document.getElementById(id + '_div').style.display = 'flex';
                      document.getElementById(id + '_div').style.height = altura + 'px';
                    } else {
                      openSubmembros = false;
                      document.getElementById(id + '_div').style.display = 'none'
                      document.getElementById(id + '_div').style.height = '0px';
                    }
                  }

                  console.log('meusub', meusub)
                  console.log('item', item)



                  const clickAdd = (idusuario) => {

                    if (idusuario == null || idusuario == '') {
                      alert('Não é possivel cadastrar dependentes de usuário que não finalizaram o cadastro no Xuliz ')
                      return false;
                    }

                    location.href = './cadastrar_submembros?id=' + idusuario + '&sub=s'
                  }


                  const excluirMembro = async (idempresaparceirousuario, situacao) => {

                    var url = "https://us-central1-restapi-4777e.cloudfunctions.net/app/excluirMembro.php";
                    var fun = 'excluirMembro';
                    if (situacao && situacao == "PENDENTE") {
                      url = "https://us-central1-restapi-4777e.cloudfunctions.net/app/excluirMembroCelular.php";
                      fun = 'excluirMembroCelular';
                    }

                    var get_idempresaparceirousuario = idempresaparceirousuario;
                    get_idempresaparceirousuario = '' + get_idempresaparceirousuario.match(/\d+/g);

                    var idestabelecimento = dadosX.idestabelecimento
                    var token_estabelecimento = dadosX.tokenestabelecimento


                    const options = {
                      method: 'POST',
                      url: url,
                      headers: { 'Content-Type': 'application/json' },
                      params: {
                        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
                        funcao: fun,
                        token_estabelecimento: token_estabelecimento,
                        idestabelecimento: idestabelecimento,
                        idlinha: get_idempresaparceirousuario

                      },
                    };



                    await axios.request(options).then(function (response) {
                      console.log('novoCadastro', response.data);
                      var dados = response.data;

                      if (dados.erro != "N") {
                        alert(dados.mensagem)
                        return false;
                      }

                      Swal.fire(
                        'Sucesso!',
                        '' + dados.mensagem,
                        'success'
                      ).then(() => {
                        location.reload()
                      })



                    }).catch(function (error) {
                      console.error(error);
                      alert("Encontramos um problema tente mais tarde")
                    });

                  }


                  return (
                    <>
                      <div style={{ width: 900, height: 70, marginBottom: 10, flex: 1, display: 'flex' }} >

                        <div style={{ display: 'flex', flex: 0.1, alignItems: 'center', padding: 10, cursor: "pointer" }} onClick={() => { funcaoClick() }}>
                          <img style={{ height: 40, width: 40, borderRadius: 5 }} src={imagemRef}>

                          </img>

                        </div>


                        <div style={{ display: 'flex', flex: 0.4, alignItems: 'center', cursor: "pointer" }} onClick={() => { funcaoClick() }}>
                          <span style={{ fontSize: 12 }}>{id}</span>
                        </div>

                        <div style={{ display: 'flex', flex: 1, alignItems: 'center', cursor: "pointer" }} onClick={() => { funcaoClick() }}>
                          <span style={{ fontSize: 12 }}>{item.nome}</span>
                        </div>

                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', cursor: "pointer" }} onClick={() => { funcaoClick() }}>
                          <span style={{ fontSize: 12 }}>{item.celular}</span>
                        </div>

                        <div style={{ display: 'flex', flex: 0.6, justifyContent: 'center', alignItems: 'center', cursor: "pointer" }} onClick={() => { funcaoClick() }}>
                          <span style={{ fontSize: 12 }}>{item.situacao}</span>
                        </div>


                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', cursor: "pointer" }} onClick={() => { funcaoClick() }}>
                          <span style={{ fontSize: 12 }}>{dataCadastro}</span>
                        </div>

                        <div style={{ display: 'flex', flex: 0.6, justifyContent: 'center', alignItems: 'center', cursor: "pointer" }} onClick={() => { funcaoClick() }}>
                          <span style={{ fontSize: 12 }}>{Relacao}</span>
                        </div>

                        <div style={{ display: 'flex', flex: 0.2, justifyContent: 'center', alignItems: 'center', cursor: "pointer" }} onClick={() => { funcaoClick() }}>
                          <span style={{ fontSize: 12 }}>{meusub.length}</span>
                        </div>

                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                          <div style={{ width: 45, backgroundColor: '#142A30', height: 25, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => { clickAdd(item.idusuario) }}>
                            <span style={{ color: '#FFFFFF', fontSize: 10, fontWeight: 'bold' }}>Add+</span>
                          </div>

                          <div style={{ width: 25, backgroundColor: '#142A30', height: 25, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 5, cursor: 'pointer' }} onClick={() => { excluirMembro(item.idempresaparceirousuario, item.situacao) }}>
                            <FontAwesomeIcon icon={faTrash} style={{ fontSize: 10, color: "#FFFFFF" }} />
                          </div>
                        </div>

                      </div>

                      <div style={{ width: 860, height: 200, borderBottomStyle: 'solid', padding: 2, borderWidth: 0.4, backgroundColor: '#262626', display: 'none', margin: 10, flexDirection: 'column', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }} id={id + '_div'}>
                        {meusub.map((item2, index) => (
                          <div style={{ width: '100%', height: 20, marginBottom: 10, flex: 1, display: 'flex', marginTop: 10 }}>

                            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                              <div style={{ backgroundColor: '#f2e1c4', width: 20, height: 20, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ textAlignLast: 'center', fontSize: 12 }}>{index + 1}</span>
                              </div>
                            </div>

                            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                              <div style={{ width: 30, height: 30, borderRadius: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', borderWidth: 1, borderColor: '#f2e1c4', borderStyle: 'solid' }} src={item2.urlfoto}>
                                <img src={item2.urlfotoperfil} style={{ width: 30, height: 30 }}>
                                </img>
                              </div>
                            </div>

                            <div style={{ display: 'flex', flex: 0.8, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                              <span style={{ fontSize: 9, color: '#8f8f8f' }}>ID</span>
                              <p style={{ fontSize: 10, marginBottom: 0, color: 'white' }}>{item2.id}</p>
                            </div>

                            <div style={{ display: 'flex', flex: 3, flexDirection: 'column', justifyContent: 'center' }}>
                              <span style={{ fontSize: 9, color: '#8f8f8f' }}>Nome</span>
                              <p style={{ fontSize: 10, marginBottom: 0, color: 'white' }}>{item2.nome}</p>
                            </div>

                            <div style={{ display: 'flex', flex: 2, flexDirection: 'column', justifyContent: 'center' }}>
                              <span style={{ fontSize: 9, color: '#8f8f8f' }}>Nº Celular</span>
                              <p style={{ fontSize: 10, marginBottom: 0, color: 'white' }}>{item2.celular}</p>
                            </div>

                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center' }}>
                              <span style={{ fontSize: 9, color: '#8f8f8f' }}>Relação</span>
                              <p style={{ fontSize: 10, marginBottom: 0, color: 'white' }}>{item2.dependentetipo}</p>
                            </div>

                            <div style={{ display: 'flex', flex: 2, flexDirection: 'column', justifyContent: 'center' }}>
                              <span style={{ fontSize: 9, color: '#8f8f8f' }}>Data cadastro</span>
                              <p style={{ fontSize: 10, marginBottom: 0, color: 'white' }}>{convertDateFormat(item2.datestamp)}</p>
                            </div>

                            <div style={{ display: 'flex', flex: 2, flexDirection: 'column', justifyContent: 'center' }}>
                              <span style={{ fontSize: 9, color: '#8f8f8f' }}>Situação</span>
                              <p style={{ fontSize: 10, marginBottom: 0, color: 'white' }}>{item2.situacao}</p>
                            </div>

                            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => { excluirMembro(item2.idempresaparceirousuario, item2.situacao) }}>
                              <FontAwesomeIcon icon={faTrash} style={{ fontSize: 12, color: '#f1e2c4' }} />
                            </div>
                          </div>
                        ))}
                      </div>


                    </>
                  )
                })}
              </div>




            </div>
          </div>
        </div>

      </div> */}

    </>
  )
};

export default MembrosJS;

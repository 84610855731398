import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "../componentes/menu";
import { useState } from "react";
import { ApiContext } from "../../services/config.js";
import Step1 from "./Steps/Step1/Conciliacao.js";
import Step2 from "./Steps/Step2/Conciliacao.js";
import Step3 from "./Steps/Step3/ConciliacaoGrupo.js";
import { format } from "date-fns";
import { Button, Grid, Typography } from "@mui/material";

import arrowLeftGray from "../assets/icons/arrowLeftGray.png";
import arrowLeft727E81 from "../assets/icons/arrowLeft727E81.png";
import user142A3099 from "../assets/icons/user142A3099.png";
import XulisLoading from "../componentes/XulisLoading/index.js";
import reportOn from "../assets/icons/reportOn.png"
import MenuMobile from "../componentes/menuMobile";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { el } from "date-fns/locale";

const Conciliacao = () => {

    const navigate = useNavigate();
    const { firebase_endpoint, firebase_endpoint_sistemas } = useContext(ApiContext);

    const [listaConciliacao, setListaConciliacao] = useState([]);
    const [step, setStep] = useState(0);
    const [oneDay, setOneDay] = useState('')
    const [moreDays, setMoreDays] = useState({
        start: '',
        end: ''
    })
    const [startDay, setStartDay] = useState(false)
    const [startOneDay, setStartOneDay] = useState(false)
    const [rows, setRow] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);

    const [screenWidth, setScreenWidth] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [loading, setloading] = useState(true);
    const [menuOpen, setMenuOpen] = useState(true);
    const [lastDay, setlastDay] = useState('');
    const [conciliacaoAntes, setConciliacaoAntes] = useState([]);
    const [loadingConciliacao, setLoadingConciliacao] = useState(true);
    const [dadosX, setDadosX] = useState([]);

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const updateDimensions = () => {
        setIsMobile(window.innerWidth <= 800); // Defina o limite de largura para o modo mobile conforme necessário
        verificarNiveisLogin(dadosX);
        setScreenWidth(window.innerWidth);

        if (window.innerWidth <= 1400 && menuOpen) {
            setMenuOpen(false)
        }
        if (window.innerWidth > 1400 && menuOpen) {
            setMenuOpen(true)
        }
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;

        setStartDate((start));
        setEndDate(end);

        if (start && end) {
            setloading(true);
            VerificaChamar(end, start);
        }
    };

    const toggleCalendar = () => {
        setShowCalendar(true)
        return false;
    };

    const VerificaChamar = async (endDate, startDate, showCalendar) => {
        await sleep(300)
        getDadosSQL(true, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'))
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const real = (valor) => {
        if (isNaN(valor)) return '-'; // Evita erros ao tentar formatar algo que não é número

        valor = parseFloat(valor);

        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        });
    };

    const getData = async () => {
        var today = new Date();
        var day = today.getDate() + "";
        var month = (today.getMonth() + 1) + "";
        var year = today.getFullYear() + "";
        var hour = today.getHours() + "";
        var minutes = today.getMinutes() + "";

        day = checkZero(day);
        month = checkZero(month);
        year = checkZero(year);
        hour = checkZero(hour);
        minutes = checkZero(minutes);

        var data = day + "/" + month + "/" + year + " " + hour + ":" + minutes;

        return data;
        function checkZero(data) {
            if (data.length == 1) {
                data = "0" + data;
            }
            return data;
        }
    }

    const getDataInicial = async () => {
        var data = new Date();
        var hora = data.getHours();
        var minutos = data.getMinutes();

        // Se for antes das 14:40, subtrai 2 dias, senão subtrai 1 dia
        var diasParaSubtrair = (hora < 13 || (hora === 13 && minutos < 45)) ? 2 : 1;

        data.setDate(data.getDate() - diasParaSubtrair);

        // Aqui NÃO devemos redefinir a variável `data`
        var ano = data.getFullYear();
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var dia = String(data.getDate()).padStart(2, '0');

        return `${ano}-${mes}-${dia}`;
    };

    const getDataFinal = async () => {
        var data = new Date();
        var hora = data.getHours();
        var minutos = data.getMinutes();

        // Se for antes das 14:40, subtrai 2 dias, senão subtrai 1 dia
        var diasParaSubtrair = (hora < 14 || (hora === 14 && minutos < 40)) ? 2 : 1;

        data.setDate(data.getDate() - diasParaSubtrair);

        // Aqui NÃO devemos redefinir a variável `data`
        var ano = data.getFullYear();
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var dia = String(data.getDate()).padStart(2, '0');

        return `${ano}-${mes}-${dia}`;
    };

    const [estabelecimento, setEstabelecimento] = useState({})

    const getTokenEstabelciment = async (token) => {
        const form = new FormData();
        form.append("idempresa", "1350");
        form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
        form.append("metodo", "ListaEstabelecimentos");


        const options = {
            method: 'POST',
        };

        options.body = form;

        var rres = await fetch(`${firebase_endpoint}/painel/integracao/estabelecimento.php`, options).then(response => response.json());

        if (rres?.estabelecimentos) {
            const hasMatch = rres?.estabelecimentos.find(item => item.idestabelecimento === dadosX.idestabelecimento);
            setEstabelecimento(hasMatch);
        }

        var arrrayEstabelecimnto = rres.estabelecimentos;

        var tokenEstabelecimento = '';
        for (var x = 0; x < arrrayEstabelecimnto.length; x++) {
            if (arrrayEstabelecimnto[x].idestabelecimento == token) {
                tokenEstabelecimento = arrrayEstabelecimnto[x].token;
                break;
            }
        }

        return tokenEstabelecimento;

    }

    const getDadosSQL = async (dataSetada, ini, fim) => {

        setloading(true);

        await getDados()

        var dados = await localStorage.getItem('login')
        dados = JSON.parse(dados);
        var idestabele = dados.idestabelecimento;

        var getTokenEstabelecimneto = await getTokenEstabelciment(idestabele);

        if (getTokenEstabelecimneto == null) {
            getTokenEstabelecimneto = dados.tokenestabelecimento;
        }

        var datainicial = await getDataInicial()
        var datafinal = await getDataFinal()

        if (dataSetada == undefined) {

            var data = new Date();
            var data2 = new Date();

            data.setDate(data.getDate());

            setStartDate(data)
            setEndDate(data2)
        }

        if (dataSetada == true) {
            datainicial = ini;
            datafinal = fim;
        }

        if (!lastDay) {
            setlastDay(datainicial)
        }

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                idestabelecimento: idestabele,
                datavendainicial: datainicial,
                datavendafinal: datafinal
            })
        };

        // fetch('https://xuliz.com.br/painel/integracao/vendaxuliz.php', options)
        // fetch('https://www.xulizsistemas.com.br/retaguarda/integracao/venda/listarvendageral.php', options)
        fetch(`${firebase_endpoint_sistemas}/retaguarda/integracao/conciliacao/listarresultado.php`, options)
            .then(response => response.json())
            .then(response => {
                setListaConciliacao(response.retorno)
                setloading(false)
            })
            .catch(err => console.error(err));
    }

    const getConciliacao = async (ini, fim) => {

        setLoadingConciliacao(true)
        var dados = await localStorage.getItem('login')
        dados = JSON.parse(dados);
        var idestabele = dados.idestabelecimento;


        const datavendainicial = ini ? ini : await getDataInicial()
        const datavendafinal = fim ? fim : ini ? ini : await getDataFinal()

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                idestabelecimento: idestabele,
                datavendainicial: datavendainicial,
                datavendafinal: datavendafinal
            })
        };

        fetch(`${firebase_endpoint_sistemas}/retaguarda/integracao/conciliacao/listarresumo.php`, options)
            .then(response => response.json())
            .then(response => {
                setConciliacaoAntes(response.retorno)
                setLoadingConciliacao(false)
            })
            .catch(err => console.error(err));
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const getDados = async () => {
        var dados = await localStorage.getItem('login')
        if (dados == null) {
            navigate("/");
            return false;
        }
        dados = JSON.parse(dados)
        setDadosX(dados)
        verificarNiveisLogin(dados)
    }

    const verificarNiveisLogin = async (dadosX) => {
        var idperfil = dadosX.idusuariotipo;

        if (idperfil == "7") {
            await sleep(50)

            //financeiro somente relatorios
            // document.getElementById('realizar_vendaID').style.display = "none";
            // document.getElementById('menu_marketing').style.display = "none";
            // document.getElementById('menu_configuracoes').style.display = "none";

        }

        if (idperfil == "8") {
            await sleep(50)
            location.href = "./xloja";
        }

    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function calcMimMax(min, max, divide) {
        const calculated = screenWidth / divide;

        if (calculated > min && calculated < max) {
            return calculated;
        } else if (calculated < min) {
            return min;
        } else if (calculated > max) {
            return max;
        }

        return 16;
    }

    useEffect(() => {
        handleInitialLoad();
    }, []);

    function checkSteps(status, number, data) {
        if (status == 'new') {
            if (number == 0) {
                setStep(0);
                getConciliacao(data);
                getDadosSQL(true, data, data);
            } else if (number == 1) {
                setStep(1);
                getConciliacao(data);
                getDadosSQL(true, data, data);
            } else if (number == 2) {
                setStep(2);
                getDadosSQL(true, data.start, data.end);
            } else {
                setStep(step)
            }
        } else {
            if (number == 0) {
                setStep(0);
                getConciliacao(lastDay);
                getDadosSQL(true, lastDay, lastDay);
            } else if (number == 1 && oneDay) {
                setStep(1);
                getConciliacao(oneDay);
                getDadosSQL(true, oneDay, oneDay);
            } else if (number == 2 && moreDays.start && moreDays.end) {
                setStep(2);
                getDadosSQL(true, moreDays.start, moreDays.end);
            } else {
                setStep(step)
            }
        }
    }

    function handleInitialLoad() {
        getDadosSQL();
        getConciliacao();
    }


    const renderStep = () => {
        switch (step) {
            case 0:
                return <Step1
                    estabelecimento={estabelecimento}
                    screenWidth={screenWidth}
                    oneDay={oneDay}
                    lastDay={lastDay}
                    setOneDay={setOneDay}
                    moreDays={moreDays}
                    setMoreDays={setMoreDays}
                    VerificaChamar={VerificaChamar}
                    startDay={startDay}
                    setStartDay={setStartDay}
                    setStartOneDay={setStartOneDay}
                    getConciliacao={getConciliacao}
                    checkSteps={checkSteps}
                    step={step}
                    conciliacaoAntes={conciliacaoAntes}
                    listaConciliacao={listaConciliacao}
                    menuOpen={menuOpen}
                    real={real}
                    handleDateChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    handleChangePage={handleChangePage}
                    loading={loading}
                    rows={rows}
                    setStartDate={setStartDate}
                />;
            case 1:
                return <Step2
                    estabelecimento={estabelecimento}
                    screenWidth={screenWidth}
                    oneDay={oneDay}
                    lastDay={lastDay}
                    setOneDay={setOneDay}
                    moreDays={moreDays}
                    setMoreDays={setMoreDays}
                    VerificaChamar={VerificaChamar}
                    startDay={startDay}
                    setStartDay={setStartDay}
                    setStartOneDay={setStartOneDay}
                    getConciliacao={getConciliacao}
                    checkSteps={checkSteps}
                    step={step}
                    conciliacaoAntes={conciliacaoAntes}
                    listaConciliacao={listaConciliacao}
                    menuOpen={menuOpen}
                    real={real}
                    handleDateChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    handleChangePage={handleChangePage}
                    loading={loading}
                    rows={rows}
                    setStartDate={setStartDate}
                />;
            case 2:
                return <Step3
                    estabelecimento={estabelecimento}
                    screenWidth={screenWidth}
                    oneDay={oneDay}
                    lastDay={lastDay}
                    setOneDay={setOneDay}
                    moreDays={moreDays}
                    setMoreDays={setMoreDays}
                    VerificaChamar={VerificaChamar}
                    startDay={startDay}
                    setStartDay={setStartDay}
                    setStartOneDay={setStartOneDay}
                    getConciliacao={getConciliacao}
                    checkSteps={checkSteps}
                    step={step}
                    conciliacaoAntes={conciliacaoAntes}
                    listaConciliacao={listaConciliacao}
                    menuOpen={menuOpen}
                    real={real}
                    handleDateChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    handleChangePage={handleChangePage}
                    loading={loading}
                    rows={rows}
                    setStartDate={setStartDate}
                />;
            default:
                return null;
        }
    };

    const textos = [
        'Buscando conciliação ...',
        'Buscando Transações Excluídas ...',
        'Buscando Transações Incluídas ...',
    ];

    const [textoAtual, setTextoAtual] = useState(textos[0]);

    useEffect(() => {
        if (!loading && !loadingConciliacao) {
            return
        }

        const interval = setInterval(() => {
            setTextoAtual((prev) => {
                const currentIndex = textos.indexOf(prev);
                const nextIndex = (currentIndex + 1) % textos.length;
                return textos[nextIndex];
            });
        }, 2000); // Altere o intervalo conforme necessário

        return () => clearInterval(interval);
    }, [loading, loadingConciliacao]);

    useEffect(() => {
        if (!estabelecimento) {
            const timeout = setTimeout(() => {
                getTokenEstabelciment(dadosX.idestabelecimento);
            }, 4000);

            return () => clearTimeout(timeout); // Sempre limpar o timeout ao desmontar
        }
    }, [estabelecimento]); // Dependência para reagir a mudanças em estabelecimento

    function handleNavigation(direction) {
        if (direction === 'next') {
            if (step == 0 && oneDay) {
                setStep(1);
                getConciliacao(oneDay);
                getDadosSQL(true, oneDay, oneDay);
            } else if (step == 0 && moreDays.start && moreDays.end) {
                setStep(2);
                getDadosSQL(true, moreDays.start, moreDays.end);
            } else if (step == 1 && moreDays.start && moreDays.end) {
                setStep(2);
                getDadosSQL(true, moreDays.start, moreDays.end);
            } else {
                setStep(step)
            }
        } else if (direction === 'prev') {
            if (step == 2 && oneDay) {
                setStep(1);
                getConciliacao(oneDay);
                getDadosSQL(true, oneDay, oneDay);
            } else if (step == 2 && !oneDay) {
                setStep(0);
                getConciliacao(lastDay);
                getDadosSQL(true, lastDay, lastDay);
            } else if (step == 1) {
                setStep(0);
                getConciliacao(lastDay);
                getDadosSQL(true, lastDay, lastDay);
            } else {
                setStep(step)
            }
        }
    }




    if (isMobile == true) {
        return (
            <>
                <MenuMobile page="conciliacao" />

                {!loading && !loadingConciliacao ?
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>

                        <Grid sx={{
                            width: '100%',
                            height: '50px',
                            display: 'flex',
                            paddingX: '20px',
                            paddingY: '17px',
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #F0EFEB'
                        }}>
                            <Grid sx={{ gap: '25px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Grid sx={{ gap: '20px', display: 'flex', flexDirection: 'row' }}>
                                    <img src={arrowLeft727E81}
                                        style={{
                                            width: "10px",
                                            height: "10px",
                                            cursor: step === 0 ? 'default' : 'pointer',
                                            opacity: step === 0 ? 0.5 : 1
                                        }}
                                        alt="Seta"
                                        onClick={() => handleNavigation('prev')}
                                    />

                                    <img src={arrowLeft727E81}
                                        style={{
                                            width: "10px",
                                            height: "10px",
                                            transform: 'rotate(180deg)',
                                            cursor: step == 2 ? 'default' : step === 1 && (!oneDay || !moreDays.start) ? 'default' : 'pointer',
                                            opacity: step == 2 ? 0.5 : step === 1 && (!oneDay || !moreDays.start) ? 0.5 : 1
                                        }}
                                        alt="Seta"
                                        onClick={() => handleNavigation('next')}
                                    />
                                </Grid>


                                <Grid sx={{ gap: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '13px', color: '#142A3066' }}>Relatórios</Typography>
                                    <Grid sx={{ width: '2px', height: '15px', bgcolor: '#142A3066' }} />
                                    <Typography sx={{ fontSize: '13px', color: '#142A30' }}>Conciliação</Typography>
                                    <Grid sx={{ width: '2px', height: '15px', bgcolor: '#142A3066' }} />
                                    <Typography sx={{ fontSize: '13px', color: '#142A30' }}>
                                        {
                                            step == 0 ? 'Última Conciliação' : step == 1 ? 'Conciliação Data' : 'Conciliação Período'
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <div style={{ width: '100%', overflow: 'auto' }}>
                            {renderStep()}
                        </div>
                    </div>
                    :
                    <div style={{
                        width: '100%',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}>
                        <XulisLoading />
                        <Typography sx={{ color: '#142A3099', fontSize: '14px', fontWeight: 700 }}>
                            {textoAtual}
                        </Typography>
                    </div>}
            </>
        )
    }

    //desktop
    return (
        <>
            <div style={{ maxWidth: '1920px', height: '100vh', display: "flex" }}>
                {/* Menu lateral */}
                <div style={{ backgroundColor: '#262626' }}>
                    <Menu page="conciliacao" menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                </div>

                {!loading && !loadingConciliacao ?
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>

                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>

                            <Grid sx={{
                                width: '100%',
                                height: '50px',
                                display: 'flex',
                                paddingX: '25px',
                                paddingY: '17px',
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                borderBottom: '1px solid #F0EFEB'
                            }}>

                                <Grid sx={{ gap: '25px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Grid sx={{ gap: '20px', display: 'flex', flexDirection: 'row' }}>
                                        <img src={arrowLeft727E81}
                                            style={{
                                                width: "10px",
                                                height: "10px",
                                                cursor: step === 0 ? 'default' : 'pointer',
                                                opacity: step === 0 ? 0.5 : 1
                                            }}
                                            alt="Seta"
                                            onClick={() => handleNavigation('prev')}
                                        />

                                        <img src={arrowLeft727E81}
                                            style={{
                                                width: "10px",
                                                height: "10px",
                                                transform: 'rotate(180deg)',
                                                cursor: step == 2 ? 'default' : step === 1 && (!oneDay || !moreDays.start) ? 'default' : 'pointer',
                                                opacity: step == 2 ? 0.5 : step === 1 && (!oneDay || !moreDays.start) ? 0.5 : 1
                                            }}
                                            alt="Seta"
                                            onClick={() => handleNavigation('next')}
                                        />
                                    </Grid>


                                    <Grid sx={{ gap: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '13px', color: '#142A3066' }}>Relatórios</Typography>
                                        <Grid sx={{ width: '2px', height: '15px', bgcolor: '#142A3066' }} />
                                        <Typography sx={{ fontSize: '13px', color: '#142A30' }}>Conciliação</Typography>
                                        <Grid sx={{ width: '2px', height: '15px', bgcolor: '#142A3066' }} />
                                        <Typography sx={{ fontSize: '13px', color: '#142A30' }}>
                                            {
                                                step == 0 ? 'Última Conciliação' : step == 1 ? 'Conciliação Data' : 'Conciliação Período'
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {/* <Grid sx={{ gap: '5px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>


                                    <Grid sx={{
                                        gap: '5px',
                                        padding: '1px 8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        borderRadius: '30px',
                                        border: '1.5px solid #142A3099'
                                    }}>
                                        <FontAwesomeIcon icon={faSearch} style={{
                                            width: '10px',
                                            height: '10px',
                                            color: "#142A3099"
                                        }} />
                                        <Typography sx={{ fontSize: '13px', color: '#142A3099' }}>Pesquisa</Typography>
                                    </Grid>
                                    <Grid sx={{
                                        width: "22px",
                                        height: "22px",
                                        display: 'flex',
                                        padding: '4px 4px 5px 2px',
                                        alignItems: 'center',
                                        borderRadius: '30px',
                                        border: '1.5px solid #142A3099'
                                    }}>
                                        <img src={user142A3099} style={{ width: "15px", height: "15px" }} alt="Seta" />
                                    </Grid>

                                </Grid> */}

                            </Grid>

                            {renderStep()}
                        </div>

                    </div>
                    :
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <XulisLoading />
                        <Typography sx={{ color: '#142A3099', fontSize: '14px', fontWeight: 700 }}>
                            {textoAtual}
                        </Typography>
                    </div>}

            </div >
        </>
    )
};

export default Conciliacao;

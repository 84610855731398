import { Grid, Typography } from "@mui/material";
import { useState } from "react";

const MenuClose = ({
    setMenuOpen,
    arrowLeftGray,
    logo,
    bag,
    bagOn,
    bagOff,
    marketing,
    marketingOn,
    marketingOff,
    dolar,
    dolarOn,
    dolarOff,
    report,
    reportOn,
    reportOff,
    sair,
    configuracoes,
    arrow,
    pageRef,
    urlLogo,
    irPage,
    onGroupContainer13Click,
    Estabelecimentos,
    menuOptions
}) => {

    const menu = [
        { name: 'XLoja', page: 'xloja', iconOn: bag, iconOff: bagOff },
        { name: 'Marketing', page: 'marketing', iconOn: marketing, iconOff: marketingOff },
        { name: 'Recebíveis', page: 'recebiveis', iconOn: dolar, iconOff: dolarOff },
        { name: 'Conciliação', page: 'conciliacao', iconOn: report, iconOff: reportOff, subMenu: [{ name: 'Conciliação', page: 'conciliacao' }] },
    ]

    const [subMenu, setSubMenu] = useState([])

    return (
        <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
            <div style={{
                width: '100px',
                height: '100vh',
                display: "flex",
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: "#142A30"
            }}>

                <div style={{
                    gap: '15px',
                    width: '100%',
                    display: "flex",
                    paddingTop: '32px',
                    alignItems: 'center',
                    paddingBottom: '69px',
                    justifyContent: 'flex-end'
                }}>

                    <div>
                        <img src={logo} style={{ width: '33.61px', height: '26.85px', zIndex: 2 }} />
                    </div>

                    <div style={{
                        width: '18px',
                        height: '22px',
                        cursor: 'pointer',
                        backgroundColor: '#5A8292',
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px'
                    }} onClick={() => setMenuOpen(true)}>
                        <img
                            src={arrow}
                            style={{
                                width: 4,
                                height: 8,
                                margin: '7px',
                                color: '#F0EFEB', 
                                transform: 'rotate(0deg)',
                            }}
                        />
                    </div>

                </div>

                <div style={{
                    flex: 5,
                    width: '100%',
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>

                    {menu.map((i) => {
                        return (
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                                paddingLeft: pageRef == i.page && i.subMenu ? '20px' : null
                            }}>
                                <div style={{
                                    display: "flex",
                                    cursor: "pointer",
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    borderRadius: '20px',
                                    backgroundColor: pageRef == i.page ? '#0D2128' : null,
                                }} onClick={() => irPage(i.page)}>
                                    <div key={i.page} style={{
                                        width: '30px',
                                        height: '30px',
                                        display: "flex",
                                        cursor: "pointer",
                                        margin: '9px',
                                        alignItems: 'center',
                                        borderRadius: '11px',
                                        justifyContent: 'center',
                                        backgroundColor: pageRef == i.page ? '#9DE200' : '#0D2128'
                                    }}>
                                        <img
                                            src={pageRef == i.page ? i.iconOn : i.iconOff}
                                            style={{ width: 14, height: 14 }}
                                        />
                                    </div>
                                </div>

                                {pageRef == i.page && i.subMenu ? <div style={{
                                    width: '17px',
                                    height: '22px',
                                    cursor: 'pointer',
                                    backgroundColor: '#5A8292',
                                    borderTopRightRadius: '10px',
                                    borderBottomRightRadius: '10px',
                                }} onClick={() => setSubMenu(subMenu.length > 0 ? [] : i.subMenu)}>
                                    <img
                                        src={arrow}
                                        style={{
                                            width: 4,
                                            height: 8,
                                            margin: '7px',
                                            transform: subMenu.length > 0 ? 'rotate(180deg)' : 'rotate(0deg)'
                                        }}
                                    />
                                </div> : null}

                            </div>
                        );
                    })}
                </div>

                <div style={{ width: '55px', paddingBottom: '15px' }}>
                    {Estabelecimentos()}
                </div>

                <div style={{ width: '54px', paddingBottom: '27px' }}>
                    {menuOptions()}
                </div>

            </div>

            {subMenu.length > 0 ? <div style={{
                zIndex: 2,
                left: '100px',
                width: '260px',
                height: '100vh',
                position: 'absolute',
                backgroundColor: '#F0EFEB',
            }}>
                {subMenu.map((i) => (
                    <Grid sx={{ padding: '25px' }}>
                        <Grid sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            bgcolor: '#142A301A',
                            borderRadius: '20px',
                            alignItems: 'center',
                            padding: '9.5px 10px',
                            justifyContent: 'space-between',
                        }}>
                            <Typography sx={{ fontSize: '12px', color: '#142A3099' }}>{i.name}</Typography>
                            <img src={arrowLeftGray} style={{ width: "15px", height: "15px" }} alt="Seta" />
                        </Grid>
                    </Grid>))}
            </div> : null}

        </div>
    )
}

export default MenuClose;

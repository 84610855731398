import { useEffect, useRef, useState } from "react";
import { Grid, TextField, Tooltip, Typography } from "@mui/material";
import exclamacaoGreen from "../../../assets/icons/exclamacaoGreen.png";
import xuxuFace from "../../../assets/icons/xuxuFace.png";
import leftRigth from "../../../assets/icons/leftRigth.png";
import arrowBottom142A3066 from "../../../assets/icons/arrowBottom142A3066.png";
import Plus142A30 from "../../../assets/icons/Plus142A30.png";
import Less142A30 from "../../../assets/icons/Less142A30.png";
import gardenValue from "../../../assets/icons/gardenValue.png";
import checkTransacoes from "../../../assets/icons/checkTransacoes.png";
import Tabela from "./Tabela";
import TabelaExcluidosIncluidos from "./TabelaExcluidosIncluidos";
import Stylesheet from "reactjs-stylesheet";
import DatePicker from "react-datepicker";
import ReactInputMask from "react-input-mask";
import { format } from "date-fns";

const Conciliacao = ({ step, estabelecimento, screenWidth, moreDays, setMoreDays, lastDay, oneDay, setOneDay, listaConciliacao, conciliacaoAntes, real, startDay, setStartDay, setStartOneDay, checkSteps }) => {

    const excluidos = listaConciliacao.find((i) => i.tipo == "E")
    const incluidos = listaConciliacao.find((i) => i.tipo == "I")

    let minutosTotais = 0;

    // Garantir que estamos lidando com números para a soma
    if (Array.isArray(excluidos)) {
        excluidos.forEach(excluido => {
            if (typeof excluido.qtde === 'number') {
                minutosTotais += excluido.qtde;
            }
        });
    }

    if (Array.isArray(incluidos)) {
        incluidos.forEach(incluido => {
            if (typeof incluido.qtde === 'number') {
                minutosTotais += incluido.qtde;
            }
        });
    }

    // Caso os dados não sejam arrays, apenas adicione os valores de `qtde` diretamente
    if (excluidos?.qtde) {
        minutosTotais += Number(excluidos.qtde);
    }

    if (incluidos?.qtde) {
        minutosTotais += Number(incluidos.qtde);
    }

    const totalTransacoes = listaConciliacao
        .filter((i) => i.tipo == 'I')
        .reduce((acc, i) => acc + parseFloat(i.total), 0);


    const [startOneDate, setOneStartDate] = useState(null); // Estado para armazenar a data selecionada
    const [loading, setLoading] = useState(false); // Estado de carregamento

    const handleDateChange = (date) => {
        setOneStartDate(date); // Atualiza a data selecionada

        if (date) {
            const formattedDate = date.toISOString().split('T')[0];
            setOneDay(formattedDate);
            setLoading(true);
            checkSteps('new', 1, formattedDate)
        }
    };

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDatesChanges = (dates) => {
        if (!dates) return;
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setMoreDays({
            start: start ? start.toISOString().split('T')[0] : null,
            end: end ? end.toISOString().split('T')[0] : null
        });

        let data = {
            start: start ? start.toISOString().split('T')[0] : null,
            end: end ? end.toISOString().split('T')[0] : null
        }

        if (start && end) {
            checkSteps('new', 2, data)
        }
    };

    const [tooltip, seTooltip] = useState(false)
    const handleClose = () => {
        seTooltip(false);
    };

    const handleOpen = () => {
        seTooltip(true);
    };

    const [calendarOne, setCalendarOne] = useState(false);
    const [calendarTwo, setCalendarTwo] = useState(false);
    const datepickerRefOne = useRef(null);
    const datepickerRefTwo = useRef(null);


    useEffect(() => {
        function handleClickOutside(event) {
            if (datepickerRefOne.current && !datepickerRefOne.current.contains(event.target)) {
                setCalendarOne(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (datepickerRefTwo.current && !datepickerRefTwo.current.contains(event.target)) {
                setCalendarTwo(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Grid sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: screenWidth <= 800 ? 'column' : 'row',
            paddingInline: screenWidth <= 800 ? '36px' : '90px',
            paddingTop: screenWidth <= 800 ? '20px' : '55px'
        }}>

            <Grid sx={{ maxWidth: '720px' }}>

                <Grid sx={{ paddingBottom: screenWidth <= 800 ? '20px' : '35px' }}>
                    <Grid sx={{
                        display: 'flex',
                        alignItems: screenWidth <= 800 ? 'start' : 'flex-start',
                        flexDirection: screenWidth <= 800 ? 'column' : 'row',
                        paddingBottom: '10px',
                        justifyContent: 'space-between'
                    }}>
                        <Typography sx={{
                            fontWeight: 700,
                            fontSize: '32px',
                            color: '#142A30',
                        }}>
                            Relatório Conciliação
                        </Typography>
                        <Tooltip
                            arrow
                            PopperProps={{
                                modifiers: [
                                    {
                                        name: 'preventOverflow',
                                        options: {
                                            boundary: 'window',
                                        },
                                    },
                                ],
                            }}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: '#181818', // Cor de fundo
                                        color: 'white', // Cor do texto
                                        fontSize: '14px', // Tamanho da fonte
                                        padding: '10px', // Espaçamento interno
                                        borderRadius: '8px', // Bordas arredondadas
                                    },
                                },
                                arrow: {
                                    sx: {
                                        width: '35px',
                                        height: '22px',
                                        color: '#181818',
                                    }
                                }
                            }}
                            open={tooltip}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            title={
                                <Grid sx={{
                                    gap: '10px',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: 700, bgcolor: '#181818', color: 'white', textAlign: 'start' }}>
                                        Explicação
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontSize: '12px', bgcolor: '#181818', color: 'white', textAlign: 'start' }}>
                                        As vendas feitas em finais de semana ou feriados serão conciliadas e
                                        estarão disponíveis no primeiro dia útil seguinte. Para visualizar,
                                        basta escolher a data no filtro "Data:".
                                    </Typography>
                                </Grid>
                            }>
                            <Grid sx={{ gap: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', bgcolor: '#f0efeb', padding: '5px 10px', border: '1px solid #9DE200', borderRadius: '20px' }}>
                                <img src={exclamacaoGreen} style={{ width: "10px", height: "10px" }} alt="Exclamação" />
                                <Typography sx={{ fontSize: '10px', color: '#7FA800' }}>
                                    A conciliação é feita somente em dias úteis
                                </Typography>
                            </Grid>
                        </Tooltip>
                    </Grid>
                    <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>
                        Este é o resumo da conciliação automática realizada pelo
                        <span style={{ fontWeight: 700 }}>
                            {' '}Xuxu dia {
                                format(`${lastDay}T00:00:00`, 'dd/MM/yyyy')
                            } às 13:23
                        </span> na empresa
                        <span style={{ fontWeight: 700 }}>
                            {' '}{estabelecimento?.estabelecimento} / CNPJ {estabelecimento?.cnpj}.
                        </span>
                    </Typography>
                </Grid>

                <Grid sx={{
                    gap: screenWidth <= 800 ? '10px' : '20px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: screenWidth <= 800 ? 'column' : 'row',
                    alignItems: screenWidth <= 800 ? 'start' : 'center',
                    paddingBottom: '35px'
                }}>

                    <Typography onClick={() => checkSteps('old', 0)}
                        sx={{
                            fontSize: '13px',
                            color: '#142A30',
                            cursor: 'pointer',
                        }}>
                        Última conciliação {lastDay ? format(`${lastDay}T00:00:00`, 'dd/MM/yyyy') : ''}
                    </Typography>

                    {screenWidth <= 800 ? null : <Grid sx={{ backgroundColor: '#142A3066', width: '2px', height: '13px' }} />}

                    <Grid item sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}>
                        <Typography onClick={() => checkSteps('old', 1)} sx={{
                            cursor: 'pointer',
                            fontSize: '13px',
                            color: '#142A3066'
                        }}>
                            Data
                        </Typography>

                        <Grid sx={{
                            gap: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                        }}
                            ref={datepickerRefOne}
                        >
                            <DatePicker
                                wrapperClassName="datePicker"
                                selected={oneDay ? new Date(oneDay + 'T00:00:00') : startOneDate}
                                onChange={handleDateChange} // Modificado para receber uma única data
                                locale="pt"
                                dateFormat="dd/MM/yyyy"
                                customInput={
                                    <ReactInputMask
                                        mask="99/99/9999"
                                        value={oneDay ? format(`${oneDay}T00:00:00`, 'dd/MM/yyyy') : ''}
                                        onChange={(e) => setOneStartDate(new Date(e.target.value))}
                                        autoComplete="off"
                                    >
                                        {() => (
                                            <TextField
                                                onClick={() => setCalendarOne((prev) => !prev)}
                                                placeholder={oneDay ? format(`${oneDay}T00:00:00`, 'dd/MM/yyyy') : "00/00/0000"}
                                                autoComplete="off"
                                                sx={{
                                                    '& .MuiOutlinedInput-input': {
                                                        cursor: 'pointer',
                                                        fontSize: '13px',
                                                        color: '#142A3066',
                                                        padding: '1px 0px 0px 5px',
                                                        maxWidth: '84px'
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: 'none',
                                                        },
                                                        '&:hover fieldset': {
                                                            border: 'none',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: 'none',
                                                        },
                                                    },
                                                    '& .MuiInputBase-input::placeholder': {
                                                        color: '#142A3066',
                                                        opacity: 1,
                                                    },
                                                }}
                                            />
                                        )}
                                    </ReactInputMask>
                                }
                            />
                            <img
                                src={arrowBottom142A3066}
                                style={{
                                    width: "9px",
                                    height: "4px",
                                    transition: 'transform 0.2s ease',
                                    transform: calendarOne ? 'rotate(180deg)' : 'rotate(0deg)',
                                }} alt="Setas"
                            />
                        </Grid>
                    </Grid>

                    {screenWidth <= 800 ? null : <Grid sx={{ backgroundColor: '#142A3066', width: '2px', height: '13px' }} />}

                    <Grid item sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}>
                        <Typography onClick={() => checkSteps('old', 2)} sx={{
                            fontSize: '13px',
                            cursor: 'pointer',
                            color: '#142A3066'
                        }}>
                            Período
                        </Typography>
                        <Grid item sx={{
                            gap: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                        }}
                            ref={datepickerRefTwo}
                        >
                            <DatePicker
                                wrapperClassName="datePicker"
                                onChange={handleDatesChanges}
                                startDate={moreDays?.start ? new Date(moreDays.start + 'T00:00:00') : startDate}
                                endDate={moreDays?.end ? new Date(moreDays.end + 'T00:00:00') : endDate}
                                selectsRange
                                locale="pt"
                                dateFormat="dd/MM/yyyy"
                                customInput={
                                    <ReactInputMask
                                        style={{ width: '33%' }}
                                        placeholder="Período"
                                        type="text"
                                        mask="99/99/9999 - 99/99/9999"
                                        value={
                                            moreDays.start && moreDays.end
                                                ? `${format(new Date(`${moreDays.start}T00:00:00`), 'dd/MM/yyyy')} - ${format(new Date(`${moreDays.end}T00:00:00`), 'dd/MM/yyyy')}`
                                                : moreDays.start
                                                    ? `${format(new Date(`${moreDays.start}T00:00:00`), 'dd/MM/yyyy')}`
                                                    : ''
                                        }
                                        autoComplete="off"
                                    >
                                        {() => (
                                            <TextField
                                                onClick={() => setCalendarTwo((prev) => !prev)}
                                                placeholder={
                                                    moreDays.start && moreDays.end
                                                        ? `${format(new Date(`${moreDays.start}T00:00:00`), 'dd/MM/yyyy')} - ${format(new Date(`${moreDays.end}T00:00:00`), 'dd/MM/yyyy')}`
                                                        : "00/00/0000 - 00/00/0000"
                                                }
                                                autoComplete="off"
                                                sx={{
                                                    '& .MuiOutlinedInput-input': {
                                                        cursor: 'pointer',
                                                        fontSize: '13px',
                                                        opacity: 1,
                                                        color: '#454a4b',
                                                        padding: '1px 0px 0px 5px',
                                                        maxWidth: moreDays.start ? '170px' : '177px',
                                                        color: '#142A3066',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: 'none',
                                                        },
                                                        '&:hover fieldset': {
                                                            border: 'none',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: 'none',
                                                        },
                                                        '& .MuiInputBase-input::placeholder': {
                                                            color: '#142A3066',
                                                            opacity: 1,
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                    </ReactInputMask>
                                }
                            />
                            <img
                                src={arrowBottom142A3066}
                                style={{
                                    width: "9px",
                                    height: "4px",
                                    transition: 'transform 0.2s ease',
                                    transform: calendarTwo ? 'rotate(180deg)' : 'rotate(0deg)',
                                }} alt="Setas"
                            />
                        </Grid>
                    </Grid>

                </Grid>

                <Grid sx={{
                    gap: '20px',
                    display: 'flex',
                    flexDirection: screenWidth <= 800 ? 'column' : 'row',
                    paddingBottom: '35px'
                }}>

                    <Grid sx={{ gap: '20px', width: '100%', width: '100%', maxWidth: screenWidth <= 800 ? '100%' : '190px', display: 'flex', justifyContent: 'space-around', flexDirection: 'column', bgcolor: '#F0EFEB', borderRadius: '20px', padding: '15px' }}>
                        <Grid sx={{ gap: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={Plus142A30} style={{ width: "40px", height: "40px" }} alt="Setas" />
                            <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#142A30' }}>{incluidos?.qtde || '0'}</Typography>
                        </Grid>
                        <Grid>
                            <Typography sx={{ color: '#142A3099', fontSize: '14px' }}>Transações incluídas</Typography>
                        </Grid>
                    </Grid>

                    <Grid sx={{ gap: '20px', width: '100%', width: '100%', maxWidth: screenWidth <= 800 ? '100%' : '300px', display: 'flex', justifyContent: 'space-around', flexDirection: 'column', bgcolor: '#F0EFEB', borderRadius: '20px', padding: '15px' }}>
                        <Grid sx={{ gap: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={gardenValue} style={{ width: "40px", height: "40px" }} alt="Setas" />
                            <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#142A30' }}>{real(totalTransacoes)}</Typography>
                        </Grid>
                        <Grid>
                            <Typography sx={{ color: '#142A3099', fontSize: '14px' }}>Diferença total corrigida</Typography>
                        </Grid>
                    </Grid>

                    <Grid sx={{ gap: '20px', width: '100%', width: '100%', maxWidth: screenWidth <= 800 ? '100%' : '190px', display: 'flex', justifyContent: 'space-around', flexDirection: 'column', bgcolor: '#F0EFEB', borderRadius: '20px', padding: '15px' }}>
                        <Grid sx={{ gap: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={Less142A30} style={{ width: "40px", height: "40px" }} alt="Setas" />
                            <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#142A30' }}>{excluidos?.qtde || '0'}</Typography>
                        </Grid>
                        <Grid>
                            <Typography sx={{ color: '#142A3099', fontSize: '14px' }}>Transações excluídas</Typography>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid sx={{ paddingBottom: '35px' }}>
                    <Grid sx={{ paddingBottom: '10px' }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#5A8292', paddingBottom: '10px' }}>Antes da conciliação</Typography>
                        <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>👀 Estes são os valores recebidos pelo Xuxu, ainda sem nenhum ajuste ou conciliação realizados.</Typography>
                    </Grid>

                    <Tabela
                        real={real}
                        rows={conciliacaoAntes ? conciliacaoAntes : []}
                    />

                </Grid>

                <Grid sx={{ height: '1px', bgcolor: '#5A8292', marginBottom: '35px' }} />

                <Grid sx={{ paddingBottom: '35px' }}>
                    <Grid sx={{ paddingBottom: '10px' }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#5A8292', paddingBottom: '10px' }}>Transações excluídas pelo Xuxu</Typography>
                        <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>❌ Essas foram todas as transações que o Xuxu excluiu no lançamento do caixa.</Typography>
                    </Grid>

                    <TabelaExcluidosIncluidos
                        tipe={'E'}
                        real={real}
                        rows={excluidos ? excluidos : []}
                    />

                </Grid>

                <Grid sx={{ paddingBottom: '35px' }}>
                    <Grid sx={{ paddingBottom: '10px' }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#5A8292', paddingBottom: '10px' }}>Transações incluídas pelo Xuxu</Typography>
                        <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>➕ Essas foram todas as transações que o Xuxu incluiu no lançamento do caixa.</Typography>
                    </Grid>

                    <TabelaExcluidosIncluidos
                        tipe={'I'}
                        real={real}
                        rows={incluidos ? incluidos : []}
                    />

                </Grid>

                <Grid sx={{ marginTop: '35px', marginBottom: '50px', display: 'flex', flexDirection: 'row', gap: '15px', bgcolor: '#9DE200', padding: '15px', borderRadius: '20px' }}>
                    <img src={xuxuFace} style={{ width: "34px", height: "34px" }} alt="Exclamação" />


                    {excluidos?.detalhe.length > 0 || incluidos?.detalhe.length > 0 ?
                        <Typography sx={{ fontSize: '14px' }}>
                            <strong>O Xuxu economizou para você {minutosTotais * 3} minutos preciosos
                                do seu tempo.</strong> Aproveite essa oportunidade para focar no que realmente faz a diferença na sua
                            empresa. Por aqui, pode deixar com a gente! 😄
                        </Typography>
                        :
                        <Typography sx={{ fontSize: '14px' }}>
                            <strong>O Xuxu analisou que até o momento, tudo está correto em seu caixa, então você pode
                                ficar tranquilo.</strong> Aproveite essa oportunidade para concentrar seus esforços nas áreas que realmente impactam o sucesso da sua empresa.
                            Aqui, nós cuidaremos de tudo para você! 😄
                        </Typography>
                    }

                </Grid>

            </Grid>

        </Grid >
    )

}

export default Conciliacao;
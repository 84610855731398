import { useEffect, useRef, useState } from "react";
import { Grid, TextField, Tooltip, Typography } from "@mui/material";
import exclamacaoGreen from "../../../assets/icons/exclamacaoGreen.png";
import leftRigth from "../../../assets/icons/leftRigth.png";
import arrowBottom142A3066 from "../../../assets/icons/arrowBottom142A3066.png";
import gardenValue from "../../../assets/icons/gardenValue.png";
import checkTransacoes from "../../../assets/icons/checkTransacoes.png";
import arrowLeftRigth from "../../../assets/icons/arrowLeftRigth.png";
import Plus142A30 from "../../../assets/icons/Plus142A30.png";
import Less142A30 from "../../../assets/icons/Less142A30.png";
import { ResponsiveBar } from '@nivo/bar'
import TabelaAgrupadoExcluidosIncluidos from "./TabelaAgrupadoExcluidosIncluidos";
import { format } from "date-fns";
import Stylesheet from "reactjs-stylesheet";
import DatePicker, { registerLocale } from "react-datepicker";
import ReactInputMask from "react-input-mask";
import xuxuFace from "../../../assets/icons/xuxuFace.png";

const ConciliacaoGrupo = ({ estabelecimento, screenWidth, moreDays, setMoreDays, lastDay, oneDay, setOneDay, listaConciliacao, real, startDay, setStartDay, setStartOneDay, checkSteps }) => {

    const excluidosGrupo = listaConciliacao?.filter((i) => i.tipo == "E");
    const incluidosGrupo = listaConciliacao?.filter((i) => i.tipo == "I")

    // console.log('excluidosGrupo', excluidosGrupo);

    // if (excluidosGrupo.length > 0) {
    //     const totalQtde = incluidosGrupo.reduce((acc, item) => acc + Number(item.qtde), 0);
    //     console.log('Total de qtde:', totalQtde);
    // }

    const grupoData = listaConciliacao?.reduce((acc, item) => {
        const { data, qtde } = item;

        if (!acc[data]) {
            acc[data] = {
                date: format(`${data}T00:00:00`, 'dd/MM/yy'),
                qtde: 0,
                dateColor: "rgb(229, 115, 115)",
            };
        }

        acc[data].qtde += Number(qtde); // Soma corretamente

        return acc;
    }, {});

    let minutosTotais = 0;

    // Função para somar os valores de 'qtde' em um array de objetos
    const somaQtde = (grupo) => {
        return grupo?.reduce((total, item) => {
            const qtde = Number(item.qtde); // Converte 'qtde' para número
            return !isNaN(qtde) ? total + qtde : total; // Soma se 'qtde' for um número válido
        }, 0);
    };

    // Somar as quantidades de 'qtde' de 'incluidosGrupo' e 'excluidosGrupo'
    minutosTotais += somaQtde(incluidosGrupo);
    minutosTotais += somaQtde(excluidosGrupo);

    // Converte o objeto em um array, garantindo que sempre seja um array válido
    const resultado = grupoData ? Object.values(grupoData) : [];

    // Garante que estamos lidando apenas com números válidos
    const qtdeArray = resultado.map((item) => Number(item.qtde) || 0);

    // Encontra o maior e o menor valor de qtde
    const maxQtde = qtdeArray.length > 0 ? Math.max(...qtdeArray) : 0;
    const minQtde = qtdeArray.length > 0 ? Math.min(...qtdeArray) : 0;

    // Define o intervalo de salto (padrão 5, mas 15 se a diferença for grande)
    const diferenca = maxQtde - minQtde;
    const step = diferenca > 50 ? 15 : 5; // Ajuste aqui conforme necessário

    // Arredonda para o próximo múltiplo do step escolhido (mínimo 5)
    const maxTick = Math.max(step, Math.ceil(maxQtde / step) * step);

    // Define os valores do eixo Y com o step dinâmico
    const tickValues = Array.from({ length: maxTick / step + 1 }, (_, i) => i * step);

    const totalTransacoes = listaConciliacao?.filter((i) => i.tipo == 'I').reduce((acc, i) => acc + parseFloat(i.total), 0);

    const [startOneDate, setOneStartDate] = useState(null); // Estado para armazenar a data selecionada
    const [loading, setLoading] = useState(false); // Estado de carregamento

    const handleDateChange = (date) => {
        setOneStartDate(date); // Atualiza a data selecionada

        if (date) {
            const formattedDate = date.toISOString().split('T')[0];
            setOneDay(formattedDate);
            setLoading(true);
            checkSteps('new', 1, formattedDate)
        }
    };

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDatesChanges = (dates) => {
        if (!dates) return;
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setMoreDays({
            start: start ? start.toISOString().split('T')[0] : null,
            end: end ? end.toISOString().split('T')[0] : null
        });

        let data = {
            start: start ? start.toISOString().split('T')[0] : null,
            end: end ? end.toISOString().split('T')[0] : null
        }

        if (start && end) {
            checkSteps('new', 2, data)
        }
    };

    const [tooltip, seTooltip] = useState(false)
    const handleClose = () => {
        seTooltip(false);
    };

    const handleOpen = () => {
        seTooltip(true);
    };

    const [calendarOne, setCalendarOne] = useState(false);
    const [calendarTwo, setCalendarTwo] = useState(false);
    const datepickerRefOne = useRef(null);
    const datepickerRefTwo = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (datepickerRefOne.current && !datepickerRefOne.current.contains(event.target)) {
                setCalendarOne(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (datepickerRefTwo.current && !datepickerRefTwo.current.contains(event.target)) {
                setCalendarTwo(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Grid sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: screenWidth <= 800 ? 'column' : 'row',
            paddingInline: screenWidth <= 800 ? '36px' : '90px',
            paddingTop: screenWidth <= 800 ? '20px' : '55px'
        }}>

            <Grid sx={{ maxWidth: '720px' }}>

                <Grid sx={{ paddingBottom: screenWidth <= 800 ? '20px' : '35px' }}>
                    <Grid sx={{
                        display: 'flex',
                        alignItems: screenWidth <= 800 ? 'start' : 'flex-start',
                        flexDirection: screenWidth <= 800 ? 'column' : 'row',
                        paddingBottom: '10px',
                        justifyContent: 'space-between'
                    }}>
                        <Typography sx={{
                            fontWeight: 700,
                            fontSize: '32px',
                            color: '#142A30',
                        }}>
                            Relatório Conciliação
                        </Typography>
                        <Tooltip
                            arrow
                            PopperProps={{
                                modifiers: [
                                    {
                                        name: 'preventOverflow',
                                        options: {
                                            boundary: 'window',
                                        },
                                    },
                                ],
                            }}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: '#181818', // Cor de fundo
                                        color: 'white', // Cor do texto
                                        fontSize: '14px', // Tamanho da fonte
                                        padding: '10px', // Espaçamento interno
                                        borderRadius: '8px', // Bordas arredondadas
                                    },
                                },
                                arrow: {
                                    sx: {
                                        width: '35px',
                                        height: '22px',
                                        color: '#181818',
                                    }
                                }
                            }}
                            open={tooltip}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            title={
                                <Grid sx={{
                                    gap: '10px',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: 700, bgcolor: '#181818', color: 'white', textAlign: 'start' }}>
                                        Explicação
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontSize: '12px', bgcolor: '#181818', color: 'white', textAlign: 'start' }}>
                                        As vendas feitas em finais de semana ou feriados serão conciliadas e
                                        estarão disponíveis no primeiro dia útil seguinte. Para visualizar,
                                        basta escolher a data no filtro "Data:".
                                    </Typography>
                                </Grid>
                            }>
                            <Grid sx={{ gap: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', bgcolor: '#f0efeb', padding: '5px 10px', border: '1px solid #9DE200', borderRadius: '20px' }}>
                                <img src={exclamacaoGreen} style={{ width: "10px", height: "10px" }} alt="Exclamação" />
                                <Typography sx={{ fontSize: '10px', color: '#7FA800' }}>
                                    A conciliação é feita somente em dias úteis
                                </Typography>
                            </Grid>
                        </Tooltip>
                    </Grid>
                    {console.log('moreDays', moreDays)}
                    <Typography sx={{
                        fontSize: '14px',
                        color: '#142A3099'
                    }}>
                        Este é o resumo da conciliação automática realizada pelo
                        <span style={{ fontWeight: 700 }}>
                            {' '}Xuxu do dia{' '}
                            {moreDays?.start ? format(new Date(`${moreDays.start}T00:00:00`), 'dd/MM/yyyy') : '...'}
                            {' '} a dia{' '}
                            {moreDays?.end ? format(new Date(`${moreDays.end}T00:00:00`), 'dd/MM/yyyy') : '...'}
                        </span> na empresa
                        <span style={{ fontWeight: 700 }}>
                            {' '} {estabelecimento?.estabelecimento} / CNPJ {estabelecimento?.cnpj}.
                        </span>
                    </Typography>
                </Grid>

                <Grid sx={{
                    gap: screenWidth <= 800 ? '10px' : '20px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: screenWidth <= 800 ? 'column' : 'row',
                    alignItems: screenWidth <= 800 ? 'start' : 'center',
                    paddingBottom: '35px'
                }}>

                    <Typography onClick={() => checkSteps('old', 0)}
                        sx={{
                            cursor: 'pointer',
                            fontSize: '13px',
                            color: '#142A3066'
                        }}>
                        Última conciliação {lastDay ? format(`${lastDay}T00:00:00`, 'dd/MM/yyyy') : '00/00/0000'}
                    </Typography>

                    {screenWidth <= 800 ? null : <Grid sx={{ backgroundColor: '#142A3066', width: '2px', height: '13px' }} />}

                    <Grid item sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}>
                        <Typography onClick={() => checkSteps('old', 1)} sx={{
                            cursor: 'pointer',
                            fontSize: '13px',
                            color: '#142A3066'
                        }}>
                            Data
                        </Typography>
                        <Grid sx={{
                            gap: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                        }}
                            ref={datepickerRefOne}
                        >
                            <DatePicker
                                wrapperClassName="datePicker"
                                selected={oneDay ? new Date(oneDay + 'T00:00:00') : startOneDate}
                                onChange={handleDateChange} // Modificado para receber uma única data
                                locale="pt"
                                dateFormat="dd/MM/yyyy"
                                customInput={
                                    <ReactInputMask
                                        mask="99/99/9999"
                                        value={oneDay ? format(`${oneDay}T00:00:00`, 'dd/MM/yyyy') : ''}
                                        onChange={(e) => setOneStartDate(new Date(e.target.value))}
                                        autoComplete="off"
                                    >
                                        {() => (
                                            <TextField
                                                onClick={() => setCalendarOne((prev) => !prev)}
                                                placeholder={oneDay ? format(`${oneDay}T00:00:00`, 'dd/MM/yyyy') : "00/00/0000"}
                                                autoComplete="off"
                                                sx={{
                                                    '& .MuiOutlinedInput-input': {
                                                        cursor: 'pointer',
                                                        fontSize: '13px',
                                                        color: '#142A3066',
                                                        padding: '1px 0px 0px 5px',
                                                        maxWidth: '84px'
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: 'none',
                                                        },
                                                        '&:hover fieldset': {
                                                            border: 'none',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: 'none',
                                                        },
                                                    },
                                                    '& .MuiInputBase-input::placeholder': {
                                                        color: '#142A3066',
                                                        opacity: 1,
                                                    },
                                                }}
                                            />
                                        )}
                                    </ReactInputMask>
                                }
                            />
                            <img
                                src={arrowBottom142A3066}
                                style={{
                                    width: "9px",
                                    height: "4px",
                                    transition: 'transform 0.2s ease',
                                    transform: calendarOne ? 'rotate(180deg)' : 'rotate(0deg)',
                                }} alt="Setas"
                            />
                        </Grid>
                    </Grid>

                    {screenWidth <= 800 ? null : <Grid sx={{ backgroundColor: '#142A3066', width: '2px', height: '13px' }} />}

                    <Grid item sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}>
                        <Typography onClick={() => checkSteps('old', 2)} sx={{
                            color: '#142A30',
                            fontSize: '13px',
                            cursor: 'pointer'
                        }}>
                            Período
                        </Typography>
                        <Grid item sx={{
                            gap: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                        }}
                            ref={datepickerRefTwo}
                        >
                            <DatePicker
                                wrapperClassName="datePicker"
                                onChange={handleDatesChanges}
                                startDate={moreDays?.start ? new Date(moreDays.start + 'T00:00:00') : startDate}
                                endDate={moreDays?.end ? new Date(moreDays.end + 'T00:00:00') : endDate}
                                selectsRange
                                locale="pt"
                                dateFormat="dd/MM/yyyy"
                                customInput={
                                    <ReactInputMask
                                        style={{ width: '33%' }}
                                        placeholder="Período"
                                        type="text"
                                        mask="99/99/9999 - 99/99/9999"
                                        value={
                                            moreDays.start && moreDays.end
                                                ? `${format(new Date(`${moreDays.start}T00:00:00`), 'dd/MM/yyyy')} - ${format(new Date(`${moreDays.end}T00:00:00`), 'dd/MM/yyyy')}`
                                                : moreDays.start
                                                    ? `${format(new Date(`${moreDays.start}T00:00:00`), 'dd/MM/yyyy')}`
                                                    : ''
                                        }
                                        autoComplete="off"
                                    >
                                        {() => (
                                            <TextField
                                                onClick={() => setCalendarTwo((prev) => !prev)}
                                                placeholder={
                                                    moreDays.start && moreDays.end
                                                        ? `${format(new Date(`${moreDays.start}T00:00:00`), 'dd/MM/yyyy')} - ${format(new Date(`${moreDays.end}T00:00:00`), 'dd/MM/yyyy')}`
                                                        : "00/00/0000 - 00/00/0000"
                                                }
                                                autoComplete="off"
                                                sx={{
                                                    '& .MuiOutlinedInput-input': {
                                                        cursor: 'pointer',
                                                        fontSize: '13px',
                                                        opacity: 1,
                                                        color: '#454a4b',
                                                        padding: '1px 0px 0px 5px',
                                                        maxWidth: '177px'
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: 'none',
                                                        },
                                                        '&:hover fieldset': {
                                                            border: 'none',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: 'none',
                                                        },
                                                        '& .MuiInputBase-input::placeholder': {
                                                            color: startDay ? '#142A3066' : moreDays ? '#454a4b' : '#142A3066',
                                                            opacity: 1,
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                    </ReactInputMask>
                                }
                            />
                            <img
                                src={arrowBottom142A3066}
                                style={{
                                    width: "9px",
                                    height: "4px",
                                    transition: 'transform 0.2s ease',
                                    transform: calendarTwo ? 'rotate(180deg)' : 'rotate(0deg)',
                                }} alt="Setas"
                            />
                        </Grid>
                    </Grid>

                </Grid>

                <Grid sx={{
                    gap: '20px',
                    display: 'flex',
                    flexDirection: screenWidth <= 800 ? 'column' : 'row',
                    paddingBottom: '35px'
                }}>

                    <Grid sx={{ gap: '20px', width: '100%', width: '100%', maxWidth: screenWidth <= 800 ? '100%' : '190px', display: 'flex', justifyContent: 'space-around', flexDirection: 'column', bgcolor: '#F0EFEB', borderRadius: '20px', padding: '15px' }}>
                        <Grid sx={{ gap: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={Plus142A30} style={{ width: "40px", height: "40px" }} alt="Setas" />
                            {/* <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#142A30' }}>15</Typography> */}
                            <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#142A30' }}>{incluidosGrupo.reduce((acc, item) => acc + Number(item.qtde), 0)}</Typography>
                        </Grid>
                        <Grid>
                            <Typography sx={{ color: '#142A3099', fontSize: '14px' }}>Transações incluídas</Typography>
                        </Grid>
                    </Grid>

                    <Grid sx={{ gap: '20px', width: '100%', width: '100%', maxWidth: screenWidth <= 800 ? '100%' : '300px', display: 'flex', justifyContent: 'space-around', flexDirection: 'column', bgcolor: '#F0EFEB', borderRadius: '20px', padding: '15px' }}>
                        <Grid sx={{ gap: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={gardenValue} style={{ width: "40px", height: "40px" }} alt="Setas" />
                            <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#142A30' }}>{real(totalTransacoes)}</Typography>
                        </Grid>
                        <Grid>
                            <Typography sx={{ color: '#142A3099', fontSize: '14px' }}>Diferença total corrigida</Typography>
                        </Grid>
                    </Grid>

                    <Grid sx={{ gap: '20px', width: '100%', width: '100%', maxWidth: screenWidth <= 800 ? '100%' : '190px', display: 'flex', justifyContent: 'space-around', flexDirection: 'column', bgcolor: '#F0EFEB', borderRadius: '20px', padding: '15px' }}>
                        <Grid sx={{ gap: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={Less142A30} style={{ width: "40px", height: "40px" }} alt="Setas" />
                            <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#142A30' }}>{excluidosGrupo?.reduce((acc, item) => acc + Number(item.qtde), 0)}</Typography>
                        </Grid>
                        <Grid>
                            <Typography sx={{ color: '#142A3099', fontSize: '14px' }}>Transações excluídas</Typography>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid sx={{ width: '100%', bgcolor: '#F0EFEB', borderRadius: '20px', marginBottom: '35px', padding: '20px' }}>

                    <Grid sx={{ gap: '10px', display: 'flex', flexDirection: 'column' }}>
                        <Grid sx={{ gap: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={arrowLeftRigth} style={{ width: "40px", height: "40px" }} alt="Setas" />
                            <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#142A30' }}>
                                Modificações feitas / dia
                            </Typography>
                        </Grid>
                        <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>
                            Gráfico que mostra todas as modificações feitas pelo Xuxu dia a dia.
                        </Typography>
                    </Grid>

                    <div style={{ height: '162px' }}>
                        <ResponsiveBar
                            data={resultado}
                            keys={['qtde']}
                            indexBy="date"
                            margin={{ top: 20, right: 0, bottom: 50, left: 20 }}
                            padding={0.3}
                            maxValue={maxTick}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={(bar) => bar.data['dateColor']}
                            borderColor="#E57373"
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 0,
                                tickPadding: 10,
                                tickRotation: -45,
                                legendPosition: 'middle',
                                legendOffset: 32,
                                truncateTickAt: 0
                            }}
                            axisLeft={{
                                tickSize: 0,
                                tickPadding: 0,
                                tickRotation: 0,
                                legendPosition: 'middle',
                                legendOffset: -40,
                                truncateTickAt: 0,
                                tickValues: tickValues
                            }}
                            gridYValues={tickValues}
                            enableGridX={true}
                            enableGridY={true}
                            enableLabel={false}
                            role="application"
                            ariaLabel="Gráfico de modificações por dia"
                        />
                    </div>
                </Grid>

                <Grid sx={{ height: '1px', bgcolor: '#5A8292', marginBottom: '35px' }} />

                <Grid sx={{ paddingBottom: '35px' }}>
                    <Grid sx={{ paddingBottom: '10px' }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#5A8292', paddingBottom: '10px' }}>Transações excluídas pelo Xuxu</Typography>
                        <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>❌ Essas foram todas as transações que o Xuxu excluiu no lançamento do caixa.</Typography>
                    </Grid>

                    <TabelaAgrupadoExcluidosIncluidos
                        tipe={'E'}
                        real={real}
                        rows={excluidosGrupo}
                    />

                </Grid>

                <Grid sx={{ paddingBottom: '35px' }}>
                    <Grid sx={{ paddingBottom: '10px' }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#5A8292', paddingBottom: '10px' }}>Transações incluídas pelo Xuxu</Typography>
                        <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>➕ Essas foram todas as transações que o Xuxu incluiu no lançamento do caixa.</Typography>
                    </Grid>

                    <TabelaAgrupadoExcluidosIncluidos
                        tipe={'I'}
                        real={real}
                        rows={incluidosGrupo}
                    />

                    <Grid sx={{ marginTop: '35px', display: 'flex', flexDirection: 'row', gap: '15px', bgcolor: '#9DE200', padding: '15px', borderRadius: '20px' }}>
                        <img src={xuxuFace} style={{ width: "34px", height: "34px" }} alt="Exclamação" />
                        {excluidosGrupo?.length > 0 || incluidosGrupo?.length > 0 ?
                            <Typography sx={{ fontSize: '14px' }}>
                                <strong>O Xuxu economizou para você {minutosTotais * 3} minutos preciosos
                                    do seu tempo.</strong> Aproveite essa oportunidade para focar no que realmente faz a diferença na sua
                                empresa. Por aqui, pode deixar com a gente! 😄
                            </Typography>
                            :
                            <Typography sx={{ fontSize: '14px' }}>
                                <strong>O Xuxu analisou que até o momento, tudo está correto em seu caixa, então você pode
                                    ficar tranquilo.</strong> Aproveite essa oportunidade para concentrar seus esforços nas áreas que realmente impactam o sucesso da sua empresa.
                                Aqui, nós cuidaremos de tudo para você! 😄
                            </Typography>
                        }
                    </Grid>

                </Grid>

            </Grid>

        </Grid >
    )
}

export default ConciliacaoGrupo;
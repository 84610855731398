import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';

import frame1 from '../../assets/gifs/loading/1.png';
import frame2 from '../../assets/gifs/loading/2.png';
import frame3 from '../../assets/gifs/loading/3.png';
import frame4 from '../../assets/gifs/loading/4.png';
import frame5 from '../../assets/gifs/loading/5.png';
import frame6 from '../../assets/gifs/loading/6.png';

const frames = [frame1, frame2, frame3, frame4, frame5, frame6];

const XulisLoading = ({ frameDuration = 1000, w, h }) => {
    const [currentFrame, setCurrentFrame] = useState(0);
    const [nextFrame, setNextFrame] = useState(1);

    // Animação de fade-in para o próximo frame
    const fadeIn = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        reset: true,
        config: { duration: 1000 },
    });

    // Animação de fade-out para o frame atual
    const fadeOut = useSpring({
        opacity: 0,
        from: { opacity: 1 },
        reset: true,
        config: { duration: 1200 },
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentFrame((prev) => (prev + 1) % frames.length); // Atualiza o frame
            setNextFrame((prev) => (prev + 1) % frames.length); // Atualiza o próximo frame
        }, frameDuration);

        return () => clearInterval(interval);
    }, [frameDuration]);

    return (
        <div style={{
            height: w ? w : '125px',
            width: h ? h : '100px',
            position: 'relative',
        }}>
            {/* Frame atual com fade-out */}
            <animated.img
                src={frames[currentFrame]}
                alt="Loading"
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    ...fadeOut, // Aplica fade-out na imagem atual
                }}
            />
            {/* Próximo frame com fade-in */}
            <animated.img
                src={frames[nextFrame]}
                alt="Next Frame"
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    ...fadeIn, // Aplica fade-in no próximo frame
                }}
            />
        </div>
    );
};

export default XulisLoading;

import axios from "axios";
import { useState, useEffect,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShoppingBag, faCircleDollarToSlot, faBookReader } from "@fortawesome/free-solid-svg-icons"

import Menu from "./componentes/menu";
import MenuMobile from "./componentes/menuMobile";

import RealizarVenda from './XLoja/RealizarVenda';
import {ApiContext} from "../services/config.js";

var vendaPaga = false;

const TelaXloja01Areaparceiros = () => {
  const navigate = useNavigate();
  const { firebase_endpoint } = useContext(ApiContext);

  // const [emailRef, setEmailRef] = useState(''); NÃO ESTAVA SENDO USADO
  const [value, setValue] = useState('');
  // const [urlfotoperfil, setFotoUrl] = useState(''); JÁ ESTA NO "dadosCLiente"
  const [cashbackConta, setCashback] = useState('0');
  const [cashbackplusvenda, setCashbackplusvenda] = useState('0');
  const [cashbackCliente, setCaschbackCliente] = useState('R$ 0,00');
  const [dadosClinte, setDadosCliente] = useState([]);
  const [access, setAccess] = useState(false);
  const [dadosX, setDadosX] = useState([]);
  // const [primeiroNome, setPrimeiroNome] = useState(''); JÁ ESTA NO "dadosCLiente"
  // const [nomeUser, setNome] = useState(''); Á ESTA NO "dadosCLiente"

  const [clickEnviou, setClickEnviou] = useState(false);
  // const [tokenEsta, setTokenEstabelecimento] = useState(''); NÃO ESTAVA SENDO USADO
  const [idvenda, setIdvenda] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  // const [urlLogo, setUrlLogo] = useState('');  NÃO ESTAVA SENDO USADO

  const [screenWidth, setScreenWidth] = useState(null);
  const [infoValore, setInfoValore] = useState(1);
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800); // Defina o limite de largura para o modo mobile conforme necessário
    verificarNiveisLogin(dadosX);
    setScreenWidth(window.innerWidth);

    if (window.innerWidth <= 1400 && menuOpen) {
      setMenuOpen(false)
    }
    if (window.innerWidth > 1400 && menuOpen) {
      setMenuOpen(true)
    }
  };

  useEffect(() => {
    setarHora();
    getDados();
  }, []);

  const Swal = require('sweetalert2')

  const setarHora = async () => {
    var getDa = await getData();
    document.getElementById("horas").innerHTML = getDa;
  }

  const getDados = async () => {
    var dados = await localStorage.getItem('login')

    if (dados == null) {
      navigate("/");

      return false;
    }

    dados = JSON.parse(dados)
    setDadosX(dados)

    // console.log('dadosX', dadosX)

    // setUrlLogo(dados.urllogo)
    verificarNiveisLogin(dados);

    // console.log('dadosX', dados)

    // setEmailRef(dados.email) - NÃO ESTA SENDO USADO
    // console.log(dados)

    // document.getElementById('nome_empresa').value = dados.empresa;

    if (dados.cashbackfixo == null) {
      dados.cashbackfixo = 0;
    }

    // setCashback(10)
    // console.log('dados', dados)
    var porcentagemxuliz = parseFloat(dados.cashbackfixo);
    if (porcentagemxuliz == '') {
      porcentagemxuliz = '0';
    }

    var porcentagemxulizPlus = parseFloat(dados?.cashbackplusvenda);

    setCashbackplusvenda(porcentagemxulizPlus)
    setCashback(porcentagemxuliz)

    // setCashback(porcentagemxulizPlus ? porcentagemxulizPlus : porcentagemxuliz)
    // document.getElementById('get_cashback').value = porcentagemxuliz + '%';
    // console.log('porcentagemxuliz---------------11', porcentagemxuliz)
    ///PEGAR CASHBACK
  }

  const verificarNiveisLogin = async () => {
    var idperfil = await localStorage.getItem('tipoPerfil');

    if (idperfil == "7") {
      //financeiro somente relatorios
      location.href = "./minhasvendas";
    }

    if (idperfil == "8") {
      await sleep(50)

      //financeiro somente relatorios
      //document.getElementById('realizar_vendaID').style.display = "none";

      //document.getElementById('vendas_hoje').style.display = "none";
      document.getElementById('historico_vendas').style.display = "none";
      document.getElementById('menu_marketing').style.display = "none";
      document.getElementById('menu_configuracoes').style.display = "none";

    }
  }

  const [userForm, setUserForm] = useState({
    chavecliente: "",
    chavetipo: "",
    codVenda: "",
  })

  async function identificarNumero(numero) {

    setUserForm({ ...userForm, chavecliente: numero, chavetipo: '' });

    const validaCPF = require('./globalFunctions/cpfValidator');
    const apenasDigitos = numero.replace(/\D/g, '');

    if (apenasDigitos.length < 11) {
      return;
    }
    const cpfValid = validaCPF(numero);

    if (cpfValid) {
      setUserForm({ ...userForm, chavecliente: apenasDigitos, chavetipo: 'cpf' });
      getUserData(apenasDigitos, 'cpf');
      return;
    }

    const telefone = /^[0-9]{10,11}$/.test(apenasDigitos);

    if (telefone) {
      setUserForm({ ...userForm, chavecliente: apenasDigitos, chavetipo: 'celular' });
      getUserData(apenasDigitos, 'celular');
    } else {
      // Formato desconhecido
    }
  }

  const getUserData = async (chavecliente, chavetipo) => {
    // Criação do objeto FormData
    const form = new FormData();
    form.append("metodo", "ConsultaUsuarioChave");
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("chavecliente", chavecliente);
    form.append("chavetipo", chavetipo);

    try {
      // Envio da requisição POST com FormData
      const response = await axios.post(`${firebase_endpoint}/painel/integracao/usuario.php`, form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      // Log da resposta para depuração
      // console.log('Resposta da API:', response.data);

      // Atualiza os dados conforme necessário
      setarDados(response.data);
    } catch (error) {
      // Log de erro para depuração
      console.error('Erro na requisição:', error);
    }
  };

  // ---------- ANTIGO ----------
  // const chaveG = async () => {
  //   // USAR FUNÇÕES DO REACT PARA PEGAR O VALOR DO CAMPO
  //   var chave = document.getElementById('chave_id').value;
  //   // CRIAR FUNÇÃO PARA IDENTIFICAR O CAMPO AUTOMATICAMENTE
  //   if (chave.length != 11) {
  //     document.getElementById("nome_cliente").value = '';
  //     return false;
  //   }

  //   // PRECISAMOS DISSO?
  //   var qual_chave = document.getElementById('qual_chave').value;

  //   // CRIAR OBJETO COM OS DADOS
  //   const form = new FormData();
  //   form.append("metodo", "ConsultaUsuarioChave");
  //   form.append("idempresa", "1350");
  //   form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
  //   form.append("chavecliente", "" + chave);
  //   form.append("chavetipo", qual_chave);

  //   const options = {
  //     method: 'POST',
  //   };

  //   options.body = form;

  //   // UTILIZAR O AXIOS
  //   fetch('https://xuliz.com.br/painel/integracao/usuario.php', options)
  //     .then(response => response.json())
  //     .then(response => setarDados(response))
  //     .catch(err => console.error(err));
  // }
  // ---------- ANTIGO ----------

  // DADOS - UTILIZAR UM OBJETO PARA O USUARIO
  const setarDados = async (dados) => {
    // console.log(dados)

    // SÉ ERROR FOR "N" = NÃO/NENHUM MENSAGEM DE SUCESSO E APRESENTA OS DADOS
    if (dados.erro != "N") {

      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: dados.mensagem,
        showConfirmButton: false,
        timer: 1000
      })

      return false;
    }

    setDadosCliente(dados);

    // console.log('dadosClinte', dadosClinte)

    // document.getElementById("blockUser").style.display = "flex";

    dadosClinte.telefone = dados.email;

    var nome = dados.nome;

    // setNome(nome) JÁ ESTA NO "dadosCLiente"
    // nome = nome.split(' ') JÁ ESTA NO "dadosCLiente"
    // setPrimeiroNome(nome[0]) JÁ ESTA NO "dadosCLiente"

    // document.getElementById("nome_cliente").value = dados.nome
    // document.getElementById("nome_cliente2").innerHTML = dados.nome
    // document.getElementById("chave_cliente").innerHTML = dados.cpf

    var urlFoto = dados.urlfotoperfil;

    if (urlFoto == '') {
      urlFoto = 'https://cdn-icons-png.flaticon.com/512/149/149071.png';
    }

    // setFotoUrl(urlFoto) NÃO ESTAVA SENDO USADO
  }

  const clickEnviar = async () => {

    // document.getElementById('msg_venda').style.display = "none";
    // document.getElementById('msg_venda_enviada').style.display = "flex";
    // document.getElementById('button_enviarar').style.display = 'none';

    if (clickEnviou == true) {
      // console.log('Trava click')
      return false;
    }

    setClickEnviou(true)

    //obter o token do establecimeeneto
    var getTokenEstabelecimneto = dadosX.tokenestabelecimento
    // setTokenEstabelecimento(dadosX.tokenestabelecimento)

    var valorXX = value;

    valorXX = valorXX.replace(/\D/g, '');
    valorXX = valorXX / 100;
    valorXX = '' + valorXX;
    valorXX = valorXX.replace('.', ',')

    // console.log(valorXX)

    // return false;

    // console.log(dadosClinte)
    // var chave = document.getElementById('chave_id').value;
    // var codigo_venda = document.getElementById('codigo_venda').value;
    // var qual_chave = document.getElementById('qual_chave').value;

    var chave = userForm.chavecliente;
    var qual_chave = userForm.chavetipo;
    var codigo_venda = userForm.codVenda;


    const form = new FormData();
    form.append("idempresa", "1350");
    form.append("idestabelecimento", dadosX.idestabelecimento);
    form.append("metodo", "VendaXLoja");
    form.append("token", getTokenEstabelecimneto);
    form.append("idusuariovenda", dadosX.idusuario);
    form.append("mensagem", "");
    form.append("idusuario", dadosClinte.idusuario);
    form.append("chave", chave);
    form.append("porcentagemcashback", access && cashbackplusvenda > '0' ? cashbackplusvenda : cashbackConta);
    form.append("tipo", qual_chave);
    form.append("valor", valorXX);
    form.append("codigovenda", codigo_venda);

    // Para visualizar o conteúdo do FormData
    // console.log('form', form)

    // const form = new FormData();
    // form.append("idempresa", "1350");
    // form.append("idestabelecimento", dadosX.idestabelecimento);
    // form.append("metodo", "VendaXLoja");
    // form.append("token", getTokenEstabelecimneto);
    // form.append("idusuariovenda", dadosX.idusuario);
    // form.append("mensagem", "");
    // form.append("idusuario", dadosClinte.idusuario);
    // form.append("chave", chave);
    // form.append("porcentagemcashback", cashbackConta);
    // form.append("tipo", qual_chave);
    // form.append("valor", valorXX);
    // form.append("codigovenda", codigo_venda);

    // console.log('form', form)

    const options = {
      method: 'POST',
    };

    options.body = form;

    fetch(`${firebase_endpoint}/painel/integracao/vendaxuliz.php?metodo=`, options)
      .then(response => response.json())
      .then(response => {

        setClickEnviou(false)

        // console.log(response)

        if (response.erro == "S") {


          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: response.mensagem,
            showConfirmButton: false,
            timer: 2000
          })

          // document.getElementById('msg_venda').style.display = "block";
          // document.getElementById('msg_venda_enviada').style.display = "none";
          // document.getElementById('button_enviarar').style.display = 'block';
          return false;
        }

        setIdvenda(response.idvendaxuliz)

        var idvendaxuliz = response.idvendaxuliz;

        aguardarPagamento(getTokenEstabelecimneto, idvendaxuliz)

        // Swal.fire(
        //   'Venda enviada',
        //   response.mensagem,
        //   'success'
        // )
      })
      .catch(err => {
        console.error(err);
        // document.getElementById('msg_venda').style.display = "block";
        // document.getElementById('msg_venda_enviada').style.display = "none";
        // document.getElementById('button_enviarar').style.display = 'block';
        setClickEnviou(false)

      });
  }

  const aguardarPagamento = async (getTokenEstabelecimneto, idvendaxuliz) => {

    setInfoValore(2);
    verificarPagamento(getTokenEstabelecimneto, idvendaxuliz)

  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const verificarPagamento = async (getTokenEstabelecimneto, idvendaxuliz) => {

    try {
      if (vendaPaga == false) {
        await sleep(1000);
        loadderPagamento(getTokenEstabelecimneto, idvendaxuliz)
        verificarPagamento(getTokenEstabelecimneto, idvendaxuliz);
      }
    } catch (error) {
      verificarPagamento(getTokenEstabelecimneto, idvendaxuliz);
    }

  }

  const getDataInicial = async () => {
    var data = new Date();
    data.setDate(data.getDate() - 7);

    var ano = data.getFullYear();
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var dia = String(data.getDate()).padStart(2, '0');

    return ano + '-' + mes + '-' + dia;
  }

  const getDataFinal = async () => {
    var data = new Date();

    var ano = data.getFullYear();
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var dia = String(data.getDate()).padStart(2, '0');

    return ano + '-' + mes + '-' + dia;
  }

  const loadderPagamento = async (getTokenEstabelecimneto, idvenda) => {


    const options = {
      method: 'POST',
      url: 'https://us-central1-restapi-4777e.cloudfunctions.net/app/retaguarda.php',
      headers: { 'Content-Type': 'application/json' },
      params: {
        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
        funcao: 'vendaconsulta',
        token_estabelecimento: getTokenEstabelecimneto,
        idvendaxuliz: idvenda,
        idempresa: dadosX.idempresa
      },
    };

    await axios.request(options).then(function (response) {
      // console.log(response.data);
      var dados = response.data;
      if (dados.erro != "N") {
        return false;
      }

      if (dados.idvendastatus == "2") {
        return pagamentoSalvo()

      }

    }).catch(function (error) {
      console.error(error);
    });





  }

  const pagamentoSalvo = async (lista) => {

    if (vendaPaga == true) {
      return false;
    }

    vendaPaga = true;
    setInfoValore(3);
    setTimeout(() => setInfoValore(1), 5000);
    // Swal.fire(
    //   'Venda concluída com sucesso!',
    //   '',
    //   'success'
    // ).then((res) => {
    //   location.href = './minhasvendas'
    // })

    // location.href = './ordem?id='+lista.idvenda


  }

  const getData = async () => {
    var today = new Date();
    var day = today.getDate() + "";
    var month = (today.getMonth() + 1) + "";
    var year = today.getFullYear() + "";
    var hour = today.getHours() + "";
    var minutes = today.getMinutes() + "";

    day = checkZero(day);
    month = checkZero(month);
    year = checkZero(year);
    hour = checkZero(hour);
    minutes = checkZero(minutes);

    var data = day + "/" + month + "/" + year + " " + '-' + " " + hour + ":" + minutes;

    return data;
    function checkZero(data) {
      if (data.length == 1) {
        data = "0" + data;
      }
      return data;
    }
  }

  const formatCurrency = (inputValue) => {
    const rawValue = inputValue.replace(/\D/g, '');

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(rawValue / 100);
  };

  const handleChange = (event) => {
    setValue(formatCurrency(event.target.value));

    var rawValue2 = event.target.value.replace(/\D/g, '');

    var valor2 = parseInt(rawValue2);
    valor2 = rawValue2 / 100;

    const formatter2 = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    var total_venda = formatter2.format(rawValue2 / 100);

    // document.getElementById('total_venda').innerHTML = total_venda; // Talvez precise descomentar e adaptar
    // console.log('total_venda', total_venda)
    // console.log('valor2', valor2)


    //calucalo cashback

    var getCas = parseInt(access && cashbackplusvenda > '0' ? cashbackplusvenda : cashbackConta);
    getCas = getCas / 100;
    getCas = getCas * valor2;

    // document.getElementById()
    // console.log('getCas',getCas)
    var formatadoCash = formatter2.format(getCas);
    // console.log('formatadoCash',formatadoCash)

    setCaschbackCliente(formatadoCash)


    //quando receber
    var quandoReceber = valor2 - getCas;
    var formatadoCashForm = formatter2.format(quandoReceber);

    // document.getElementById('quandoReceber').innerHTML = formatadoCashForm; // Talvez precise descomentar e adaptar


  };


  // ---------------------------------

  useEffect(() => {
    // setAccess

    if (dadosX?.idempresa && dadosClinte?.parceiros) {
      const empresaLogada = dadosX?.idempresa;
      const parceiros = dadosClinte?.parceiros;

      // Verifica se a empresa logada está entre os parceiros
      const isParceiro = parceiros.some(parceiro => parceiro.idempresa === empresaLogada);

      if (isParceiro) {
        setAccess(true)
        console.log('A empresa logada está entre os parceiros do usuário.');
      } else {
        setAccess(false)
        console.log('A empresa logada NÃO está entre os parceiros do usuário.');
      }
    }

  }, [dadosClinte])



  if (isMobile == true) {

    return (
      <>
        <MenuMobile page="xloja" />

        <div style={{ height: 150, width: '100%', flexDirection: 'row', display: 'flex', padding: 10 }}>

          <div style={{ width: '100%', backgroundColor: '#142A30', display: 'flex', flex: 1, margin: 5, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} onClick={() => { location.href = './xloja' }}>
            {/* <img src={xloja} style={{ width: "auto", height: 30, filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(90deg)" }} /> */}
            <FontAwesomeIcon icon={faCircleDollarToSlot} style={{ fontSize: 30, color: "#FFFFFF" }} />
            <span style={{ textAlign: 'center', fontSize: 14, marginTop: 10, color: "#FFFFFF" }} id="realizar_vendaID">
              XLoja
            </span>
          </div>

          <div style={{ width: '100%', backgroundColor: '#F1F1F1', display: 'flex', flex: 1, margin: 5, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} id="vendas_hoje" onClick={() => { location.href = './vendashoje' }}>
            <FontAwesomeIcon icon={faShoppingBag} style={{ fontSize: 30, color: "#262626" }} />
            <span style={{ textAlign: 'center', fontSize: 14, marginTop: 10 }} >Vendas de hoje</span>
          </div>

          <div style={{ width: '100%', backgroundColor: '#F1F1F1', display: 'flex', flex: 1, margin: 5, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} id="historico_vendas" onClick={() => { location.href = './minhasvendas' }}>
            <FontAwesomeIcon icon={faBookReader} style={{ fontSize: 30, color: "#262626" }} />
            <span style={{ textAlign: 'center', fontSize: 14, marginTop: 10 }}>Histórico de vendas </span>
          </div>

        </div>

        <RealizarVenda
          screenWidth={screenWidth}
          dadosClinte={dadosClinte}
          access={access}
          value={value}
          cashbackConta={cashbackConta}
          cashbackCliente={cashbackCliente}
          cashbackplusvenda={cashbackplusvenda}
          handleChange={handleChange}
          identificarNumero={identificarNumero}
          setUserForm={setUserForm}
          userForm={userForm}
          clickEnviar={clickEnviar}
          clickEnviou={clickEnviou}
          isMobile={isMobile}
          infoValore={infoValore}
          setInfoValore={setInfoValore}
        />
      </>
    )
  }

  if (isMobile == false) {

    return (
      <>
        <div style={{ maxWidth: '1920px', height: '100vh', display: "flex" }}>
          <div style={{ backgroundColor: '#262626' }}>
            <Menu page="xloja" menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          </div>
          <div style={{ overflow: 'auto' }}>
            <RealizarVenda
              menuOpen={menuOpen}
              screenWidth={screenWidth}
              dadosClinte={dadosClinte}
              access={access}
              value={value}
              cashbackConta={cashbackConta}
              cashbackCliente={cashbackCliente}
              cashbackplusvenda={cashbackplusvenda}
              handleChange={handleChange}
              identificarNumero={identificarNumero}
              setUserForm={setUserForm}
              userForm={userForm}
              clickEnviar={clickEnviar}
              clickEnviou={clickEnviou}
              isMobile={isMobile}
              infoValore={infoValore}
              setInfoValore={setInfoValore}
            />
          </div>
        </div>
      </>
    )
  };
};

export default TelaXloja01Areaparceiros;
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import bag from "../assets/icons/bag.png"
import bagOn from "../assets/icons/bagOn.png"
import bagOff from "../assets/icons/bagOff.png"
import marketing from "../assets/icons/marketing.png"
import marketingOn from "../assets/icons/funnelOn.png"
import marketingOff from "../assets/icons/funnelOff.png"
import dolar from "../assets/icons/dolar.png"
import dolarOn from "../assets/icons/dolarOn.png"
import dolarOff from "../assets/icons/dolarOff.png"
import report from "../assets/icons/report.png"
import reportOn from "../assets/icons/reportOn.png"
import reportOff from "../assets/icons/reportOff.png"
import arrow from "../assets/icons/arrowRigthWhite.png"

import sair from "../assets/icons/exit.png"
import configuracoes from "../assets/icons/config.png"
import logo_parceiros from '../assets/logos/logo.parceiros.png';
import logo_xulis from '../assets/logos/logo.xulis.name.png';
import estabelecimentoIcon from '../assets/icons/estabelecimento.png';
import doubleArrow from '../assets/icons/doubleArrow.png';
import closeIcon from '../assets/icons/closeIcon.png';
import logo from '../assets/logos/logo.png';
import MenuOpen from "./MenuOpen";
import MenuClose from "./MenuClose";
import { Button, Grid, Menu, MenuItem, Modal, OutlinedInput, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import Stylesheet from "reactjs-stylesheet";
import axios from "axios";

import { faChevronDown, faUser } from "@fortawesome/free-solid-svg-icons";
import UserGroup from '../assets/icons/userGroup.png';
import GearMenu from '../assets/icons/gearMenu.png';
import CloseMenu from '../assets/icons/closeMenu.png';
import arrowLeftGray from "../assets/icons/arrowLeftGray.png";

const MenuMobile = ({ page, menuOpen, setMenuOpen }) => {
    const navigate = useNavigate();
    const Swal = require('sweetalert2')

    const [pageRef, setPageRef] = useState(page);
    const [nome, setNome] = useState('');
    const [usuario, setUsuario] = useState('');
    const [urlLogo, setUrlLogo] = useState('');
    const [estabelecimento, setEstabelecimento] = useState({})
    const [estabelecimentos, setEstabelecimentos] = useState([])
    const [modal, setModal] = useState(false)
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [filtro, setFiltro] = useState(''); // Estado para o valor da pesquisa
    const [resultadosFiltrados, setResultadosFiltrados] = useState([]);

    // Função para lidar com a entrada do campo de pesquisa
    const handleSearch = (e) => {
        const valor = e.target.value || ''; // Garante que seja uma string
        setFiltro(valor);
    };

    // Atualiza a lista de resultados filtrados sempre que `filtro` mudar
    useEffect(() => {
        // Verifica se há uma correspondência exata
        let resultados = estabelecimentos.filter(
            (estabelecimento) =>
                estabelecimento.idestabelecimento.toString().includes(filtro) || // Comparação exata com o id
                estabelecimento.estabelecimento?.toLowerCase() === filtro.toLowerCase() || // Nome exato
                estabelecimento.cnpj === filtro // CNPJ exato
        );

        // Se não encontrar nada, faz a busca parcial
        if (resultados.length === 0) {
            resultados = estabelecimentos.filter(
                (estabelecimento) =>
                    estabelecimento.idestabelecimento.toString().includes(filtro) || // Busca parcial no id
                    estabelecimento.estabelecimento?.toLowerCase().includes(filtro.toLowerCase()) || // Nome parcial
                    estabelecimento.cnpj.includes(filtro) // CNPJ parcial
            );
        }

        // Ordena os resultados de forma crescente por `idestabelecimento`
        resultados.sort((a, b) => a.idestabelecimento - b.idestabelecimento);

        setResultadosFiltrados(resultados);
    }, [filtro, estabelecimentos]);


    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop + clientHeight >= scrollHeight) {
            setIsAtBottom(true)
        } else {
            setIsAtBottom(false)
        }
    };

    const onGroupContainer13Click = useCallback(() => {
        localStorage.clear()
        navigate("/");
    }, [navigate]);

    useEffect(() => {
        getDados();
    }, []);

    const getDados = async () => {
        var dados = await localStorage.getItem('login')

        if (dados == null) {
            return false;
        }

        dados = JSON.parse(dados)
        var dadosX = dados;

        setNome(dadosX.nome)
        setUsuario(dadosX.empresa)
        setUrlLogo(dadosX.urllogo)
        // Não esta sendo mais usado
        // verificarCDL(dadosX)
        getTokenEstabelciment(dadosX.idestabelecimento);
    }

    // Não esta sendo mais usado
    // const verificarCDL = (dadosX) => {
    //     var idparceiro = dadosX.parceiros;

    //     if (idparceiro == null) {
    //         document.getElementById('menu_marketing').style.display = 'none'
    //         return false;
    //     }

    //     if (idparceiro.length == 0) {
    //         document.getElementById('menu_marketing').style.display = 'none'
    //         return false;
    //     }

    //     if (idparceiro[0] == null) {
    //         document.getElementById('menu_marketing').style.display = 'none'
    //         return false;
    //     }
    // }

    const irPage = (page) => {
        location.href = './' + page
    }

    const getTokenEstabelciment = async (id) => {

        fetch('https://us-central1-restapi-4777e.cloudfunctions.net/app/lista_estabelecimento', {
            method: 'POST',
            headers: {
                'consumerkey': '64df72043ca4b',
                'consumersecret': '64df72043ca4f',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                idestabelecimento: '',
                token: '',
                idempresagrupo: ''
            })
        })
            .then(response => response.json())
            .then(response => {
                searchEnterprise(response.retorno)
                const mesmoGrupo = response.retorno.filter(item => item.idempresagrupo === dados.idempresa);
                setEstabelecimentos(mesmoGrupo)
            })
            .catch(err => console.error(err));
    }

    let dados = JSON.parse(localStorage.getItem('login'));

    const changeEnterprise = (i) => {
        dados.idestabelecimento = i.idestabelecimento;
        dados.tokenestabelecimento = i.token;
        dados.idempresa = i.idempresagrupo;
        dados.complemento = i.estabelecimento;
        dados.empresa = i.complemento;
        dados.urllogo = i.urlfoto;
        dados.nome = i.razaosocial;
        localStorage.setItem('login', JSON.stringify(dados));
        window.location.reload();
    }

    const searchEnterprise = (data) => {
        const hasMatch = data.find(item => item.idestabelecimento === dados.idestabelecimento);
        setEstabelecimento(hasMatch);
    }

    const Estabelecimentos = () => {
        return (
            <Grid sx={{ width: '100%' }}>
                {estabelecimento.cnpj ?
                    <>
                        <Grid sx={{
                            gap: '12px',
                            display: 'flex',
                            flexDirection: 'column',
                            bgcolor: '#2F4650',
                            padding: menuOpen ? '19px 20px' : '19px 12.5px',
                            borderTopLeftRadius: '15px',
                            borderTopRightRadius: '15px'
                        }}>
                            <Grid sx={{
                                width: '30px',
                                height: '30px',
                                display: 'flex',
                                bgcolor: '#0D2128',
                                borderRadius: '11px',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <img src={estabelecimentoIcon} style={{ width: 14, height: 14 }} />
                            </Grid>
                            {menuOpen ? <Grid>
                                <Typography sx={{ color: '#FFFFFF', fontSize: '10px' }}>{estabelecimento.estabelecimento}</Typography>
                                <Typography sx={{ color: '#7998A3', fontSize: '10px' }}>{estabelecimento.cnpj}</Typography>
                            </Grid> : null}
                        </Grid>
                        <Grid sx={{
                            padding: '5px 14px',
                            border: '1px solid #2F4650',
                            borderBottomLeftRadius: '15px',
                            borderBottomRightRadius: '15px'
                        }}>
                            <Button
                                onClick={() => setModal(true)}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    textTransform: 'none',
                                    justifyContent: 'center',
                                    gap: menuOpen ? '9px' : '0px',
                                    minWidth: menuOpen ? '64px' : '0px'
                                }}>
                                <img src={doubleArrow} style={{ width: 10, height: 10 }} />
                                {menuOpen ? <Grid>
                                    <Typography sx={{ color: '#FFFFFF', fontSize: '10px' }}>Trocar de estabelecimento</Typography>
                                </Grid> : null}
                            </Button>
                        </Grid>
                    </> : null}
                {/* Modal para trocar de estabelecimento */}
                <Modal open={modal} onClose={() => { setModal(false); setIsAtBottom(false); }}>
                    <Grid
                        sx={{
                            width: '462px',
                            top: '50%',
                            left: '50%',
                            boxShadow: 24,
                            border: 'none',
                            bgcolor: 'red',
                            borderRadius: '20px',
                            position: 'absolute',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        <Grid sx={{
                            bgcolor: '#142A30',
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px'
                        }}>
                            <Grid sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '35px 30px 0px 30px'
                            }}>
                                <img src={logo_xulis} style={{ width: 95, height: 'auto' }} />
                                <img onClick={() => { setModal(false); setIsAtBottom(false); }} src={closeIcon} style={{ width: 25, height: 25, cursor: 'pointer' }} />
                            </Grid>
                            <Grid sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                padding: '40px 30px 35px 30px'
                            }}>
                                <Typography sx={{ color: '#FFFFFF', fontSize: '20px', fontWeight: 500 }}>{nome}</Typography>
                                <Grid sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                    <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontWeight: 100 }}>
                                        <span style={{ fontWeight: 500 }}>Você está em:</span> {estabelecimento.estabelecimento}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid sx={{
                            bgcolor: '#FFFFFF',
                            borderBottomLeftRadius: '20px',
                            borderBottomRightRadius: '20px',
                        }}>
                            <Grid sx={{ width: '100%', display: 'flex', justifyContent: 'center', bgcolor: '#142A3066', padding: '13px 30px' }}>
                                <Grid sx={styles.gridSearch}>
                                    <OutlinedInput
                                        sx={{
                                            ...styles.customInputCod,
                                            '& .MuiInputBase-input': {
                                                color: '#142A30'
                                            },
                                            '& .MuiInputBase-input::placeholder': {
                                                opacity: 1,
                                                fontSize: '15px',
                                                color: '#142A3099',
                                            },
                                            width: '100%',
                                            maxWidth: '480px'
                                        }}
                                        placeholder="Pesquisar unidade"
                                        value={filtro}
                                        onChange={handleSearch}
                                    />
                                    <FontAwesomeIcon icon={faSearch} style={styles.iconSearch} />
                                </Grid>
                            </Grid>

                            <Grid
                                onScroll={handleScroll}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    height: 280,
                                    overflowY: 'auto',
                                    direction: 'rtl', // Mantém a barra de rolagem à esquerda
                                    '&::-webkit-scrollbar': {
                                        width: '20px', // Largura da barra de rolagem
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        height: '42px',
                                        backgroundColor: '#17333A', // Cor da barra
                                        borderBottomLeftRadius: isAtBottom ? '20px' : '0px',
                                        border: '1px solid trasparent', // Espaço para adicionar efeito de "padding"
                                        backgroundClip: 'content-box', // Para manter o border transparente
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        backgroundColor: '#F0EFEB', // Cor do track (fundo da barra)
                                        borderBottomLeftRadius: '20px',
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                        backgroundColor: '#17333A', // Cor ao passar o mouse sobre a barra
                                    },
                                }}
                            >
                                <Grid sx={{
                                    width: '100%',
                                    direction: 'ltr',
                                    paddingTop: '20px'
                                }}>
                                    {(resultadosFiltrados.length > 0 ? resultadosFiltrados : estabelecimentos).map((i, index) => (
                                        <Grid
                                            onClick={() => changeEnterprise(i)}
                                            sx={{
                                                height: '80px'
                                            }}>
                                            <Grid sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                bgcolor: '#FFFFFF',
                                                margin: '0px 30px 0px 0px',
                                                cursor: 'pointer',
                                                '&:active': {
                                                    backgroundColor: '#F0EFEB'
                                                },
                                            }}>
                                                <Grid>
                                                    <Typography sx={{ marginLeft: '35px', fontSize: '14px', color: '#142A30', fontWeight: 700 }}>
                                                        {i.idestabelecimento}. {i.estabelecimento}
                                                    </Typography>
                                                    <Typography sx={{ marginLeft: '35px', fontSize: '12px', color: '#142A3099' }}>
                                                        {i.cnpj}
                                                    </Typography>
                                                </Grid>
                                                <FontAwesomeIcon
                                                    icon={faChevronRight}
                                                    style={{ color: '#142A30', width: '10px', height: '15px' }}
                                                />
                                            </Grid>
                                            <Grid sx={{ marginInline: '20px', width: '90%', paddingTop: '15px', borderBottom: '1px solid #142A3099' }} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal>
            </Grid>
        );
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const menuItens = [
        { icon: UserGroup, title: 'Contas', page: 'configuracoes', subTitle: 'Gerencia acessos ao Xulis Parceiros' },
        { icon: GearMenu, title: 'Configurações', page: 'configuracoes', subTitle: 'Acesse todas as configurações disponíveis' },
    ]

    const formatarNome = (nome) => {
        return nome
            .toLowerCase()
            .split(" ")
            .map((palavra) => palavra.charAt(0).toUpperCase() + palavra.slice(1))
            .join(" ");
    };

    const menuOptions = () => {
        return (
            <>
                {estabelecimento.cnpj ? <>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        sx={{ textTransform: 'none', padding: 0, width: '100%', minWidth: '54px' }}
                    >
                        <Grid sx={{
                            width: menuOpen ? '100%' : '54px',
                            height: '52px',
                            bgcolor: anchorEl ? '#5A8292' : '#2F4650',
                            borderRadius: '15px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: menuOpen ? 'space-between' : 'center',
                            padding: '6px'
                        }}>
                            <Grid sx={{
                                gap: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                {estabelecimento?.urlfoto ?
                                    <img src={estabelecimento?.urlfoto} style={{ width: 36, height: 36, borderRadius: 20, margin: '2px' }} />
                                    :
                                    <Grid sx={{
                                        margin: '2px',
                                        width: '36px',
                                        height: '36px',
                                        display: 'flex',
                                        borderRadius: '40px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        border: '7px solid #FFFFFF'
                                    }}>
                                        <FontAwesomeIcon icon={faUser} style={{ fontSize: 15, color: "#FFFFFF", backgroundColor: '#17333A', border: '5px solid #17333A', borderRadius: '20px', }} />
                                    </Grid>
                                }

                                {menuOpen && estabelecimento?.estabelecimento ? <Grid>
                                    <Typography sx={{ textAlign: 'start', fontSize: '12px', fontWeight: 600, color: '#FFFFFF' }}>
                                        {formatarNome(estabelecimento?.complemento).length > 15
                                            ? `${formatarNome(estabelecimento.complemento).slice(0, 15)}...`
                                            : formatarNome(estabelecimento?.complemento)}
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', fontSize: '12px', color: '#FFFFFF' }}>
                                        {formatarNome(estabelecimento?.estabelecimento).length > 15
                                            ? `${formatarNome(estabelecimento.estabelecimento).slice(0, 15)}...`
                                            : formatarNome(estabelecimento?.estabelecimento)}
                                    </Typography>
                                </Grid> : null}
                            </Grid>

                            {menuOpen ? <Grid sx={{
                                width: '22px',
                                height: '22px',
                                display: 'flex',
                                marginRight: '4px',
                                alignItems: 'center',
                                borderRadius: '40px',
                                justifyContent: 'center',
                                backgroundColor: '#0D2128',
                                border: '1px solid #FFFFFF',
                                transform: anchorEl ? 'rotate(0deg)' : 'rotate(180deg)'
                            }}>
                                <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: 8, color: "#FFFFFF", backgroundColor: '#0D2128', border: '5px solid #0D2128', borderRadius: '20px' }} />
                            </Grid> : null}

                        </Grid>
                    </Button >

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        sx={{
                            marginTop: '-80px', // Posiciona o menu acima do botão
                            '& .MuiMenu-paper': {
                                borderRadius: '15px',
                                transform: 'translateY(-80px)', // Ajusta a posição para cima
                            },
                            '& .MuiMenu-list': {
                                padding: '0px',

                            }
                        }}
                        PopperProps={{
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -80], // Ajusta a distância entre o botão e o menu
                                    },
                                },
                            ],
                        }}
                    >
                        <Grid sx={{ width: '206px' }}>

                            <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 700, padding: '15px 20px' }}>
                                Mais opções
                            </Typography>

                            <Grid sx={{ opacity: '0.7', borderTopStyle: 'solid', borderTopWidth: '1px', borderTopColor: '#142A3099' }} />

                            <Grid sx={{ padding: '10px 20px 10px 20px' }}>
                                {menuItens.map((i) => (
                                    <Grid onClick={() => irPage(i.page)} sx={{ height: '60px', gap: '10px', display: 'flex', cursor: 'pointer' }}>
                                        <img src={i.icon} style={{ width: '30px', height: '30px' }} />
                                        <Grid>
                                            <Typography sx={{ fontSize: '12px', color: '#142A30', fontWeight: 600 }}>{i.title}</Typography>
                                            <Typography sx={{ fontSize: '10px', color: '#142A3099' }}> {i.subTitle}</Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>

                            <Grid sx={{ padding: '0px 20px 5px 20px' }}>
                                <Grid onClick={onGroupContainer13Click}
                                    sx={{
                                        gap: '10px',
                                        display: 'flex',
                                        cursor: 'pointer',
                                        alignItems: 'center',
                                        paddingBottom: '10px',
                                        justifyContent: 'flex-start',
                                    }}>
                                    <img src={CloseMenu} style={{ width: '30px', height: '30px' }} />
                                    <Grid>
                                        <Typography sx={{ textAlign: 'start', fontSize: '14px', fontWeight: 600, color: '#9E4848' }}>Sair</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Menu>
                </> : null}
            </>
        )
    }

    return (
        <>
            {menuOpen ?
                <MenuOpen
                    setMenuOpen={setMenuOpen}
                    arrowLeftGray={arrowLeftGray}
                    logo_parceiros={logo_parceiros}
                    bag={bag}
                    bagOn={bagOn}
                    bagOff={bagOff}
                    marketing={marketing}
                    marketingOn={marketingOn}
                    marketingOff={marketingOff}
                    dolar={dolar}
                    dolarOn={dolarOn}
                    dolarOff={dolarOff}
                    report={report}
                    reportOn={reportOn}
                    reportOff={reportOff}
                    sair={sair}
                    configuracoes={configuracoes}
                    arrow={arrow}
                    pageRef={pageRef}
                    nome={nome}
                    usuario={usuario}
                    urlLogo={urlLogo}
                    irPage={irPage}
                    onGroupContainer13Click={onGroupContainer13Click}
                    Estabelecimentos={Estabelecimentos}
                    menuOptions={menuOptions}
                />
                :
                <MenuClose
                    setMenuOpen={setMenuOpen}
                    arrowLeftGray={arrowLeftGray}
                    logo={logo}
                    bag={bag}
                    bagOn={bagOn}
                    bagOff={bagOff}
                    marketing={marketing}
                    marketingOn={marketingOn}
                    marketingOff={marketingOff}
                    dolar={dolar}
                    dolarOn={dolarOn}
                    dolarOff={dolarOff}
                    report={report}
                    reportOn={reportOn}
                    reportOff={reportOff}
                    sair={sair}
                    configuracoes={configuracoes}
                    arrow={arrow}
                    pageRef={pageRef}
                    usuario={usuario}
                    urlLogo={urlLogo}
                    irPage={irPage}
                    onGroupContainer13Click={onGroupContainer13Click}
                    Estabelecimentos={Estabelecimentos}
                    menuOptions={menuOptions}
                />
            }
        </>
    )
}

export default MenuMobile;

const styles = Stylesheet.create({
    customInputCod: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
    gridHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '28px',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    },
    gridArrow: {
        gap: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    boxArrow: {
        width: '34px',
        display: 'flex',
        bgcolor: '#142A30',
        borderRadius: '10px',
        justifyContent: 'center'
    },
    arrow: {
        padding: '7px',
        color: "#FFFFFF"
    },
    gridSearch: {
        width: '100%',
        height: '35px',
        display: 'flex',
        justifyContent: 'space-between',
        bgcolor: '#FEFDF9',
        padding: '5px 10px',
        borderRadius: '20px',
        flexDirection: 'row',
        alignItems: 'center',
        border: '2px solid #142A3099'
    },
    iconSearch: {
        width: '15px',
        height: '15px',
        color: "#142A3099"
    }
});
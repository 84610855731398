import { Grid, Typography } from "@mui/material";
import { useState } from "react";

const MenuOpen = ({
    setMenuOpen,
    arrowLeftGray,
    logo_parceiros,
    bag,
    bagOff,
    marketing,
    marketingOff,
    dolar,
    dolarOff,
    report,
    reportOff,
    sair,
    configuracoes,
    arrow,
    pageRef,
    emailRef,
    usuario,
    urlLogo,
    irPage,
    onGroupContainer13Click,
    Estabelecimentos,
    menuOptions
}) => {

    const menu = [
        { name: 'XLoja', page: 'xloja', iconOn: bag, iconOff: bagOff },
        { name: 'Marketing', page: 'marketing', iconOn: marketing, iconOff: marketingOff },
        { name: 'Recebíveis', page: 'recebiveis', iconOn: dolar, iconOff: dolarOff },
        { name: 'Relatórios', page: 'conciliacao', iconOn: report, iconOff: reportOff, subMenu: [{ name: 'Conciliação', page: 'conciliacao' }] },
    ]

    const subMenu = menu.find((i) => i.page == pageRef)

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{
                width: 260,
                backgroundColor: '#142A30',
                display: 'flex',
                flexDirection: 'column',
                padding: '20px 25px 27px 25px',
                height: '100vh'
            }}>

                <div style={{
                    display: "flex",
                    width: '100%',
                    paddingTop: '12px',
                    position: 'relative',
                    alignItems: 'center',
                    margin: '0px 14px',
                    gap: '52.82px'
                }}>

                    <div>
                        <img src={logo_parceiros} style={{ width: 150, height: 'auto', zIndex: 2 }} />
                    </div>

                    <div style={{
                        width: '18px',
                        height: '22px',
                        display: 'flex',
                        cursor: 'pointer',
                        justifyContent: 'flex-end',
                        backgroundColor: '#5A8292',
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',
                    }} onClick={() => setMenuOpen(false)}>
                        <img
                            src={arrow}
                            style={{
                                width: 4,
                                height: 8,
                                margin: '7px',
                                color: '#F0EFEB',
                                transform: 'scaleX(-1)'
                            }}
                        />
                    </div>

                </div>

                <div style={{
                    flex: 5,
                    width: '100%',
                    marginTop: 60,
                    display: "flex",
                    flexDirection: 'column',
                }}>
                    {menu.map((i) => {
                        return (
                            <div style={{
                                display: "flex",
                                cursor: "pointer",
                                flexDirection: 'row',
                                alignItems: 'center',
                                borderRadius: '20px',
                                backgroundColor: pageRef == i.page ? '#0D2128' : null,
                            }} onClick={() => irPage(i.page)}>
                                <div key={i.page} style={{
                                    width: '30px',
                                    height: '30px',
                                    display: "flex",
                                    cursor: "pointer",
                                    margin: '9px 14px',
                                    alignItems: 'center',
                                    borderRadius: '11px',
                                    justifyContent: 'center',
                                    backgroundColor: pageRef == i.page ? '#9DE200' : '#0D2128'
                                }}>
                                    <img
                                        src={pageRef == i.page ? i.iconOn : i.iconOff}
                                        style={{
                                            width: 14,
                                            height: 14,
                                        }}
                                    />
                                </div>
                                <text style={{
                                    fontSize: '12px',
                                    fontWeight: pageRef == i.page ? '600' : null,
                                    color: pageRef == i.page ? "#9DE200" : '#5A8292'
                                }}>
                                    {i.name}
                                </text>
                            </div>
                        );
                    })}

                </div>

                <div style={{ display: "flex", width: '100%', paddingBottom: '15px' }}>
                    {Estabelecimentos()}
                </div>

                <div style={{ display: "flex", width: '100%' }}>
                    {menuOptions()}
                </div>
            </div>

            {subMenu.subMenu?.length > 0 && subMenu.page == pageRef ? (<div style={{
                // zIndex: 2,
                left: '100px',
                width: '260px',
                height: '100vh',
                // position: 'absolute',
                backgroundColor: '#F0EFEB',
            }}>
                {subMenu.subMenu.map((i) => (
                    <Grid sx={{ padding: '45px 25px 25px 25px' }}>
                        <Grid sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            bgcolor: '#142A301A',
                            borderRadius: '20px',
                            alignItems: 'center',
                            padding: '9.5px 10px',
                            justifyContent: 'space-between'
                        }}>
                            <Typography sx={{ fontSize: '12px', color: '#142A3099' }}>{i.name}</Typography>
                            <img src={arrowLeftGray} style={{ width: "15px", height: "15px" }} alt="Seta" />
                        </Grid>
                    </Grid>))}
            </div>) : null}
        </div>
    )
}

export default MenuOpen;
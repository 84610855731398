import React from "react";
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import exclamacao142A3099 from '../../../assets/icons/exclamacao142A3099.png';

const Tabela = ({ real, rows }) => {

    function calcDif(incluido, excluido) {
        const diferenca = parseFloat(incluido) - parseFloat(excluido);
        return Number(diferenca.toFixed(2));
    }

    return (
        <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>
            {rows.length > 0 ?
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, borderSpacing: '0 2px', borderCollapse: 'separate' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, paddingLeft: 0, paddingBottom: '15px' }} align="left">Tipo transação</TableCell>
                                <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, paddingBottom: '15px' }} align="center">Transações Xulis</TableCell>
                                <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, paddingBottom: '15px' }} align="center">ERP (Sistema)</TableCell>
                                <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, paddingBottom: '15px' }} align="center">Diferença</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row?.bandeira}>
                                    <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, padding: '10px 5px 10px 0px' }} align="left">
                                        {row?.bandeira &&
                                            (row.bandeira === row.bandeira.toUpperCase()
                                                ? row.bandeira.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
                                                : row.bandeira)}
                                    </TableCell>
                                    <TableCell sx={{ fontSize: '14px', color: '#142A3099', border: 0, padding: '5px' }} align="center">{real(row?.totalretaguarda)}</TableCell>
                                    <TableCell sx={{ border: 0, padding: '5px', display: 'flex', justifyContent: 'center' }}>
                                        <Grid sx={{ width: '70%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography sx={{ fontSize: '14px', color: '#142A3099', }}>{real(row?.totalerp)}</Typography>
                                            <Grid sx={{
                                                width: '15px',
                                                height: '15px',
                                                borderRadius: '5px',
                                                bgcolor: parseFloat(calcDif(row?.totalretaguarda, row?.totalerp)) != 0 ? '#F09292' : '#BCDF4B',
                                                border: parseFloat(calcDif(row?.totalretaguarda, row?.totalerp)) != 0 ? '1px solid #9E4848' : '1px solid #7FA800'
                                            }} />
                                        </Grid>
                                    </TableCell>
                                    {/* Quando totalerp > totalretaguarda = Positivo / totalerp < totalretaguarda = Negativo */}
                                    <TableCell
                                        sx={{
                                            fontSize: '14px',
                                            color: '#142A3099',
                                            border: 0,
                                            padding: '5px',
                                            bgcolor: parseFloat(calcDif(row?.totalretaguarda, row?.totalerp)) < 0
                                                ? '#F0929280'
                                                : parseFloat(calcDif(row?.totalretaguarda, row?.totalerp)) > 0
                                                    ? '#FFC58180'
                                                    : ''
                                        }} align="center">
                                        {calcDif(row?.totalretaguarda, row?.totalerp) < 0
                                            ? `${real(calcDif(row?.totalretaguarda, row?.totalerp))}`
                                            : calcDif(row?.totalretaguarda, row?.totalerp) > 0
                                                ? `+ ${real(calcDif(row?.totalretaguarda, row?.totalerp))}`
                                                : `R$ 0.00`}

                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, paddingLeft: 0, paddingBottom: '15px' }} align="left">
                                    Total
                                </TableCell>
                                <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, paddingBottom: '15px' }} align="center">
                                    {real(rows.reduce((acc, row) => acc + parseFloat(row?.totalretaguarda || 0), 0))}
                                </TableCell>
                                <TableCell sx={{ border: 0, padding: '16px 5px 0px 5px', display: 'flex', justifyContent: 'center' }}>
                                    <Grid sx={{ width: '70%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0 }}>
                                            {real(rows.reduce((acc, row) => acc + parseFloat(row?.totalerp || 0), 0))}
                                        </Typography>
                                        <Grid sx={{ width: '15px', height: '15px' }} />
                                    </Grid>
                                </TableCell>
                                <TableCell sx={{ fontSize: '15px', color: '#142A3099', fontWeight: 700, border: 0, paddingBottom: '15px' }} align="center">
                                    {real(rows.reduce((acc, row) => acc + calcDif(row?.totalretaguarda, row?.totalerp), 0))}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                (
                    <Grid sx={{
                        gap: '25px',
                        display: 'flex',
                        borderRadius: '20px',
                        flexDirection: 'row',
                        bgcolor: '#F0EFEB59',
                        padding: '15px 25px',
                        alignItems: 'center'
                    }}>
                        <img src={exclamacao142A3099} style={{ width: "35px", height: "35px" }} alt="Exclamação" />
                        <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>
                            Não foram encontrados lançamentos ou dados disponíveis. Como resultado, até o momento nenhuma transação foi feita do sistema.
                        </Typography>
                    </Grid>
                )}
        </Paper >
    );
};

export default Tabela;
import { useCallback,useContext } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./componentes/menu";
import MenuMobile from "./componentes/menuMobile";

import { useEffect } from "react";
import xloja_12 from './assets/xloja_12.png'; // Tell webpack this JS file uses this image
import waiting from './assets/waiting.jpg';

import xloja from './assets/xloja.png'; // Tell webpack this JS file uses this image


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faShoppingBag, faChartPie, faBox, faCircleDollarToSlot, faBullhorn, faQuestionCircle, faUsers, faGear, faSquareXmark, faMobile, faBars, faBookReader } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";
import axios from "axios";
import logo_parceiros from './assets/grupo677.png'; // Tell webpack this JS file uses this image
import grupo12 from './assets/grupo12.png';
import retangulo473 from './assets/retangulo473.png';

import grupo13 from './assets/grupo13.png';
import Marketing from "./Marketing/Marketing";
import {ApiContext} from "../services/config.js";


var vendaPaga = false;

const MarketingJS = () => {
  const navigate = useNavigate();

  const [emailRef, setEmailRef] = useState('');
  const { firebase_endpoint } = useContext(ApiContext);

  const [value, setValue] = useState('');
  const [urlfotoperfil, setFotoUrl] = useState('');
  const [cashbackConta, setCashback] = useState('3');
  const [cashbackCliente, setCaschbackCliente] = useState('R$ 0,00');
  const [dadosClinte, setDadosCliente] = useState([]);
  const [dadosX, setDadosX] = useState([]);
  const [primeiroNome, setPrimeiroNome] = useState('');
  const [nomeUser, setNome] = useState('');

  const [clickEnviou, setClickEnviou] = useState(false);
  const [tokenEsta, setTokenEstabelecimento] = useState('');
  const [idvenda, setIdvenda] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [urlLogo, setUrlLogo] = useState('');
  const [cashbackPlus, setCashbackPlusVenda] = useState('0');
  const [screenWidth, setScreenWidth] = useState(null);
  const [menuOpen, setMenuOpen] = useState(true);
  const [membros, setMembros] = useState(0);
  const [subMembros, setSubMembros] = useState(0);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);



  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800);
    setScreenWidth(window.innerWidth);

    if (window.innerWidth <= 1400 && menuOpen) {
      setMenuOpen(false)
    }
    if (window.innerWidth > 1400 && menuOpen) {
      setMenuOpen(true)
    }
  };

  useEffect(() => {

    getDados()
  }, []);

  const Swal = require('sweetalert2')

  const pegarCashbackPlus = async (dados) => {
    var idempresa = dados.idempresa;
    var token = 'e84d7ad2-f0cc-11eb-b838-568a76eb275e';
    var idestabelecimentoLogin = dados.idestabelecimento

    const form = new FormData();
    form.append("metodo", "EstabelecimentoCashbackPlus");
    form.append("idempresa", idempresa);
    form.append("token", token);


    const options = {
      method: 'POST',
    };

    options.body = form;

    fetch(`${firebase_endpoint}/painel/integracao/estabelecimento.php`, options)
      .then(response => response.json())
      .then(response => {

        // console.log('EstabelecimentoCashbackPlus', response)

        var dadosEstabelecimentos = response.estabelecimentos;

        for (var x = 0; x < dadosEstabelecimentos.length; x++) {
          var idestabelecimento = dadosEstabelecimentos[x].idestabelecimento;

          if (idestabelecimento == idestabelecimentoLogin) {
            setCashbackPlusVenda(dadosEstabelecimentos[x].cashbackplusvenda)
            break;
          }

        }

      })
      .catch(err => console.error(err))

  }


  const pegarMembros = async (dados) => {

    var token_estabelecimento = dados.tokenestabelecimento;
    var idestabelecimento = dados.idestabelecimento;
    var idparceiro = dados.parceiros;

    // console.log('idparceiro', idparceiro)

    if (idparceiro == null) {
      return false;
    }

    if (idparceiro.length == 0) {
      return false;
    }

    if (idparceiro[0] == null) {
      return false;
    }

    idparceiro = idparceiro[0].idparceiro

    const options = {
      method: 'POST',
      url: 'https://us-central1-restapi-4777e.cloudfunctions.net/app/membrosListar.php',
      headers: { 'Content-Type': 'application/json' },
      params: {
        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
        funcao: 'membrosListar',
        token_estabelecimento: token_estabelecimento,
        idestabelecimento: idestabelecimento,
        idparceiro: idparceiro
      },
    };

    await axios.request(options).then(function (response) {
      // console.log('membrosListar', response.data);
      var dados = response.data;
      if (dados.erro != "N") {
        return false;
      }

      var membros = dados.retorno;

      if (membros != null && membros.length > 0) {
        setMembros(membros)
        // setMembros(membros.length)
        // document.getElementById('membros_id').innerText = "" + membros.length;
      }

      calcularSubmembros(membros)



    }).catch(function (error) {
      console.error(error);
      alert("Encontramos um problema tente mais tarde")
    });

  }

  const calcularSubmembros = async (membros) => {
    var dependentesTOTAL = 0;

    for (var x = 0; x < membros.length; x++) {

      if (membros[x].dependentes && membros[x].dependentes.length && membros[x].dependentes.length > 0) {
        var total = membros[x].dependentes.length;
        dependentesTOTAL = dependentesTOTAL + total;
      }


    }

    setSubMembros(dependentesTOTAL)
    // document.getElementById('total_submembros').innerText = "" + dependentesTOTAL;

  }

  const alterarCash = async () => {

    var token = dadosX.tokenestabelecimento;
    var idestabelecimentoLogin = dadosX.idestabelecimento;
    // var cash = document.getElementById('valor_cash').value;
    var cash = cashbackPlus;

    const options = {
      method: 'POST',
      url: 'https://us-central1-restapi-4777e.cloudfunctions.net/app/alterarCashback.php',
      headers: { 'Content-Type': 'application/json' },
      params: {
        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
        funcao: 'alterarCashback',
        token_estabelecimento: token,
        idestabelecimento: idestabelecimentoLogin,
        cashbackplusvenda: cash,
        idusuario: dadosX.idusuario
      },
    };

    await axios.request(options).then(function (response) {
      // console.log('alterarCashback', response.data);

      if (response.data.erro != "N") {
        alert("Encontramos um problema tente mais tarde!!")
        return false;
      }

      Swal.fire(
        'Sucesso!',
        '' + response.data.mensagem,
        'success'
      ).then(() => {
        location.reload()
      })


    }).catch(function (error) {
      console.error(error);
      alert("Encontramos um problema tente mais tarde")
    });
  }

  const getDados = async () => {
    var dados = await localStorage.getItem('login')

    if (dados == null) {
      navigate("/");

      return false;
    }
    dados = JSON.parse(dados)
    setDadosX(dados)

    setUrlLogo(dados.urllogo)

    //  console.log('dadosX',dados)

    setEmailRef(dados.email)
    //  console.log(dados)
    pegarMembros(dados)
    pegarCashbackPlus(dados)





  }


  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  if (isMobile == true) {
    return (
      <>
        <MenuMobile page="marketing" />
        <Marketing
          isMobile={isMobile}
          screenWidth={screenWidth}
          membros={membros}
          subMembros={subMembros}
          cashbackPlus={cashbackPlus}
          setCashbackPlusVenda={setCashbackPlusVenda}
          alterarCash={alterarCash}
        />

        {/* <div style={{ height: '100vh', display: "flex", width: "100%" }}>
          <div style={{ display: "flex", width: "100%", margin: 40, flexDirection: 'column', height: 420 }}>

            <p style={{ fontSize: 20, fontWeight: 'bold', fontFamily: 'Montserrat', marginLeft: 20, marginTop: 30 }}>Recursos de marketing</p>
            <p style={{ fontSize: 16, fontFamily: 'Montserrat', marginLeft: 20 }}>Use esses recursos para sua empresa alcançar um público amplo.</p>

            <div style={{ height: 400, width: '100%', display: 'flex', flexDirection: 'column' }}>

              <div style={{ flex: 2, backgroundColor: '#f1f1f1', display: 'flex', borderRadius: 20, margin: 10 }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 20 }}>

                  <img src={grupo13} style={{ width: '100%', height: 'auto', borderRadius: 10 }} />
                  <p style={{ fontSize: 15, fontWeight: 'bold', fontFamily: 'Montserrat', marginTop: 20 }}>Minha empresa é CDL</p>
                  <p style={{ fontSize: 12, fontFamily: 'Montserrat', color: '#514d4d' }}>Cadastre membros+ e submembros+ para que eles possam ganhar cashback+ em todas as lojas parceiras CDL e também tenham um selo exclusivo no app</p>


                  <div style={{ width: 150, height: 30, backgroundColor: '#262626', borderRadius: 10, justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: "pointer" }} onClick={() => { location.href = './cadastrar_membros' }}>
                    <span style={{ fontSize: 12, color: '#f1e2c4', fontFamily: 'Montserrat' }}>Cadastrar Membro+</span>
                  </div>

                </div>


              </div>

              <div style={{ flex: 1.2, backgroundColor: '#f1f1f1', display: 'flex', margin: 10, borderRadius: 20, padding: 20, flexDirection: 'column', justifyContent: 'center' }}>
                <span style={{ fontWeight: 'bold', fontFamily: 'Montserrat', fontSize: 15 }}>Ver todos os Membros+</span>
                <div style={{ backgroundColor: '#262626', height: 1, width: '100%', marginTop: 10 }}></div>
                <span style={{ fontFamily: 'Montserrat', fontSize: 12, marginTop: 10 }}>Gerencie os seus Membros+ como também os Submembros+ clicando abaixo</span>

                <div style={{ width: 100, height: 30, backgroundColor: '#262626', borderRadius: 10, justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 20, cursor: "pointer" }} onClick={() => { location.href = './membros' }}>
                  <span style={{ fontSize: 12, color: '#f1e2c4', fontFamily: 'Montserrat' }}>Ver agora</span>
                </div>
              </div>




              <div style={{ flex: 1, display: 'flex', flexDirection: 'column', maxHeight: 200 }}>
                <div style={{ flex: 2, backgroundColor: '#f1f1f1', display: 'flex', borderRadius: 20, margin: 10, borderTopRightRadius: 10 }}>




                  <div style={{ flex: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 20, padding: 20 }}>

                    <img src={retangulo473} style={{ width: '100%', height: 'auto', borderRadius: 10 }} />



                    <p style={{ fontSize: 13, fontWeight: 'bold', fontFamily: 'Montserrat', marginTop: 20 }}>Configurar Cashback+ para os parceiros CDL</p>
                    <p style={{ fontSize: 12, fontFamily: 'Montserrat', color: '#514d4d' }}>Para fazer parte da comunidade CDL & Xuliz é necessario que sua empresa disponibilize cashback+ para os usuários permitidos.</p>

                    <div style={{ flexDirection: 'row', display: 'flex' }}>

                      <input
                        placeholder={cashbackPlus}
                        id="valor_cash"
                        style={{ borderWidth: 0, width: 50, alignItems: 'center', borderRadius: 5 }}
                      >

                      </input>
                      <span style={{ alignSelf: 'center', marginRight: 15 }}>%</span>
                      <div style={{ width: 90, height: 30, backgroundColor: '#262626', borderRadius: 10, justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer' }} onClick={() => { alterarCash() }}>
                        <span style={{ fontSize: 12, color: '#f1e2c4', fontFamily: 'Montserrat' }}>Alterar</span>
                      </div>
                    </div>


                  </div>
                </div>



                <div style={{ flex: 1, backgroundColor: '#f1f1f1', display: 'flex', borderRadius: 20, flexDirection: 'column', justifyContent: 'center', flexDirection: 'row' }}>
                  <div style={{ display: 'flex', backgroundColor: '#f1f1f1', flex: 1, margin: 7, borderRadius: 10 }}>
                    <div style={{ flex: 1, marginLeft: 10, alignSelf: 'center' }}>
                      <span style={{ fontSize: 11, color: '#262626', fontFamily: 'Montserrat' }}>Membro+ Cadastrados</span>
                    </div>

                    <div style={{ flex: 2, backgroundColor: '#f1f1f1', justifyContent: 'center', display: 'flex', borderRadius: 10 }}>
                      <div style={{ backgroundColor: '#424242', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 10, borderRadius: 15, marginTop: 30, marginBottom: 30 }}>
                        <span style={{ fontSize: 25, color: '#f1e2c4' }} id="membros_id">0</span>
                      </div>
                    </div>
                  </div>

                  <div style={{ display: 'flex', backgroundColor: '#f1f1f1', flex: 1, margin: 7, borderRadius: 10 }}>
                    <div style={{ flex: 1, marginLeft: 10, alignSelf: 'center' }}>
                      <span style={{ fontSize: 11, color: '#262626', fontFamily: 'Montserrat' }} >Submembros+ cadastrados</span>
                    </div>

                    <div style={{ flex: 2, backgroundColor: '#f1f1f1', justifyContent: 'center', display: 'flex', borderRadius: 10 }}>
                      <div style={{ backgroundColor: '#424242', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 10, borderRadius: 15, marginTop: 30, marginBottom: 30 }}>
                        <span style={{ fontSize: 25, color: '#f1e2c4' }} id="total_submembros">0</span>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>


          </div>
        </div> */}


      </>
    )
  }

  return (
    <>
      <div style={{ maxWidth: '1920px', height: '100vh', display: "flex" }}>
        <div style={{ backgroundColor: '#262626' }}>
          <Menu page="marketing" menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </div>
        <div style={{ overflow: 'auto', width: '100%' }}>
          <Marketing
            isMobile={isMobile}
            screenWidth={screenWidth}
            membros={membros}
            subMembros={subMembros}
            cashbackPlus={cashbackPlus}
            setCashbackPlusVenda={setCashbackPlusVenda}
            alterarCash={alterarCash}
          />
        </div>
      </div>

      {/* <div style={{ height: '100vh', display: "flex" }}>
        <div style={{ backgroundColor: '#262626' }}>
          <Menu page="marketing" />
        </div>
        <div style={{ height: '100vh', display: "flex", width: "100%" }}>
          <div style={{ display: "flex", width: 850, margin: 40, flexDirection: 'column', height: 420 }}>

            <p style={{ fontSize: 20, fontWeight: 'bold', fontFamily: 'Montserrat', marginLeft: 20, marginTop: 30 }}>Recursos de marketing</p>
            <p style={{ fontSize: 16, fontFamily: 'Montserrat', marginLeft: 20 }}>Use esses recursos para sua empresa alcançar um público amplo.</p>

            <div style={{ height: 400, width: '100%', display: 'flex' }}>
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <div style={{ flex: 2, backgroundColor: '#F0EFEB', display: 'flex', borderRadius: 20, margin: 10 }}>
                  <div style={{ flex: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column', paddingLeft: 20, paddingRight: 10 }}>
                    <p style={{ fontSize: 15, fontWeight: 'bold', fontFamily: 'Montserrat' }}>Minha empresa é CDL</p>
                    <p style={{ fontSize: 12, fontFamily: 'Montserrat', color: '#514d4d' }}>Cadastre membros+ e submembros+ para que eles possam ganhar cashback+ em todas as lojas parceiras CDL e também tenham um selo exclusivo no app</p>


                    <div style={{ width: 150, height: 30, backgroundColor: '#142A30', borderRadius: 10, justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: "pointer" }} onClick={() => { location.href = './cadastrar_membros' }}>
                      <span style={{ fontSize: 12, color: '#FFFFFF', fontFamily: 'Montserrat' }}>Cadastrar Membro+</span>
                    </div>

                  </div>

                  <div style={{ flex: 1, display: 'flex', backgroundColor: '#f1f1f1', borderTopRightRadius: 10, borderBottomRightRadius: 10 }}>
                    <img src={grupo12} style={{ width: '100%', height: 'auto', borderTopRightRadius: 10, borderBottomRightRadius: 10 }} />
                  </div>
                </div>

                <div style={{ flex: 1.2, backgroundColor: '#f1f1f1', display: 'flex', margin: 10, borderRadius: 20, padding: 20, flexDirection: 'column', justifyContent: 'center' }}>
                  <span style={{ fontWeight: 'bold', fontSize: 15 }}>Ver todos os Membros+</span>
                  <div style={{ backgroundColor: '#262626', height: 1, width: '100%', marginTop: 10 }}></div>
                  <span style={{ fontSize: 12, marginTop: 10 }}>Gerencie os seus Membros+ como também os Submembros+ clicando abaixo</span>

                  <div style={{ width: 100, height: 30, backgroundColor: '#142A30', borderRadius: 10, justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 20, cursor: "pointer" }} onClick={() => { location.href = './membros' }}>
                    <span style={{ fontSize: 12, color: '#FFFFFF' }}>Ver agora</span>
                  </div>
                </div>
              </div>





              <div style={{ flex: 1, display: 'flex', flexDirection: 'column', maxHeight: 200 }}>
                <div style={{ flex: 2, backgroundColor: '#f1f1f1', display: 'flex', borderRadius: 20, margin: 10, borderTopRightRadius: 10 }}>


                  <div style={{ flex: 1.2, display: 'flex', backgroundColor: '#f1f1f1', borderTopRightRadius: 10, borderBottomRightRadius: 10 }}>
                    <img src={retangulo473} style={{ width: '100%', height: 'auto', borderTopRightRadius: 10, borderBottomRightRadius: 10, minWidth: 100, borderRadius: 10 }} />
                  </div>

                  <div style={{ flex: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column', paddingLeft: 20, paddingRight: 10 }}>
                    <p style={{ fontSize: 13, fontWeight: 'bold', fontFamily: 'Montserrat' }}>Configurar Cashback+ para os parceiros CDL</p>
                    <p style={{ fontSize: 12, fontFamily: 'Montserrat', color: '#514d4d' }}>Para fazer parte da comunidade CDL & Xuliz é necessario que sua empresa disponibilize cashback+ para os usuários permitidos.</p>

                    <div style={{ flexDirection: 'row', display: 'flex' }}>

                      {console.log('cashbackPlus', cashbackPlus)}
                      <input
                        placeholder={cashbackPlus}
                        id="valor_cash"
                        style={{ borderWidth: 0, width: 50, alignItems: 'center', borderRadius: 5 }}
                      >

                      </input>
                      <span style={{ alignSelf: 'center', marginRight: 15 }}>%</span>
                      <div style={{ width: 90, height: 30, backgroundColor: '#142A30', borderRadius: 10, justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer' }}
                        onClick={() => { alterarCash() }}>
                        <span style={{ fontSize: 12, color: '#FFFFFF', fontFamily: 'Montserrat' }}>Alterar</span>
                      </div>
                    </div>


                  </div>
                </div>



                <div style={{ flex: 1, backgroundColor: '#f1f1f1', display: 'flex', borderRadius: 20, flexDirection: 'column', justifyContent: 'center', flexDirection: 'row' }}>
                  <div style={{ display: 'flex', backgroundColor: '#f1f1f1', flex: 1, margin: 7, borderRadius: 10 }}>
                    <div style={{ flex: 1, marginLeft: 10, alignSelf: 'center' }}>
                      <span style={{ fontSize: 12, color: '#262626' }}>Membro+ Cadastrados</span>
                    </div>

                    <div style={{ flex: 2, backgroundColor: '#f1f1f1', justifyContent: 'center', display: 'flex', borderRadius: 10 }}>
                      <div style={{ backgroundColor: '#142A30', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 10, borderRadius: 15, marginTop: 30, marginBottom: 30 }}>
                        <span style={{ fontSize: 25, color: '#FFFFFF' }} id="membros_id">0</span>
                      </div>
                    </div>
                  </div>

                  <div style={{ display: 'flex', backgroundColor: '#f1f1f1', flex: 1, margin: 7, borderRadius: 10 }}>
                    <div style={{ flex: 1, marginLeft: 10, alignSelf: 'center' }}>
                      <span style={{ fontSize: 12, color: '#262626' }} >Submembros+ cadastrados</span>
                    </div>

                    <div style={{ flex: 2, backgroundColor: '#f1f1f1', justifyContent: 'center', display: 'flex', borderRadius: 10 }}>
                      <div style={{ backgroundColor: '#142A30', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 10, borderRadius: 15, marginTop: 30, marginBottom: 30 }}>
                        <span style={{ fontSize: 25, color: '#FFFFFF' }} id="total_submembros">0</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

    </>
  )
};

export default MarketingJS;

import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const [firebase_endpoint, setEndpoint] = useState("https://xuliz.com.br");
  const [firebase_endpoint_sistemas, setEndpointSistemas] = useState("https://www.xulizsistemas.com.br");

  // Função para buscar dados da API
  const fetchData = async () => {
    try {
      const ambiente = "xuliz"; // para teste use "xulizdev"
      const url = `https://us-central1-restapi-4777e.cloudfunctions.net/app/pegarAmbiente?plataforma=xulizparceiros&ambiente=${ambiente}`;
      
      const { data: resposta } = await axios.post(url);

      let api_url = localStorage.getItem("@endpoint_api");

      if (api_url !== null && api_url !== "") {
        setEndpoint(resposta.url);
      }

      if (resposta && resposta.erro === "N") {
        if (!resposta.url) {
          return;
        }
        localStorage.setItem("@endpoint_api", resposta.url);
        setEndpoint(resposta.url);
      }
    } catch (err) {
      console.error("Erro ao buscar dados da API:", err);
    }
  };

  // Função para buscar dados da API
  const fetchDataSistemas = async () => {
    try {
      const ambiente = "xulizsistemas"; // para teste use "xulizdev"
      const url = `https://us-central1-restapi-4777e.cloudfunctions.net/app/pegarAmbiente?plataforma=xulizparceiros&ambiente=${ambiente}`;
      
      const { data: resposta } = await axios.post(url);

      let api_url = localStorage.getItem("@endpoint_api_sistemas");

      if (api_url !== null && api_url !== "") {
        setEndpoint(resposta.url);
      }

      if (resposta && resposta.erro === "N") {
        if (!resposta.url) {
          return;
        }
        localStorage.setItem("@endpoint_api_sistemas", resposta.url);
        setEndpointSistemas(resposta.url);
      }
    } catch (err) {
      console.error("Erro ao buscar dados da API:", err);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataSistemas();
  }, []);

  return (
    <ApiContext.Provider value={{ firebase_endpoint,firebase_endpoint_sistemas }}>
      {children}
    </ApiContext.Provider>
  );
};

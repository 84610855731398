import { useEffect, useState,useContext } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import Menu from "./componentes/menu";
import CadastrarMembros from "./CadastrarMembros/CadastrarMembros";
import Swal from "sweetalert2";
import MenuMobile from "./componentes/menuMobile";

import {ApiContext} from "../services/config.js";


const CadastrarMembrosJS = () => {
  const navigate = useNavigate();
  const { firebase_endpoint } = useContext(ApiContext);


  const [dadosX, setDadosX] = useState([]);
  const [menuOpen, setMenuOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [screenWidth, setScreenWidth] = useState(null);
  const [membro, setMembro] = useState({
    celular: '',
    relacaoEmpresa: '',
    nome: '',
    situacao: ''
  })
  const [subMembro, setSubMembro] = useState({
    celular: '',
    relacaoMembro: '',
    nome: '',
    situacao: ''
  })
  const [subMembrosList, setSubmembrosList] = useState([]);
  const [relacaoEmpresa, setRelacaoEmpresa] = useState([])
  const [relacaoMembro, setRelacaoMembro] = useState([])

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800);
    setScreenWidth(window.innerWidth);

    if (window.innerWidth <= 1400 && menuOpen) {
      setMenuOpen(false)
    }
    if (window.innerWidth > 1400 && menuOpen) {
      setMenuOpen(true)
    }
  };

  useEffect(() => {
    pegarRelacaoEmpresa()
    pegarRelacaoMembro()
    getDados()
  }, []);

  const getDados = async () => {
    var dados = await localStorage.getItem('login')
    if (dados == null) {
      navigate("/");
      return false;
    }
    dados = JSON.parse(dados)
    setDadosX(dados)
    verificaParams(dados)
  }

  const verificaParams = (dados) => {
    function getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    var ids = getParameterByName('id');
    var sub = getParameterByName('sub');

    buscarMembro(dados, ids)
  }

  const pegarRelacaoEmpresa = async () => {

    const options = {
      method: 'POST',
      url: 'https://us-central1-restapi-4777e.cloudfunctions.net/app/listartipo.php',
      headers: { 'Content-Type': 'application/json' },
      params: {
        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
        funcao: 'listartipo',
        tipo: 'MEMBRO',
      },
    };

    await axios.request(options).then(function (response) {
      var arra = [{
        id: 0,
        iddependentetipo: '0',
        dependentetipo: 'Nenhuma opção disponível',
      }]

      for (var x = 0; x < response.data.retorno.length; x++) {
        arra.push({
          id: response.data.retorno[x].iddependentetipo,
          iddependentetipo: response.data.retorno[x].iddependentetipo,
          dependentetipo: response.data.retorno[x].dependentetipo,
        })
      }

      setRelacaoEmpresa(arra)
    }).catch(function (error) {
      // console.error(error);
      alert("Encontramos um problema tente mais tarde")
    });
  }

  const pegarRelacaoMembro = async () => {

    const options = {
      method: 'POST',
      url: 'https://us-central1-restapi-4777e.cloudfunctions.net/app/listartipo.php',
      headers: { 'Content-Type': 'application/json' },
      params: {
        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
        funcao: 'listartipo',
        tipo: 'SUBMEMBRO',
      },
    };

    await axios.request(options).then(function (response) {
      var arra = [{
        id: 0,
        iddependentetipo: '0',
        dependentetipo: 'Relação com membro+',
      }]

      for (var x = 0; x < response.data.retorno.length; x++) {
        arra.push({
          id: response.data.retorno[x].iddependentetipo,
          iddependentetipo: response.data.retorno[x].iddependentetipo,
          dependentetipo: response.data.retorno[x].dependentetipo,
        })
      }

      setRelacaoMembro(arra)
    }).catch(function (error) {
      console.error(error);
      alert("Encontramos um problema tente mais tarde")
    });
  }

  useEffect(() => {
    getMembro();
    setSubmembrosList([]);
  }, [membro.celular])

  const getMembro = async () => {

    const chave = membro.celular.replace(/[^0-9]/g, '');

    if (chave.length != 11) {
      setMembro({
        relacaoEmpresa: '0',
        nome: '',
        situacao: ''
      })
      return false;
    }

    const form = new FormData();
    form.append("metodo", "ConsultaUsuarioChave");
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("chavecliente", "" + chave);
    form.append("chavetipo", "celular");

    const options = {
      method: 'POST',
    };

    options.body = form;

    fetch(`${firebase_endpoint}/painel/integracao/usuario.php`, options)
      .then(response => response.json())
      .then(response => {
        if (response.erro == 'S') {
          setMembro((prevState) => ({
            ...prevState,
            ...response,
            relacaoEmpresa: '',
            nome: '',
            situacao: '',
          }))
        } else {
          setMembro({
            relacaoEmpresa: '',
            nome: ''
          })
          setMembro((prevState) => ({ ...prevState, ...response }))
          buscarMembro(dadosX, response.idusuario)
        }
      })
      .catch(err => console.error(err));
  }

  const buscarMembro = async (dados, id) => {

    var token_estabelecimento = dados.tokenestabelecimento;
    var idestabelecimento = dados.idestabelecimento;
    var idparceiro = dados.parceiros;

    if (idparceiro == null) {
      return false;
    }

    if (idparceiro.length == 0) {
      return false;
    }

    if (idparceiro[0] == null) {
      return false;
    }

    idparceiro = idparceiro[0].idparceiro

    const options = {
      method: 'POST',
      url: 'https://us-central1-restapi-4777e.cloudfunctions.net/app/membrosListar.php',
      headers: { 'Content-Type': 'application/json' },
      params: {
        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
        funcao: 'membrosListar',
        token_estabelecimento: token_estabelecimento,
        idestabelecimento: idestabelecimento,
        idparceiro: idparceiro
      },
    };

    await axios.request(options).then(function (response) {
      for (var x = 0; x < response.data.retorno.length; x++) {
        if (response.data.retorno[x]?.idusuario == id) {
          let nome = response.data.retorno[x].nome;
          let celular = response.data.retorno[x].celular;
          let idTipo = response.data.retorno[x].iddependentetipo;
          let situacao = response.data.retorno[x].situacao;
          if (!membro.celular) {
            setMembro((prevState) => ({
              ...prevState,
              nome: nome,
              celular: celular,
              relacaoEmpresa: idTipo,
              situacao: situacao
            }));
          } else {
            setMembro((prevState) => ({ ...prevState, relacaoEmpresa: idTipo, situacao: situacao }));
            setSubmembrosList(response.data.retorno[x].dependentes);
          }
        }
      }
    }).catch(function (error) {
      console.error(error);
      alert("Encontramos um problema tente mais tarde")
    });
  }

  useEffect(() => {
    getSubMembro();
  }, [subMembro.celular])

  const getSubMembro = async () => {

    const chave = subMembro.celular.replace(/[^0-9]/g, "");

    if (chave.length != 11) {
      return false;
    }

    const form = new FormData();
    form.append("metodo", "ConsultaUsuarioChave");
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("chavecliente", "" + chave);
    form.append("chavetipo", "celular");

    const options = {
      method: 'POST',
    };

    options.body = form;

    fetch(`${firebase_endpoint}/painel/integracao/usuario.php`, options)
      .then(response => response.json())
      .then(response => {
        if (response.erro == 'S') {
          setSubMembro((prevState) => ({
            ...prevState,
            ...response,
            relacaoMembro: '',
            nome: '',
            situacao: ''
          }))
        } else {
          setSubMembro((prevState) => ({ ...prevState, ...response }))
        }
      })
      .catch(err => console.error(err));
  }

  const salvarMembro = async () => {

    var chave = membro.celular.replace(/[^0-9]/g, "");

    if (chave.length != 11) {
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Número de celular inválido!',
        showConfirmButton: false,
        timer: 1000
      })

      return false;
    }

    var iddependentetipo = membro.relacaoEmpresa;

    if (iddependentetipo == '') {
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Selecione uma relação com a empresa',
        showConfirmButton: false,
        timer: 1000
      })

      return false;
    }

    //vamos conversar com a API
    var idestabelecimento = dadosX.idestabelecimento
    var token_estabelecimento = dadosX.tokenestabelecimento
    var idparceiro = dadosX.parceiros;

    if (idparceiro == null) {
      return false;
    }

    if (idparceiro.length == 0) {
      return false;
    }

    if (idparceiro[0] == null) {
      return false;
    }

    idparceiro = idparceiro[0].idparceiro

    var idusuario = membro.idusuario;
    var idusuariotitular = '';
    var idusuarioregistro = dadosX.idusuario;
    var nome = membro.nome;
    var celular = membro.celular;

    var urlPost = 'https://us-central1-restapi-4777e.cloudfunctions.net/app/cadastrarMembro.php'

    var cadastrarMembro = 'cadastrarMembro';

    // if (submembroTipo == true) {
    //   idusuariotitular = ''
    // }

    if (membro.erro == 'S') {

      if (nome == '') {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'Escreva o nome',
          showConfirmButton: false,
          timer: 1000
        })

        return false;
      }

      cadastrarMembro = 'cadastrarMembroCelular'
      urlPost = 'https://us-central1-restapi-4777e.cloudfunctions.net/app/cadastrarMembroCelular.php'
    }

    const options = {
      method: 'POST',
      url: urlPost,
      headers: { 'Content-Type': 'application/json' },
      params: {
        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
        funcao: cadastrarMembro,
        token_estabelecimento: token_estabelecimento,
        idestabelecimento: idestabelecimento,
        idparceiro: idparceiro,
        idusuario: idusuario,
        idusuariotitular: idusuariotitular,
        iddependentetipo: iddependentetipo,
        idusuarioregistro: idusuarioregistro,
        nome: nome,
        celular: celular
      },
    };

    await axios.request(options).then(function (response) {
      var dados = response.data;

      if (dados.erro != "N") {
        alert(dados.mensagem)
        return false;
      }

      location.href = './membros';

    }).catch(function (error) {
      console.error(error);
      alert("Encontramos um problema tente mais tarde")
    });
  }

  const adicionarSubmembro = async () => {

    var chave = subMembro.celular.replace(/[^0-9]/g, "");

    if (chave.length != 11) {
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Número de celular inválido!',
        showConfirmButton: false,
        timer: 1000
      })

      return false;
    }

    var iddependentetipo = subMembro.relacaoMembro;

    if (subMembro.relacaoMembro == '') {
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Selecione uma relação com o membro',
        showConfirmButton: false,
        timer: 1000
      })

      return false;
    }

    var idestabelecimento = dadosX.idestabelecimento
    var token_estabelecimento = dadosX.tokenestabelecimento

    var idparceiro = dadosX.parceiros;

    if (idparceiro == null) {
      return false;
    }

    if (idparceiro.length == 0) {
      return false;
    }

    if (idparceiro[0] == null) {
      return false;
    }

    idparceiro = idparceiro[0].idparceiro

    var nome = subMembro.nome;
    var celular = subMembro.celular;
    var idusuario = subMembro.idusuario;
    var idusuariotitular = membro.idusuario;
    var idusuarioregistro = dadosX.idusuario;

    var urlPost = 'https://us-central1-restapi-4777e.cloudfunctions.net/app/cadastrarMembro.php'

    var cadastrarMembro = 'cadastrarMembro';

    // if (submembroTipo == true) {
    //   idusuariotitular = idSubmembro
    // }

    if (subMembro.erro == 'S') {

      if (nome == '') {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'Escreva o nome',
          showConfirmButton: false,
          timer: 1000
        })

        return false;
      }

      cadastrarMembro = 'cadastrarMembroCelular'
      urlPost = 'https://us-central1-restapi-4777e.cloudfunctions.net/app/cadastrarMembroCelular.php'
    }

    const options = {
      method: 'POST',
      url: urlPost,
      headers: { 'Content-Type': 'application/json' },
      params: {
        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
        funcao: cadastrarMembro,
        token_estabelecimento: token_estabelecimento,
        idestabelecimento: idestabelecimento,
        idparceiro: idparceiro,
        idusuario: idusuario,
        celular: celular,
        nome: nome,
        idusuariotitular: idusuariotitular,
        iddependentetipo: iddependentetipo,
        idusuarioregistro: idusuarioregistro
      }
    };

    await axios.request(options).then(function (response) {

      var dados = response.data;

      if (dados.erro != "N") {
        alert(dados.mensagem)
        return false;
      }

      Swal.fire(
        'Sucesso!',
        '' + dados.mensagem,
        'success'
      ).then(() => {
        buscarMembro(dadosX, idusuariotitular)
      })

    }).catch(function (error) {
      console.error(error);
      alert("Encontramos um problema tente mais tarde")
    });
  }

  const excluirMembro = async (idempresaparceirousuario, situacao) => {

    var url = "https://us-central1-restapi-4777e.cloudfunctions.net/app/excluirMembro.php";
    var fun = 'excluirMembro';

    if (situacao && situacao == "PENDENTE") {
      url = "https://us-central1-restapi-4777e.cloudfunctions.net/app/excluirMembroCelular.php";
      fun = 'excluirMembroCelular';
    }

    var get_idempresaparceirousuario = idempresaparceirousuario;
    get_idempresaparceirousuario = '' + get_idempresaparceirousuario.match(/\d+/g);
    var idestabelecimento = dadosX.idestabelecimento
    var token_estabelecimento = dadosX.tokenestabelecimento

    const options = {
      method: 'POST',
      url: url,
      headers: { 'Content-Type': 'application/json' },
      params: {
        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
        funcao: fun,
        token_estabelecimento: token_estabelecimento,
        idestabelecimento: idestabelecimento,
        idlinha: get_idempresaparceirousuario
      },
    };

    await axios.request(options).then(function (response) {

      var dados = response.data;

      if (dados.erro != "N") {
        alert(dados.mensagem)
        return false;
      }

      Swal.fire(
        'Sucesso!',
        '' + dados.mensagem,
        'success'
      ).then(() => {
        buscarMembro(dadosX, membro.idusuario)
      })

    }).catch(function (error) {
      console.error(error);
      alert("Encontramos um problema tente mais tarde")
    });
  }

  const palavrasExcecao = ['de', 'a', 'o', 'para', 'em', 'com', 'do', 'da', 'dos', 'das', 'se', 'um', 'uma'];

  const getInitials = (name) => {
    const nameParts = name.trim().split(' ').filter(part => !palavrasExcecao.includes(part.toLowerCase()));
    // Pegamos as iniciais das três primeiras partes, ignorando as palavras de conexão
    const firstInitial = nameParts[0] ? nameParts[0][0] : '';
    const secondInitial = nameParts[1] ? nameParts[1][0] : '';
    const thirdInitial = nameParts[2] ? nameParts[2][0] : '';
    return (firstInitial + secondInitial + thirdInitial).toUpperCase();
  };

  return (
    <>
      {isMobile ?
        <>
          <MenuMobile page="marketing" />
          <CadastrarMembros
            membro={membro}
            setMembro={setMembro}
            salvarMembro={salvarMembro}
            isMobile={isMobile}
            screenWidth={screenWidth}
            subMembrosList={subMembrosList}
            relacaoEmpresa={relacaoEmpresa}
            relacaoMembro={relacaoMembro}
            subMembro={subMembro}
            setSubMembro={setSubMembro}
            adicionarSubmembro={adicionarSubmembro}
            excluirMembro={excluirMembro}
            getInitials={getInitials}
          />
        </>
        :
        <>
          <div style={{ maxWidth: '1920px', height: '100vh', display: "flex" }}>
            <div style={{ backgroundColor: '#262626' }}>
              <Menu page="marketing" menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            </div>
            <div style={{ overflow: 'auto', width: '100%' }}>
              <CadastrarMembros
                membro={membro}
                setMembro={setMembro}
                salvarMembro={salvarMembro}
                isMobile={isMobile}
                screenWidth={screenWidth}
                subMembrosList={subMembrosList}
                relacaoEmpresa={relacaoEmpresa}
                relacaoMembro={relacaoMembro}
                subMembro={subMembro}
                setSubMembro={setSubMembro}
                adicionarSubmembro={adicionarSubmembro}
                excluirMembro={excluirMembro}
                getInitials={getInitials}
              />
            </div>
          </div>
        </>}
    </>
  )
};

export default CadastrarMembrosJS;

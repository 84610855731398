import styles from "./ListaHora.module.css";
const ListaHora = () => {

  
  return (
    <div className={styles.listaHora}>
      <p>asdasd</p>
      <div className={styles.lista}>
        <div className={styles.defalut}>
          <div className={styles.div}>00</div>
        </div>
        <div className={styles.defalut1}>
          <div className={styles.div}>01</div>
        </div>
        <div className={styles.defalut2}>
          <div className={styles.div}>02</div>
        </div>
        <div className={styles.defalut3}>
          <div className={styles.div}>03</div>
        </div>
        <div className={styles.defalut4}>
          <div className={styles.div}>04</div>
        </div>
        <div className={styles.defalut5}>
          <div className={styles.div}>05</div>
        </div>
        <div className={styles.defalut6}>
          <div className={styles.div}>06</div>
        </div>
        <div className={styles.defalut7}>
          <div className={styles.div}>07</div>
        </div>
        <div className={styles.defalut8}>
          <div className={styles.div}>08</div>
        </div>
        <div className={styles.defalut9}>
          <div className={styles.div}>09</div>
        </div>
        <div className={styles.defalut10}>
          <div className={styles.div}>10</div>
        </div>
        <div className={styles.defalut11}>
          <div className={styles.div}>11</div>
        </div>
        <div className={styles.defalut12}>
          <div className={styles.div}>12</div>
        </div>
        <div className={styles.defalut13}>
          <div className={styles.div}>13</div>
        </div>
        <div className={styles.defalut14}>
          <div className={styles.div}>14</div>
        </div>
        <div className={styles.defalut15}>
          <div className={styles.div}>15</div>
        </div>
        <div className={styles.defalut16}>
          <div className={styles.div}>16</div>
        </div>
        <div className={styles.defalut17}>
          <div className={styles.div}>17</div>
        </div>
        <div className={styles.defalut18}>
          <div className={styles.div}>18</div>
        </div>
        <div className={styles.defalut19}>
          <div className={styles.div}>19</div>
        </div>
        <div className={styles.defalut20}>
          <div className={styles.div}>20</div>
        </div>
        <div className={styles.defalut21}>
          <div className={styles.div}>21</div>
        </div>
        <div className={styles.defalut22}>
          <div className={styles.div}>22</div>
        </div>
        <div className={styles.defalut23}>
          <div className={styles.div}>23</div>
        </div>
      </div>
    </div>
  );
};

export default ListaHora;
